import { CommentItem } from "./CommentItem";
import { useState, useEffect } from 'react';
import Pagination from "../components/Pagination";
import { useNotify, Notification } from "react-admin";
import DocumentPreviewModal from '../../components/DocumentPreview/DocumentPreviewModal';
import { doQueryWithTotal } from "../../utils/lib";
import Box from "@mui/material/Box";


export const CommentList = ({projectTask, setReloadTriggerID, reloadTriggerID, resource, contacts, describe } : {projectTask: any, setReloadTriggerID: any, reloadTriggerID: any, resource: string, contacts: any, describe: any}) => {
    
    //const { data, ids } = useListContext<any>();
    const notify: any = useNotify();
    const [ comments, setComments ] = useState<any[]>([]);
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [perPage] = useState(5);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
    const [ clickedItem, setClickedItem ] = useState<any>({});

    useEffect(() => {
        const q = `SELECT * FROM ModComments WHERE related_to=${projectTask?.id} ORDER BY createdtime DESC LIMIT ${offset}, ${perPage}`;
        setIsLoading(true);
        doQueryWithTotal(q).then((res: any) => {
            const {result, totalrows} = res;
            setTotal(totalrows)
            setComments([]);
            setComments(result);
        }).catch(function (error) {
            notify(error, 'error');
        })
        .finally(() => {
            setIsLoading(false);
        })
    }, [notify, offset, perPage, projectTask?.id, reloadTriggerID])

    const handleDocPreviewModalOpen = (record: any = null) => {
        setClickedItem(record);
        setDocPreviewOpen(true);
    };

    return (
        <>
            {comments.length > 0 &&
                <Box mt={8}>
                    <DocumentPreviewModal record={clickedItem} title={clickedItem?.notes_title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />
                    {comments.map((comment: any) => (
                            <CommentItem describe={describe} contacts={contacts} handleDocPreviewModalOpen={handleDocPreviewModalOpen} resource={resource} comment={comment} key={comment.id} projectTask={projectTask} setReloadTriggerID={setReloadTriggerID} />
                    ))}
                    {total > perPage && 
                        <Box width={'25%'} mx={'auto'} my={2} textAlign="center">
                            <Pagination total={total} offset={offset} setOffset={setOffset} page={page} setPage={setPage} perPage={perPage} isLoading={isLoading}  />
                        </Box>
                    }
                    <Notification />
                </Box>
            }
        </>
    )
}
