import React from 'react';
import { Link } from 'react-router-dom';
import HistoryIcon from '@mui/icons-material/History'
import { useTranslate } from 'react-admin';
import Card from '@mui/material/Card';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';




const ProjectTaskTimeline = ({tasks} : {tasks: any[]}) => {

    return (
        <>
            {
                tasks.map((task: any) => {

                    return (
                        <TimelineItem key={task?.projecttaskid}>
                            <TimelineSeparator>
                                <TimelineDot>
                                    <HistoryIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Box>
                                    <Typography variant="h6" component={Link} to={`/ProjectTask/32x${task?.projecttaskid}/show`} sx={{cursor: 'pointer', textDecoration: 'none'}}> {task.projecttaskname} </Typography>
                                </Box>
                                <Typography component={Link} to={`/Project/33x${task?.projectid}/show`} sx={{cursor: 'pointer', textDecoration: 'none'}}> {task.projectname}  </Typography>
                                <Divider />
                                <Box mt={1}>
                                    {
                                        Object.keys(task).map((key: string) => {
                                            if(key !== 'projecttaskid' && key !== 'projectid' && key !== 'projectname' && key !== 'projecttaskname'){
                                                return (
                                                    <Typography variant="body2" component="span" sx={{mr: 1}} > 
                                                        {key}: <strong style={{marginLeft: '3px'}}> {task[key] || '_'} </strong> 
                                                    </Typography>
                                                )
                                            }
                                            return <></>
                                        })
                                    }
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })
            }
        </>
    )

}

const CustomTimeline = ({ questions, describe }: { questions: any, describe?: any }) => {

    const translate = useTranslate();

    return (
        <Card elevation={0} sx={{ width: '100%' }}>
            <Box sx={{maxHeight: window.innerHeight - 160,overflow: 'auto',position: 'relative',width: '100%'}}>
                <Timeline className='justLogItTimeline' sx={{align: 'left' }}>
                    {questions?.module === 'ProjectTask'
                        ? <ProjectTaskTimeline tasks={questions?.answer} />
                        : <>
                            {
                                questions?.answer?.map((answer: any, index: number) => {
                                    return (
                                        <TimelineItem key={'activity'+index}>
                                            <TimelineSeparator>
                                                <TimelineDot>
                                                    <HistoryIcon />
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Paper sx={{ border: 'none' }}>
                                                    <Typography variant="body1" color="textPrimary"> {answer[1]}, {answer[2]} </Typography>
                                                    <Typography variant="body2" color="inherit"> {answer[0]} </Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                    )
                                })
                            }
                        </>
                    }
                </Timeline>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Typography variant="inherit" component={Link} to={`/${questions?.module}`} color="primary" sx={{ textDecoration: 'none', fontSize: '20px' }}>
                    {translate('translations.viewAll')}
                </Typography>
            </Box>
        </Card>
    )
}

export default CustomTimeline;