import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useListFilterContext, useGetIdentity } from 'react-admin';



export const OnlyMineInput = ({ alwaysOn, fieldName, btnLabel }: { alwaysOn: boolean, fieldName: string, btnLabel: string }) => {

    const {
        filterValues,
        displayedFilters,
        setFilters,
    } = useListFilterContext();
    const { identity } = useGetIdentity();


    const handleChange = () => {
        const newFilterValues = { ...filterValues };
        if (typeof filterValues[fieldName] !== 'undefined') {
            delete newFilterValues[fieldName];
        } else {
            newFilterValues[fieldName] = identity?.id;
        }
        setFilters(newFilterValues, displayedFilters);
    };


    return (
        <Box sx={{ marginBottom: 1, marginLeft: 1 }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={typeof filterValues[fieldName] !== 'undefined'}
                        onChange={handleChange}
                        color="primary"
                        name={fieldName}
                    />
                }
                label={btnLabel}
            />
        </Box>
    );
};