import { Reducer } from 'redux';
import { CHANGE_THEME, changeTheme, CHANGE_MENU_POSITION, changeMenuPostion, CHANGE_APP_MENU, changeAppMenu, Taskcounter, TIME, RECORD, setProjecttaskrecord, setDatestart, DATESTART, setTc, TIMECONTROL, initialState } from './actions';
import { ThemeName, MenuPosition } from '../types';

type ThemeState = ThemeName;
type MenuPositionState = MenuPosition;


type ThemeAction = | ReturnType<typeof changeTheme> | { type: 'OTHER_ACTION'; payload?: any };

type  MenuPositionAction = | ReturnType<typeof changeMenuPostion> | { type: 'OTHER_ACTION'; payload?: any };

type  AppMenuAction = | ReturnType<typeof changeAppMenu> | { type: 'OTHER_ACTION'; payload?: any };

type  TimeAction = ReturnType<typeof Taskcounter> | { type: 'OTHER_ACTION'; payload?: string };

type RecordAction = | ReturnType<typeof setProjecttaskrecord> | {type: 'OTHER_ACTION'; payload?: Object};

type DateAction = | ReturnType<typeof setDatestart> | {type: 'OTHER_ACTION'; payload?: string};

type TcAction = | ReturnType<typeof setTc> | {type: 'OTHER_ACTION'; payload?: Object};


const userTheme: any = localStorage.getItem('theme') ?? 'light';
const appThemeReducer: Reducer<ThemeState, ThemeAction> = (previousState: any, action: any) => {
    const prevState = previousState ?? userTheme;
    if (action.type === CHANGE_THEME) {
        return action.payload;
    }
    return prevState;
};
export const themeReducer = appThemeReducer;


const userMenuPosition: any = localStorage.getItem('menuposition') ?? 'left';
const userMenuPositionReducer: Reducer<MenuPositionState, MenuPositionAction> = (previousState: any, action: any) => {
    const prevState = previousState ?? userMenuPosition;
    if (action.type === CHANGE_MENU_POSITION) {
        return action.payload;
    }
    return prevState;
};
export const menuPositionReducer = userMenuPositionReducer;


const userMenuReducer: Reducer<any, AppMenuAction> = (previousState: any[], action: any) => {
    const prevState = previousState ?? [];
    if (action.type === CHANGE_APP_MENU) {
        return action.payload;
    }
    return prevState;
};

export const appMenuReducer = userMenuReducer;

const timeReducer: Reducer<any, TimeAction> = (previousState: string, action) => {
    const prevState = previousState ?? 'initial';
    if (action.type === TIME) {
        return action.payload;
    }
    return prevState;
};

export const taskReducer = timeReducer;

const recordReducer: Reducer<any, RecordAction> = (previousState: any, action) => {
    const prevState = previousState ?? {};
    if (action.type === RECORD) {
        return action.payload;
    }
    return prevState;
};

export const projecttaskReducer = recordReducer;

const dateReducer: Reducer<any, DateAction> = (previousState: any, action) => {
    const prevState = previousState ?? new Date();
    if (action.type === DATESTART) {
        return action.payload;
    }
    return prevState;
};

export const datestartReducer = dateReducer;

const TcReducer: Reducer<any, TcAction> = (previousState: any, action) => {
    const prevState = previousState ?? {};
    if (action.type === TIMECONTROL) {
        return action.payload;
    }
    return prevState;
};

export const timecontrolReducer = TcReducer;

export const stopwatchReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'START_TIMER': {
            const savedRecordedTime = localStorage.getItem('tcrecordedtime') ?? null;
            if(savedRecordedTime){
                return {
                    ...state,
                    started: action.time, 
                    ended: null,
                    workingTask: action.workingTask,
                    recordedTime: Number(savedRecordedTime),
                };
            } 

            localStorage.setItem('tctask', JSON.stringify(action?.workingTask??{}));
            localStorage.setItem('tcstarted', action.time);
            return {
                ...state,
                started: action.time, 
                ended: null,
                workingTask: action.workingTask
            };
        }
        case 'STOP_TIMER': {
            if(action.tcModalOpen){
                const tcrecordedtime: any = state.recordedTime + action.time - state.started;
                localStorage.setItem('tcrecordedtime', tcrecordedtime);
                return {
                    ...state,
                    recordedTime: tcrecordedtime,
                    started: null,
                    ended: action.time,
                    tcModalOpen: action.tcModalOpen,
                }; 
            } 

            return {
                ...state,
                started: null,
                ended: null,
                tcModalOpen: action.tcModalOpen,
            };
        }
       case 'RESET_TIMER': {
            localStorage.removeItem('tctask');
            localStorage.removeItem('tcstarted');
            localStorage.removeItem('tcrecordedtime');
            return initialState;
        }
        case 'CLOSE_BUTTON': {
            return {tcModalClose: action.tcModalClose}
        }
       case 'CONTINUE_TIMER': {
            const savedRecordedTimeContinue = localStorage.getItem('tcrecordedtime') ?? null;
            if(savedRecordedTimeContinue){
                return {
                    ...state,
                    started: action.time, 
                    ended: null,
                    workingTask: action.workingTask,
                    recordedTime: Number(savedRecordedTimeContinue),
                };
            } 

            localStorage.setItem('tctask', JSON.stringify(action?.workingTask??{}));
            localStorage.setItem('tcstarted', action.time);
            return {
                ...state,
                started: action.time, 
                ended: null,
                workingTask: action.workingTask
            };
        }
        default:
            return {...state, initialState};
    }
}


