import * as React from 'react';
import Inbox from '@mui/icons-material/Inbox';
import { useTranslate, useResourceContext, useGetResourceLabel, CreateButton} from 'react-admin';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const CustomEmpty = (props: any) => {
    const resource: any = useResourceContext(props);
    const translate = useTranslate();
    const { action } = props;

    const getResourceLabel = useGetResourceLabel();
    const resourceName = translate(`resources.${resource}.forcedCaseName`, {
        smart_count: 0,
        _: getResourceLabel(resource, 0),
    });

    const emptyMessage = translate('ra.page.empty', { name: resourceName });
    const inviteMessage = translate('ra.page.invite');

    return (
        <>
            <Box sx={{textAlign: 'center', opacity: (theme: any) =>  theme.palette.type === 'light' ? 0.5 : 0.8, margin: '0 1em', color: (theme: any) => theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary}}>
                <Inbox sx={{width: '9em', height: '9em'}} />
                <Typography variant="h4" paragraph>
                    {translate(`resources.${resource}.empty`, {
                        _: emptyMessage,
                    })}
                </Typography>
                <Typography variant="body1">{translate(`resources.${resource}.invite`, {_: inviteMessage,})}</Typography>
            </Box>
            <Box sx={{textAlign: 'center',  marginTop: '2em'}}>
                { action || <CreateButton variant="contained" resource={resource} /> }
            </Box>
        </>
    );
};

