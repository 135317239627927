import React, {useEffect} from 'react'
import { doInvoke, doRevise, remoteValidate } from '../utils/lib';
import { useFormContext } from 'react-hook-form';

//@ts-ignore
import mermaid from 'mermaid';
import { useNotify } from 'react-admin';


export const PFPushAlong = (props: any) => { 
    
    const {pfid, ctxid, isDetailView, onPflUpdate=null, graphId} = props;
    const {setValue} = useFormContext();
    const pflGraphId = graphId || 'pfpaoutputgraph' + pfid;

    useEffect(() => {

        const updateRecord = (data: any, element: any, onPflUpdate: any) => {
            doRevise(data.module, element).then(() => getGraph(pfid, ctxid));
            if(onPflUpdate){
                onPflUpdate();
            }
        }

        const handleUpdate = (data: any, onPflUpdate: any, _isDetailView: boolean) => {
            const window_: any = window;
            window_[data.functionname] = (tostate: any, _forrecord: any, _askifsure: any) => {
                if (_isDetailView) {
                    const element: any = {'id': data.record};
                    element[data.fieldname] = tostate;
                    updateRecord(data, element, onPflUpdate);
                    
                } else {
                    setValue(data.fieldname, tostate);
                }
            }
        }

        const renderGraph = (value: any, pfidParam: any) => {
            const output: any = document.getElementById(pflGraphId);
            try {
                output.innerHTML = '';
                mermaid.parse(value);
                mermaid.render('theGraph' + pfidParam, value, (svgCode: any) => {
                    output.innerHTML = svgCode;
                });
                const window_: any = window;
                window_.dispatchEvent(window_?.coreBOS?.PFStateEvent);
            } catch (err) {
                console.error(err);
            }
        }

        const getGraph = (pfidParam: any, ctxidParam: any, _isDetailView?: boolean) => {
            doInvoke('PushAlongFlow', {pflowid: pfidParam, contextid: ctxidParam}, 'GET').then(
                    (data: any) => {
                        console.log(data)
                        renderGraph(data.graph, pfidParam);
                        handleUpdate(data, onPflUpdate, isDetailView);
                    },
                    (reason: string) => {
                        console.warn('fail to read graph: '+reason);
                    }
                ).catch((e: any) => console.log(e));
        };
        const window_: any = window;
        if (window_.coreBOS === undefined) {
            window_.coreBOS = {};
        }
        const customEvent: any = {processflow: pfid};
        window_.coreBOS.PFStateEvent = new CustomEvent('PFStateUpdated', customEvent);
        mermaid.initialize({startOnLoad: true, securityLevel: 'loose'});
        getGraph(pfid, ctxid, isDetailView);
        
    }, [pfid, ctxid, isDetailView, onPflUpdate, setValue, pflGraphId]);

    return (
        <div id={pflGraphId} ></div>
    );
};

export const DetailViewPFPushAlong = (props: any) => { 

    const {pfid, ctxid, onPflUpdate=null, graphId, record=null} = props;

    const pflGraphId = graphId || 'pfpaoutputgraph' + pfid;
    const notify = useNotify();


    useEffect(() => {
        const renderGraph = (value: any, pfidParam: any) => {
            const output: any = document.getElementById(pflGraphId);
            try {
                output.innerHTML = '';
                mermaid.parse(value);
                mermaid.render('theGraph' + pfidParam, value, function (svgCode: any) {
                    output.innerHTML = svgCode;
                });
                const cbWindow: any = window;
                window.dispatchEvent(cbWindow.coreBOS.PFStateEvent);
            } catch (err) {
                console.error(err);
            }
        }
    
        const updateRecord = (data: any, element: any, onPflUpdate: any) => {
            doRevise(data.module, element).then(() => {
                getGraph(pfid, ctxid);
                if(onPflUpdate){
                    onPflUpdate();
                }
            }).catch((err: any) => {
                notify(err);
            });
        }

        const handleUpdate = (data: any, record: any, onPflUpdate: any) => {
            const cbWindow: any = window;
            cbWindow[data.functionname] = async (tostate: any, _forrecord: any, _askifsure: any) => {
                const element: any = {
                    'id': data.record
                };
                record[data.fieldname] = tostate;
                element[data.fieldname] = tostate;
                const validationRes: any = await remoteValidate(record.id, data.module, record);
                if(validationRes.isValid){
                    updateRecord(data, element, onPflUpdate);
                } else {
                    notify(validationRes?.errors[0]??'', {type: 'error'});
                }
            }
        }
        
        const getGraph = (pfidParam: any, ctxidParam: any, _isDetailView: boolean = false) => {
            doInvoke('PushAlongFlow', {pflowid: pfidParam, contextid: ctxidParam}, 'GET').then((data: any) => {
                        renderGraph(data.graph, pfidParam);
                        handleUpdate(data, record, onPflUpdate);
                    },
                    (reason: any) => {
                        console.warn('fail to read graph: '+reason);
                    }
                ).catch((e: any) =>console.log(e));
        };

        const cbWindow: any = window;
        if (cbWindow.coreBOS === undefined) {
            cbWindow.coreBOS = {};
        }
        const processflow: any = {processflow: pfid};
        cbWindow.coreBOS.PFStateEvent = new CustomEvent('PFStateUpdated', processflow);
        mermaid.initialize({startOnLoad: true, securityLevel: 'loose'});
        getGraph(pfid, ctxid);

    }, [pfid, ctxid, pflGraphId, record, onPflUpdate, notify]);

    return (
        <div id={pflGraphId} ></div>
    );
};