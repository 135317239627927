import React, { useState, useEffect, useCallback } from 'react';
import { Create, TabbedForm, FormTab, Toolbar, SaveButton, TabbedFormTabs, useCreate, useTranslate, useNotify, useRedirect, FormDataConsumer, Notification, useResourceContext, usePermissions, useGetIdentity } from 'react-admin';
import cbUtils from '../corebosUtils/corebosUtils';
import { filterDependencyMaps, getDataFromLocalDb, uploadFile, handleSpecialChars } from '../../utils/Helpers';
import { doInvoke, remoteValidate } from '../../utils/lib';
import { TABLE_DESCRIBE, TABLE_AUTH } from '../../local-db';
import {Box, Grid, CircularProgress, Typography, Button} from '@mui/material';
import { DocumentUpload } from '../DocumentUpload';
import { useLocation } from "react-router";
import queryString, {parse} from 'query-string';
import { MarkDownInput } from '../MarkDown';
import { DependentField } from '../DependentField';
import { ParentDependencyField } from '../ParentDependencyField';
import "react-mde/lib/styles/css/react-mde-all.css";
import { useNavigate } from 'react-router-dom';




const CustomToolbar = (props: any) => {

	const translate = useTranslate();
	const navigate = useNavigate();
	const { modPermission, isLoading } = props;

	return (
		<Toolbar {...props}>
			{modPermission?.create &&
				<>
					<SaveButton
						label={translate('ra.action.save')}
						icon={isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary'} size={24} /> : <></>}
						disabled={props.pristine || isLoading}
						size="small"
						sx={{ textTransform: 'none' }}
						{...props} 
					/>
					<Button onClick={() => navigate(-1)} variant="contained" color="inherit" size='small' sx={{marginLeft: 2}}>
						Cancel
					</Button>
				</>
			}
		</Toolbar>
	);
}

const CbCreateTabGuesser = (props: any) => {
	
	const resource: any = useResourceContext();
	const [describe, setDescribe] = useState<any>({});
	const [blocks, setBlocks] = useState<any[]>([]);
	const [user, setUser] = useState<any>({});
	const location = useLocation();
	const urlQueryString = queryString.parse(location.search);
	const allPermissions: any = usePermissions();
	const modPermission = allPermissions ? allPermissions?.permissions?.filter((permission: any) => permission.module === resource)[0]?.permissions : null;
	const [isLoading, setIsLoading] = useState(false);
	const [uploadedFileList, setUploadedFileList] = useState<any[]>([]);
	const [create] = useCreate();
	const translate = useTranslate();
	const notify = useNotify();
	const [dependentFields, setDependentFields] = useState<any[]>([]);
	const [dependentFieldsNames, setDependentFieldsNames] = useState<any[]>([]);
	const [title, setTitle] = useState<string>('');
	const redirect = useRedirect();
	const { identity } = useGetIdentity();


	const { relatedModule, relatedField, relatedFieldValue } = parse(location.search);
	const defaultValue: { [k: string]: any } = {};
	let redirectUrl = '';
	if (relatedModule && relatedField && relatedFieldValue) {
		defaultValue[relatedField as string] = relatedFieldValue;
		redirectUrl = `/${relatedModule}/${relatedFieldValue}/show?showrelmod=1&relmod=${resource}`;
	}


	for (const key in urlQueryString) {
		if (typeof key === 'string') {
			defaultValue[key] = urlQueryString[key];
		}
	}
	const [markdownInputValue, setMarkdownInputValue] = useState({});
	const fetchMaps = useCallback(async () => {
		const module = resource + '_FieldDependencyPortal';

		let currentMap = await doInvoke('ProcessMap', { mapid: module }).catch((err: any) => { console.log(err); });

		if (currentMap && typeof currentMap !== "undefined") {
			const { allMaps, fieldNames }: { allMaps: any[], fieldNames: any[] } = filterDependencyMaps(currentMap);
			setDependentFields(allMaps);
			setDependentFieldsNames(fieldNames);
		}


	}, [resource]);


	useEffect(() => {
		getDataFromLocalDb(TABLE_AUTH.tableName).then((result) => {
			setUser(result?.user ?? {});
		});
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then(async (result: any) => {
			setDescribe(result);
			setTitle(result[resource]?.label);
			let bfields: any[] = [];
			let mfields: any = result[resource]?.fields ?? [];
			for (const value of mfields) {
				if (!value.block) {
					continue;
				}
				let bidx = bfields.findIndex((element :any) => element.id === value.block.blockid);
				if (bidx===-1) {
					bfields.push({
						id: value.block.blockid,
						sequence: value.block.blocksequence,
						label: value.block.blocklabel,
						name: value.block.blockname,
						fields: []
					});
					bidx = bfields.findIndex((element :any) => element.id === value.block.blockid);
				}
				if(value.editable !== false && value.displaytype !== '4') {
					bfields[bidx].fields.push(value);
				}
			}
			const allBlocks: any[] = bfields.filter((fieldBlock: any) => fieldBlock.fields.length > 0);
			setBlocks(allBlocks);
			await fetchMaps();
		});
	}, [fetchMaps, resource])


	const saveThisImage = async function* (data: any, file: any) {
		const title = '';
		const result = await handleUpload(file, title);
		if (result instanceof Error) {
			yield '';
			return true;
		}
		yield result?._downloadurl;
		return !!result?._downloadurl;
	};

	const handleUpload = async (file: any, title: string) => {
		setIsLoading(true);
		const result = await uploadFile(file, title, '', '', '');
		if (result instanceof Error) {
			setIsLoading(false);
			return null;
		}
		setUploadedFileList((uploadedFileList: any[]) => { return [...uploadedFileList, result] });
		setIsLoading(false);
		return result;
	}

	const saveRecord = useCallback(
		async (values: any, fileList: any[], currentMarkdownInputValue: any) => {
			setIsLoading(true);
			const dataTosend: any = Object.assign(values, currentMarkdownInputValue);
			for (const key in dataTosend) {
				const value = dataTosend[key];
				if (value?.rawFile) {
					const result = await handleUpload(value.rawFile, '');
					dataTosend[key] = result?._downloadurl ?? '';
				} else {
					dataTosend[key] = handleSpecialChars(dataTosend[key]);
				}
			}
			const validationRes: any = await remoteValidate('', resource, dataTosend);
			if (validationRes.isValid) {
				create(resource, {data: dataTosend}, {
					onSuccess: (result) => {
						const fileIds: any[] = [];
						fileList.forEach((uploadedDocument: any) => {
							fileIds.push(uploadedDocument.id);
						});
						if (fileIds.length > 0) {
							doInvoke('SetRelation', { relate_this_id: result?.id, with_these_ids: JSON.stringify(fileIds) }, 'POST').then(() => {
								if (redirectUrl) {
									redirect(redirectUrl);
								} else {
									setUploadedFileList([]);
									setMarkdownInputValue('');
									redirect(`/${resource}/${result?.id}/show`)
								}
							}).catch(() => {
								notify(`Upload: ${translate('Something went wrong')}`, {type: 'error'});
							}).finally(() => {
								setIsLoading(false);
							})
						} else if(redirectUrl) {
								redirect(redirectUrl);
						} else {
							setUploadedFileList([]);
							setMarkdownInputValue('');
							redirect(`/${resource}/${result?.id}/show`)
						}
					},
					onError: () => {
						setIsLoading(false);
						notify(translate('Something went wrong'), {type: 'error'});
					},
				});
			} else {
				setIsLoading(false);
				notify(validationRes?.errors[0] ?? '', {type: 'error'});
			}
		},
		[create, notify, redirect, redirectUrl, resource, translate],
	);

	const handleMarkdownField = (fieldName: string, value: string, cureentMarkdownInputValue: any) => {
		cureentMarkdownInputValue[fieldName] = value;
		setMarkdownInputValue(cureentMarkdownInputValue);
	}

	return (
		<Box px={0.5} >
			<Grid container spacing={1}>
				<Grid item xs={12} md={12}>
					<Box className='raPageContent'>
						{resource === 'Documents'
							? <DocumentUpload afterUpload={null} uploadResource={resource} assigned_user_id={user?.id} relationsIds={''} relProjectId={''} />
							: <Create {...props} title={<> {(title)} </>}>
								<TabbedForm defaultValues={resource === 'cbVacationRequest' ? {...defaultValue, ...{assigned_user_id: identity?.id}} :defaultValue} onSubmit={(values: any) => saveRecord(values, uploadedFileList, markdownInputValue)} toolbar={<CustomToolbar modPermission={modPermission} isLoading={isLoading} />} syncWithLocation={false} tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" {...props} />} >
									{
										blocks.map((block, bidx) => {
											return (
												<FormTab key={"fbrefblk" + bidx} label={block.name}>
													<Box style={{ width: "100%" }} py={3}>
														<Grid container spacing={2}>
															{block?.fields?.map((field: any) => {
																if(resource === 'cbVacationRequest' && (field.name === 'manager_user' || field.name === 'assigned_user_id')){
																	return <></>
																}
																if (field.uitype === "19") {
																	return (
																		<Grid key={field.name} item xs={12} md={12}>
																			<Box my={2}>
																				<Typography variant="subtitle2">
																					{" "}
																					{field.label}{" "}
																				</Typography>
																				<MarkDownInput
																					record={defaultValue}
																					source={field.name}
																					handleMarkdownField={handleMarkdownField}
																					saveThisImage={saveThisImage}
																					cureentMarkdownInputValue={markdownInputValue}
																				/>
																			</Box>
																		</Grid>
																	);
																}

																if(field.uitype === '10' && field.type?.refersTo && Array.isArray(field.type.refersTo) && field.type.refersTo.length > 1){
																	return field.type.refersTo.map((referenceMod: string) => {
																		if(describe[referenceMod]){
																			return <Grid item xs={12} md={6} key={field.name +'_'+referenceMod}> {cbUtils.field2InputElement(field, resource, {fullWidth: true}, describe, '', referenceMod, {}, {}, {})} </Grid>
																		}
																		return <></>;
																	});
																}

																const formattedDepenedncyMaps =
																	dependentFieldsNames.filter(
																		(e: any) => e?.dependentFieldName === field?.name
																	);
																const formattedDepenedncyMapsForParent =
																	dependentFieldsNames.filter(
																		(e) => e.parentFieldName === field?.name
																	);
																const cbField = cbUtils.field2InputElement(field, resource, { fullWidth: true }, describe, '', '', {}, {}, {});
																return cbField ? (
																		<FormDataConsumer>
																			{({ formData }) => {

																				if(formattedDepenedncyMaps.length > 0 ){
																					return (
																						<Grid key={field.name} item xs={12} md={6}>
																							<DependentField
																								formattedDepenedncyMap={formattedDepenedncyMaps}
																								describe={describe}
																								resource={resource}
																								field={field}
																								source={field.name}
																								formValues={formData}
																								dependencyMaps={dependentFields}
																							/>
																						</Grid>
																					)
																				}

																				if(formattedDepenedncyMapsForParent.length > 0){
																					return (
																						<Grid key={field.name} item xs={12} md={6}>
																							<ParentDependencyField
																								formattedDepenedncyMap={
																									formattedDepenedncyMapsForParent
																								}
																								input={cbField}
																								field={field}
																								dependencyMaps={dependentFields}
																							/>
																						</Grid>
																					)
																				}

																				return (<Grid key={field.name} item xs={12} md={6} className={field.label}> {cbField} </Grid>)
																			}}
																		</FormDataConsumer>
																) : (
																	<></>
																);
															})}
														</Grid>
													</Box>
												</FormTab>
											);
										})
									}
								</TabbedForm>
							</Create>
						}
					</Box>
				</Grid>
			</Grid>
			<Notification />
		</Box>
	)
};



export default CbCreateTabGuesser;