import React, { useState } from 'react';
import { FunctionField, useListContext } from 'react-admin';
import cbUtils from './corebosUtils/corebosUtils';
import CustomFieldLink from './CustomFieldLink';
import RowAction from './RowAction';
import DocumentPreviewModal from './DocumentPreview/DocumentPreviewModal';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';



const ResponsiveList = (props: {describe: any, resource: string, fields: any[], linkFields: any, linkField: string, modPermission: any, onRowClick?: any}) => {

    const { data } = useListContext();
    const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
	const [ clickedItem, setClickedItem ] = useState<any>({});



    const handleDocPreviewModalOpen = (recordValue: any, _moduleName: string, field:any =null) => {
        const record = recordValue || null;
        if(record){
			record['title'] = field?.label ?? '';
			record['_downloadurl'] = record[field?.name] ?? '';
			if(!record.filetype){
				const urlStr: string[] = record[field?.name] ? record[field.name].split('.') : [];
				record['filetype'] = urlStr[urlStr.length-1] ?? '';
			}
		}
		setClickedItem(record);
        setDocPreviewOpen(true);
    };

    return (
        <Box p={3}>
            <DocumentPreviewModal record={clickedItem} title={clickedItem?.title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />
            {data?.map((record: any) =>
                <Card key={record?.id} style={{ padding: '10px', margin: '15px auto', border: '1px solid #ddd' }}>
                    <CardHeader 
                        title={<CustomFieldLink resource={props.resource} record={record} linkField={props.linkField} />} 
                        action={<RowAction record={record} resource={props.resource} modPermission={props.modPermission} />}
                    />
                    <Divider />
                    <CardContent>
                        { props.fields.map((field: any) => {
                            let handleFieldClick: any = null;
                            if(field.uitype === '28' || field.uitype === '69'){
                                handleFieldClick = handleDocPreviewModalOpen;
                            }

                            if(field.uitype === '10' && field.type.refersTo && field.type.refersTo.length > 1){
                                return (
                                    <Box my={1} key={field.name}>
                                        <Typography variant="subtitle2"> {field.label} </Typography>
                                        <FunctionField label={field.label} render={() => {
                                            return cbUtils.field2DisplayRecord(field, record, props.resource, props.describe, '', '', handleFieldClick);
                                        }}/>
                                    </Box>
                                )
                            }
                            
                            return (
                                <Box my={1} key={field.name}>
                                    <Typography variant="subtitle2"> {field.label} </Typography>
                                    { props.linkFields.includes(field.name) 
                                        ? <>
                                            { props.resource === 'cbCalendar' && props.onRowClick
                                                ? <Typography onClick={() => props.onRowClick({record: record, open: true})} variant="inherit" color="primary" className='pointer' >
                                                        { record[props.linkField]}
                                                    </Typography>
                                                : <CustomFieldLink linkField={field.name} resource={props.resource} record={record} /> 
                                            }
                                            </>
                                        
                                        : cbUtils.field2DisplayRecord(field, record, props.resource, props.describe, '', '', handleFieldClick)}
                                </Box>
                            )
                        })}
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};

export default ResponsiveList;