

//@ts-ignore
import italianMessages from 'ra-language-italian';

const ra = italianMessages.ra;

ra.page.dashboard = 'Dashboard';
ra.page.empty = 'Vuota';
ra.page.invite = 'Invitare';
ra.action.export = 'Esportare';
ra.action.add_filter = 'Cerca per filtro';

const it = {
    ra,
    dashboard: {
        searchInputPlaceholder: "Ricerca...",
    },
    translations: {
        somethingWentWrong: "Qualcosa è andato storto",
        all: "Tutto",
        dataUpdated: "Dati aggiornati",
        menuPosition: "Posizione del menu",
        left: "Sinistra",
        right: "Destra",
        top: "Superiore",
        upload: "Caricamento",
        unsupportedFileType: "Tipo di file non supportato",
        relateAction: "Relazionare",
        selectAction: "Selezionare",
        backAction: "Di ritorno",
        theme: {
            name: 'Tema',
            light: 'Ligera',
            dark: 'Oscura',
        },
        configuration: "Configurazione",
        profile: "Profilo",
        viewAll: "Mostra tutto",
        addAction: "Inserisci",
        associateNew: "Associato Nuovo",
        associateExisting: "Associato esistente",
        cancelAction: "Annulla",
        closeAction: "chiudere",
        associateThisRecord: "Associa nuovo o esistente a questo record",
    },
    "incorrect response: INVALID_SESSIONID: Session Identifier provided is Invalid": 'Sessione non valida',
    "Theme Setting": "Impostazione del tema",
    "Theme Color": "Theme Color",
    "Menu Position": "Colore del tema",
    "Top": "Superiore",
    "Left": "Sinistra",
    "Right": "Destra",
    "Position": "Posizione",
    "Time Control": "Controllo del tempo",
    "Dashboard": "Pannello",
    "Cancel": "Annulla",
    "File": "File",
    "Search": "Ricerca",
    "View All": "Mostra Tutto",
    "Search for": "Cercare",
    "Assigned to Me": "Assegnato a me",
    "Data saved successfully": "Dati salvati con successo",
    "Something went wrong": "Qualcosa è andato storto",
    "Something went wrong, try again later": "Qualcosa è andato storto, riprova più tardi",
    "Edit": "Modificare",
    "New Task": "Nuovo compito",
    "Archive": "Archivio",
    "Version": "Versione",
    "A new version was created successfully": "Una nuova versione è stata creata correttamente",
    "Drop your file here to upload or click to select": "Trascina qui il tuo file per caricarlo o fai clic per selezionarlo",
    "TimeControl created successfully": "TimeControl creato correttamente",
    "TimeControl updated successfully": "TimeControl aggiornato correttamente",
    "Record created successfully": "Record creato correttamente",
    "Activities": "Attività",
    "Activity": "Attività",
    "Create Calendar Task": "Crea attività del calendario",
    "Tasks": "Compiti",
    "Calendar": "Calendario",
    "Go to full form": "Vai al modulo completo",
    "Preview": "Anteprima",
    "Stop": "Fermare",
    "Pause": "Pausa",
    "Duplicate": "Duplicare",
    "Are you sure": "Sei sicuro",
    "You are about to duplicate this record": "Stai per duplicare questo record",
    "Yes": "Sì",
    "No": "No",
    "Create SubTask": "Crea sottoattività",
    "Review": "Revisione",
    "Parent": "Genitore",
    "Create New": "Creare nuovo",
    "Assignee": "Assegnataria",
    "Assigned To": "Assegnato a",
    "Sub Tasks": "Compiti Secondari",
    "Subscribers": "Iscritte",
    "Add Subscriber": "Aggiungi abbonato",
    "File uploaded successfully": "il file è stato caricato con successo",
    "Documents": "Documenti",
    "Upload": "Caricamento",
    "Unsupported file type": "Tipo di file non supportato",
    "Invalid file type or the file is too large": "Tipo di file non valido o il file è troppo grande",
    "Description": "Descrizione",
    "Start Time": "Ora di inizio",
    "End Time": "Tempo scaduto",
    "Total Time": "Tempo totale",
    "Maximum": "Massimo",
    "hours": "ore",
    "Create TimeControl": "Crea TimeControl",
    "Finish": "Fine",
    "Approve": "Approvare",
    "Effort": "Sforzo",
    "Invalid username or password": "Nome utente o password errati",
    "Filters": "Filtri",
    "Worked Hours": "Ore lavorate",
    "GoBack": "Torna indietro",
    "Clear Storage": "Clear Storage",
    "ClearingStorageInfo": "Facendo clic sul pulsante in basso, la memoria locale per questa applicazione verrà eliminata e tutti i dati salvati andranno persi",
};

export default it;
