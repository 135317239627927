import * as React from 'react';
import { Link } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import CustomFieldLink from './CustomFieldLink';
import cbUtils from './corebosUtils/corebosUtils';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';

export const AsideDetailView = ({fields, linkFields=[], labelField='', resource, describe, record, open, handleAsideModal, position='right' } : { fields: any[], linkFields?: string[], labelField?: string, resource: string, describe: any, record: any, open: boolean, handleAsideModal: any, position?: any }) => {


    const handleClose = () => {
		handleAsideModal(null, false);
	};

    return (
        <Drawer
			anchor={position}
			open={open}
			onClose={handleClose}
			elevation={4}
			className={'asidePreview'}
		>
			<DialogTitle id="quickPreview">
				{record &&
					<Box display={'flex'}>
						<Typography variant="subtitle2" component={Link} to={`/${resource}/${record?.id}/show`} color="primary" style={{textTransform: 'uppercase', width: '100%'}}>
							{record[labelField] ?? ''}
						</Typography>
						<IconButton aria-label="close" onClick={handleClose} style={{marginTop: '-10px'}}>
							<CloseIcon />
						</IconButton>
					</Box>
				}
			</DialogTitle>
			<Card style={{ padding: '14px' }}>
				<CardContent>
                	<Box my={2}>
						{
							fields.map((field, index) => {
								return (
									<Box key={field.name} display={'flex'} sx={{width: '100%', margin: '3px auto'}}>
										<Box justifyContent={'start'} sx={{width: '50%'}}> <Typography variant="subtitle2"> {field.label} </Typography> </Box>
										<Box justifyContent={'end'}> {linkFields.includes(field.name) ? <CustomFieldLink resource={resource} record={record} linkField={field.name} /> : cbUtils.field2DisplayRecord(field, record, resource, describe)} </Box>
									</Box>
								)
							})
						}
					</Box>
				</CardContent>
			</Card>
		</Drawer>
    );
};

