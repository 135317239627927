import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { APP_NAME, COMPANY_LOGO_URL } from "../constant";
import { MenuItemLink, useGetIdentity, useRedirect, UserMenu, useTranslate, Loading, Logout } from 'react-admin';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { ContentContext } from '../contentContext';
import AppMenu from './Menu';


const drawerWidth = 240;
const drawerCloseWidth = 55;


const CustomUserMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate();
  return (
    <UserMenu {...props}>
      <MenuItemLink
        ref={ref}
        to="/"
        primaryText={translate('ra.page.dashboard')}
        leftIcon={<DashboardOutlinedIcon />}
        onClick={props.onClick}
        {...props}
      />
      <MenuItemLink
        ref={ref}
        to={`/Contacts/${props?.contactid || props?.user?.id}/show`}
        primaryText={translate('translations.profile')}
        leftIcon={<PersonOutlineOutlinedIcon />}
        onClick={props.onClick}
        {...props}
      />
      <Divider />
      <Logout />
    </UserMenu>
  )
});

const MiniDrawer = ({ isloading, isloadingmenu, menuitemslist, main, props } : { isloading: boolean, isloadingmenu: boolean, menuitemslist: any[], main: any, props: any }) => {
  const theme = useTheme();
  const [sidebarisopen, setSidebarisopen] = useState<boolean>(true);
  const isMedium = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const [open, setOpen] = useState(true);
  const redirect = useRedirect();
  const { identity } = useGetIdentity();
  const [contentheight, setContentheight] = useState(0);
  const contentHeightRef: any = useRef(null);
  const toolbarref: any = useRef(null);



  useEffect(() => {
    setOpen(isMedium);
    if (contentHeightRef.current) {
      setContentheight(window.innerHeight - (220))
    }
  }, [isMedium]);

  const handleDrawerOpen = () => {
    setOpen(!open);
    setSidebarisopen(!open);
  };

  return (
    <ContentContext.Provider value={contentheight}>
      <Box 
          sx={{
            display: 'flex',
            height: '100vh',
            marginTop: 0,
            marginBottom: 0,
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            '& .MuiMenuItem-root': {
              fontSize: '14px',
            },
            '& .MuiCircularProgress-root': {
              width: '25px !important',
              height: '25px !important',
            },
            '& a.MuiListItem-root': {
              color: 'white !important',
            },
          }}
        >
        <CssBaseline />
        <AppBar
          position="fixed"
          color='inherit'
          elevation={0}
          sx={{
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
            }),
            marginLeft: open ? drawerWidth : 'auto',
            width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
            border: '1px solid #eee',
          }}
          ref={contentHeightRef}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ marginRight: 36 }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{flex: 1}} ></Box>
            <Box my={'auto'} alignSelf={'center'}>
              <CustomUserMenu user={identity?.user} contactid={identity?.contactid} />
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: open ? drawerWidth : drawerCloseWidth,
            backgroundColor: '#397F95',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
            }),
            boxShadow: '0 0 18px rgba(0,0,0,0.25)',
            overflowX: 'hidden',
            flexShrink: 0,
            whiteSpace: 'nowrap',
            height: '100vh', 
            '& .MuiPaper-root': {
              width: 'inherit !important',
              backgroundColor: '#397F95',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
              }),
            }
          }}
        >

          <>
            <Box mx={2} mt={3} mb={3}>
              {COMPANY_LOGO_URL
                ? <Box sx={{ width: '90%', cursor: 'pointer'}} mx={'auto'} onClick={() => redirect('/')}>
                    <img src={COMPANY_LOGO_URL} alt={'Company Logo'} style={{ width: '100%', cursor: 'pointer' }}  />
                  </Box>
                : <Typography variant="h6" color="inherit">{APP_NAME}</Typography>
              }
            </Box>
            <AppMenu menuitemslist={menuitemslist} isloadingmenu={isloadingmenu} sidebarisopen={sidebarisopen} dense={props?.dense} />
          </>
        </Drawer>
        <Box sx={{flexGrow: 1, padding: theme.spacing(3), width: `calc(100% - ${drawerWidth}px)`}}>
          <Box 
            ref={toolbarref}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: theme.spacing(0, 1),
              ...theme.mixins.toolbar,
            }}
          />
          <>
            <Box>
              <Typography
                variant="h6"
                color="inherit"
                id="react-admin-title"
                sx={{ flex: 'inherit', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
              />
            </Box>
            <> {isloading ? <Loading /> : <> {main} </>} </>
          </>
        </Box>
      </Box>
    </ContentContext.Provider>
  );
}

export default MiniDrawer