import React, { useEffect, useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Identifier, useRedirect } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';

import { TaskCard } from './TaskCard';
import { statusNames } from './status';
import { Box, Typography, useTheme } from '@mui/material';



export const TaskColumn = ({status,taskIds,data,resource,handleQuickCreateModal,modPermission,height} : {status: string, taskIds: Identifier[], data: any, resource: string, handleQuickCreateModal: any, modPermission: any, height: any}) => {
    const theme = useTheme();
    const redirect = useRedirect();
    const [contentheight, setContentheight] = useState(height);
    const contentHeightRef: any = useRef(null);
    const statuses: any = statusNames;


    useEffect(() => {
        if (contentHeightRef.current) {
            const offsetHeight = contentHeightRef.current.offsetHeight;
            setContentheight(offsetHeight)
          }
      
    }, [contentHeightRef])


    return (
        <Box sx={{flex: 1, paddingTop: 1,paddingBottom: 0, '&:first-child': {paddingLeft: 5,borderTopLeftRadius: 5,},'&:last-child': {paddingRight: 5,borderTopRightRadius: 5}}}>
            <Typography align="center" variant="subtitle1">
                {statuses[status]}  <AddIcon onClick={() => redirect(`/ProjectTask/create/?projecttaskstatus=${statuses[status]}`)}  sx={{marginLeft: theme.spacing(2),cursor: 'pointer',position: 'relative',top: '8px'}} />
            </Typography>
           <Droppable droppableId={status}>
                {(droppableProvided, snapshot) => (
                    <Box
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                        className={(snapshot.isDraggingOver ? ' isDraggingOver' : '')}
                        sx={{
                            height: contentheight + 50, 
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 5,
                            padding: 5, 
                            paddingTop: 1, 
                            minWidth: 320, 
                            cursor: 'move', 
                            '&.isDraggingOver': {backgroundColor: '#dadadf'}
                        }}
                    >
                        {taskIds?.map((id, index) => (
                            <TaskCard key={id} modPermission={modPermission} index={index} task={data[id]} resource={resource} handleQuickCreateModal={handleQuickCreateModal} />
                        ))}
                        {droppableProvided.placeholder}
                    </Box>
                )}
            </Droppable>
        </Box>
    );
};
