import React, { useState, useEffect } from 'react';
import { Show, TabbedShowLayout, TabbedShowLayoutTabs, Tab, Notification, useTranslate, FunctionField, EditButton, useRedirect, useResourceContext, usePermissions, useRecordContext } from 'react-admin';
import cbUtils from '../corebosUtils/corebosUtils';
import { getDataFromLocalDb } from '../../utils/Helpers';
import { TABLE_DESCRIBE } from '../../local-db';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';
import { getRelatedModules } from '../../utils/lib';
import Title from '../Title';
import { CbRelatedList } from '../cbRelatedList';
import { useSelector } from 'react-redux';
import DocumentPreviewModal from '../DocumentPreview/DocumentPreviewModal';
import { MarkDownPreview } from '../MarkDown';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';




const PageTitle = ({ resource, labelField, label, showTitle } : { resource: string, labelField: string, label?: string, showTitle: boolean }) => {
	const record = useRecordContext();

	return (
		<Box sx={{mb: 2, mr: 1}}> <Title resource={resource} label={label} value={record} showTitle={showTitle} linkField={labelField} /> </Box>
	)
};

const ShowActions = (props: any) => {

	const { redirect, resource, id, hasEdit } = props;

	return (
		<Box sx={{ alignItems: 'center', justifyContent: 'flex-end', display: 'flex', top: -54, position: 'absolute', zIndex: 99, width: '100%', flexDirection: 'row' }}>
			{hasEdit && <EditButton {...props} onClick={() => { redirect(`/${resource}/${id}`) }} />}
		</Box>
	)
};

export const CbShowTabGuesser = (props: any) => {
	const resource: any = useResourceContext();
	const [describe, setDescribe] = useState<any>({});
	const [blocks, setBlocks] = useState<any[]>([]);
	const allPermissions: any = usePermissions();
	const modPermission = allPermissions ? allPermissions?.permissions?.filter((permission: any) => permission.module === resource)[0]?.permissions : null;
	const [relatedModules, setRelatedModules] = useState<any[]>([]);
	const params: any = useParams();
	const id = params?.id || '';
	const translate = useTranslate();
	const isMedium = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
	const [expanded, setExpanded] = useState<any>(null);
	const [labelField, setLabelField] = useState<string>(props.label ?? '');
	const theme = useSelector((state: any) => state.theme);
	const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
	const [clickedItem, setClickedItem] = useState<any>({});
	const redirect = useRedirect();



	useEffect(() => {
		const getRelatedMods = (rModules: any, describeData: any) => {
			const relatedMods: any[] = [];
			rModules.forEach((rMod: any) => {
				const describeRelatedMod = describeData[rMod.loadfrom];
				if (describeRelatedMod) {
					describeRelatedMod.relatedfield = rMod.relatedfield;
					describeRelatedMod.moduleRelationType = rMod.relatedfield ? '1:N' : 'N:N';
					relatedMods.push(describeRelatedMod);
				}
			});
			return relatedMods;
		}
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result: any) => {
			setDescribe(result);
			let bfields: any[] = [];
			let mfields: any[] = result[resource]?.fields || [];
			for (const value of mfields) {
				if (!value.block) {
					continue;
				}
				let bidx = bfields.findIndex((element: any) => element.id === value.block.blockid);
				if (bidx === -1) {
					bfields.push({
						id: value.block.blockid,
						sequence: value.block.blocksequence,
						label: value.block.blocklabel,
						name: value.block.blockname,
						fields: []
					});
					bidx = bfields.findIndex((element: any) => element.id === value.block.blockid);
				}
				bfields[bidx].fields.push(value);
			}
			setBlocks(bfields);
			if (!props.label) {
				const labelFields = result[resource]?.labelFields;
				if (labelFields) {
					let aLabelField = labelFields.split(',');
					aLabelField = aLabelField[0] ? aLabelField[0] : '';
					setLabelField(aLabelField);
				}
			}
			if (!props.hiderelmod) {
				getRelatedModules(resource).then((rMods: any) => {
					if (rMods?.blocks) {
						const rModules = Object.values(rMods.blocks);
						const relatedMods = getRelatedMods(rModules, result);
						setRelatedModules(relatedMods);
					}
				}).catch(() => {
					console.log(translate('translations.somethingWentWrong'));
				})
			}
		});
	}, [props.hiderelmod, props.label, resource, translate])


	const handleChange = (panel: any) => (event: any, expanded: any) => {
		setExpanded(expanded ? panel : false);
	};

	const handleDocPreviewModalOpen = (record: any, _moduleName: string, field: any = null) => {
		if (record) {
			record['title'] = field?.label ?? '';
			record['_downloadurl'] = record[field?.name] ?? '';
			if (!record.filetype) {
				const urlStr = record[field?.name] ? record[field.name].split('.') : [];
				record['filetype'] = urlStr[urlStr.length - 1] ?? '';
			}
		}
		setClickedItem(record);
		setDocPreviewOpen(true);
	};


	return (
		<Box px={0.5} mt={3}>
			<DocumentPreviewModal record={clickedItem} title={clickedItem?.title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />

			<Grid container spacing={4} >
				<Grid item xs={12} md={relatedModules && relatedModules.length > 0 ? 8 : 12} sx={{ minHeight: '100vh', background: theme === 'dark' ? 'inherit' : '#fff' }}>
					<Box className='raPageContent' sx={{ position: 'relative' }}>
						<Show
							title={ <PageTitle resource={resource} labelField={labelField} showTitle={true} />}
							actions={<ShowActions hasShow={modPermission?.list} hasList={modPermission?.list} hasCreate={modPermission?.create} hasEdit={modPermission?.update} redirect={redirect} resource={resource} id={id} />}
						>
							<>
								{
									isMedium
										? <TabbedShowLayout syncWithLocation={false} tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" {...props} />} >
											{
												blocks.map((block: any) => {
													return (
														<Tab key={'fbrefblk' + block.name} label={block.name}>
															<Box style={{ width: '100%' }} py={3}>
																<Grid container spacing={2} >
																	{
																		block.fields.map((field: any) => {
																			let handleFieldClick: any = null;
																			if (field.uitype === '19') {
																				return (
																					<Grid key={field.name} item xs={12} md={12}>
																						<FunctionField label={''} render={(aRecord: any) => {
																							return (
																								<Box>
																									<Typography variant="subtitle2"> {field.label} </Typography>
																									<MarkDownPreview record={aRecord} source={field.name} />
																								</Box>
																							)
																						}
																						} />
																					</Grid>
																				)
																			}
																			if (field.uitype === '28' || field.uitype === '69') {
																				handleFieldClick = handleDocPreviewModalOpen;
																			}
																			return (
																				<Grid key={field.name} item xs={12} md={6}>
																					<Typography variant="subtitle2"> {field.label} </Typography>
																					<FunctionField key={field.name} label={field.label} render={(record: any) => {
																						return (
																							<> {cbUtils.field2DisplayRecord(field, record, resource, describe, '', '', handleFieldClick)} </>
																					)}}/>
																				</Grid>
																			)

																		})
																	}
																</Grid>
															</Box>
														</Tab>
													)
												})
											}
										</TabbedShowLayout>
										: <>
											{
												blocks.map((block) => {
													return (
														<Accordion key={block.name} expanded={expanded === block.name} onChange={handleChange(block.name)}>
															<AccordionSummary expandIcon={<ExpandMoreIcon />}>
																<Typography> {block.name} </Typography>
															</AccordionSummary>
															<AccordionDetails>
																<Grid container spacing={2} >
																	{
																		block.fields.map((field: any) => {
																			let handleFieldClick: any = null;
																			if (field.uitype === '19') {
																				return (
																					<Grid key={field.name} item xs={12} md={12}>
																						<FunctionField label={''} render={(aRecord: any) => {
																							return (
																								<Box>
																									<Typography variant="subtitle2"> {field.label} </Typography>
																									<MarkDownPreview record={aRecord} source={field.name} />
																								</Box>
																							)
																						}
																						} />
																					</Grid>
																				)
																			}
																			if (field.uitype === '28' || field.uitype === '69') {
																				handleFieldClick = handleDocPreviewModalOpen;
																			}

																			return (
																				<Grid key={field.name} item xs={12} md={6}>
																					<Typography variant="subtitle2"> {field.label} </Typography>
																					<FunctionField key={field.name} label={field.label} render={(record: any) => {
																						return (
																							<> {cbUtils.field2DisplayRecord(field, record, resource, describe, '', '', handleFieldClick)} </>
																					)}}/>
																				</Grid>
																			)
																		})
																	}
																</Grid>
															</AccordionDetails>
														</Accordion>
													)
												})
											}
										</>
								}
							</>

						</Show>
					</Box>
				</Grid>
				{relatedModules.length > 0 &&
					<Grid item xs={12} md={4} style={{ borderLeft: '1px solid #eee', height: '100vh', overflowY: 'auto', padding: '0' }}>
						<Box mt={4}>
							{relatedModules.map((relatedModule: any) => {
								return (
									<Box key={relatedModule?.name}>
										<CbRelatedList id={id} describe={describe} basePath={relatedModule?.name} relatedModule={relatedModule} relatedParentModule={resource} moduleRelationType={relatedModule.moduleRelationType} />
									</Box>
								);
							})}
						</Box>
					</Grid>
				}
			</Grid>
			<Notification />
		</Box>
	)
};
