import * as React from 'react';
import { useEffect, useState } from 'react';

import { LayoutProps, useLocaleState } from 'react-admin';
import MiniDrawer from './persistentdrawer';
import { getDataFromLocalDb, loginAction, prepareMenu } from '../utils/Helpers';
import { TABLE_DESCRIBE } from '../local-db';
import { getMenu } from '../utils/lib';
import { authProvider } from '../authProvider';
import { CbMenuItem } from '../types';


const _window: any = window;
if (_window.coreBOS === undefined) {
    _window.coreBOS = {};
}

const Layout = (props: LayoutProps) => {

    const [locale, setLocale] = useLocaleState();
    const [isloading, setIsloading] = useState<boolean>(false);
    const [isloadingmenu, setIsloadingmenu] = useState<boolean>(false);
    const [menuitemslist, setMenuitemslist] = useState<any[]>([]);


    useEffect(() => {
        setIsloading(true);
        getDataFromLocalDb(TABLE_DESCRIBE.tableName).then(async (describeData: any) => {
            setIsloadingmenu(true);
            let menuRes: any = await getMenu();
            setIsloadingmenu(false);
            menuRes = menuRes ?? {};
            const preparedUserMenu: CbMenuItem[] = prepareMenu(menuRes) ?? [];
            setMenuitemslist(preparedUserMenu);
            if(!describeData){
                const coreBOSDescribe = [];
                for (const key in preparedUserMenu) {
                    const menuItem: CbMenuItem = preparedUserMenu[key];
                    if(menuItem.name && menuItem.name !== 'Reports' && menuItem.type === 'module') {
                        coreBOSDescribe.push(menuItem.name) 
                    }
                }
                if(coreBOSDescribe.length > 0){
                    return loginAction(coreBOSDescribe).then((describe: any) => {
                        _window.coreBOS.Describe = describe;
                        window.location.reload();
                    })
                } else {
                    authProvider.logout().then(() => {
                        window.location.href = '#/login';
                        window.location.reload();
                    });
                }
            } else{
                _window.coreBOS.Describe = describeData;
                return Promise.resolve();
            }
        }).catch((error) => {
            setIsloadingmenu(false);
            console.log(error);
        }).finally(() => {
            setIsloading(false);
        })
    }, []);

    useEffect(() => {
        const lang = localStorage.getItem('locale') ?? 'en'; // Set default locale to english
        setLocale(lang);
    }, [setLocale, locale]); 


    return <MiniDrawer menuitemslist={menuitemslist} isloadingmenu={isloadingmenu} main={props.children} isloading={isloading} props={props} />
};

export default Layout;
