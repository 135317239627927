import * as React from 'react';
import { Loading, useListContext, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { Chip, Divider, ListItemSecondaryAction, ListItemText, ListItem, List, Card, CardHeader, Box, Typography } from '@mui/material';




const CustomComponentList = (props: any) => {

    const {data,isLoading,resource} = useListContext<any>();
    const translate = useTranslate();
    const { describe } = props;
    const userlist = describe[resource]?.userlist ?? [];

    
    if (isLoading) return <Loading loadingPrimary='' loadingSecondary='' />

    if (!data) return <></>

    return (
        <Box my={2}>
            <Card elevation={2}>
                <CardHeader style={{ borderBottom: '1px solid gray', background: '#397F95', color: 'white', fontWeight: 'bold' }}
                    title={translate('Sub Tasks')}
                />
                <Box p={2} style={{ borderTop: '1px solid #ddd' }}>
                    <List>
                        {data.map((record: any, index: number) => {
                            let assignedUser: any = userlist.filter((user: any) => user.userid === record.assigned_user_id);
                            assignedUser = assignedUser[0] || {};
                            return (
                                <>
                                    <ListItem key={record.id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <ListItemText
                                            primary={<Typography variant="inherit" component={Link} to={`/${props.resource}/${record.id}/show`} color="primary" sx={{ textDecoration: 'none' }}>
                                                {record.projecttaskname}
                                            </Typography>}
                                            secondary={assignedUser?.username}
                                            style={{ marginRight: 30, flex: 1 }}
                                        />
                                        <ListItemSecondaryAction style={{ flex: 1 }}>
                                            {record.projecttaskstatus === 'Backlog' &&
                                                <Chip label={record.projecttaskstatus} color='default' variant="outlined" />
                                            }
                                            {record.projecttaskstatus === 'Open' &&
                                                <Chip label={record.projecttaskstatus} color='default' />
                                            }
                                            {record.projecttaskstatus === 'Completed' &&
                                                <Chip label={record.projecttaskstatus} style={{ backgroundColor: 'green' }} color='secondary' />
                                            }
                                            {record.projecttaskstatus === 'Approved' &&
                                                <Chip label={record.projecttaskstatus} style={{ backgroundColor: 'green' }} color='secondary' />
                                            }
                                            {record.projecttaskstatus === 'Done' &&
                                                <Chip label={record.projecttaskstatus} style={{ backgroundColor: 'green' }} color='secondary' />
                                            }
                                            {record.projecttaskstatus === 'To Be Tested Demo' &&
                                                <Chip label={'TBT Demo'} style={{ backgroundColor: 'green' }} color='secondary' />
                                            }
                                            {record.projecttaskstatus === 'To Be Tested Production' &&
                                                <Chip label={'TBT Production'} style={{ backgroundColor: 'green' }} color='secondary' />
                                            }
                                            {record.projecttaskstatus === 'In Progress' &&
                                                <Chip label={record.projecttaskstatus} color='secondary' style={{ backgroundColor: '#6a96fd' }} />
                                            }
                                            {record.projecttaskstatus === 'Doing' &&
                                                <Chip label={record.projecttaskstatus} color='secondary' style={{ backgroundColor: '#6a96fd' }} />
                                            }
                                            {record.projecttaskstatus === 'To Do' &&
                                                <Chip label={record.projecttaskstatus} color='secondary' />
                                            }
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {index + 1 < data.length &&
                                        <Divider variant="fullWidth" component="li" />
                                    }
                                </>
                            )
                        })}
                    </List>
                </Box>
            </Card>
        </Box>
    )
}
export default CustomComponentList;