import React, { useId } from "react";
import Scatterchart from './charts/Scatterchart';
import Table from './table';
import Piechart from './charts/PieChart';
import Numberchart from './charts/Numberchart';
import Rowchart from './charts/Rowchart';
import Timeline from './Timeline'
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";

const DynamicComponent = (props: any) => {

	const randomKey = useId();

	
	const getChart = (question: any) => {

		return (
			<>
				{question.type === 'RowChart' &&
					<Rowchart questions={question} />
				}
				{question.type === 'Scatter' &&
					<Scatterchart questions={question} />
				}
				{question.type === 'Pie' &&
					<Piechart questions={question} />
				}
				{question.type === 'Number' &&
					<Numberchart questions={question} />
				}
				{question.type === 'Timeline' &&
					<Timeline questions={question} />
				}
				{question.type === 'Table' &&
					<Table questions={question} />
				}
			</>
		);
	}

	const generateDOM = () => {
		return props?.questionsdata?.map((question: any, index: number) => {

			return (
				<Grid key={randomKey+'_'+index} item xs={12} md={12} lg={question.type === 'Table' ? 12 : 6}>
					<Card elevation={4} style={{ marginBottom: 50, border: '1px solid #ddd', borderRadius: '15px' }}>
						<Box p={2} style={{ background: 'rgb(0 128 150)' }} >
							<Typography style={{ color: 'white', fontSize: '1.5em' }} variant="subtitle1"> {question?.title} </Typography>
						</Box>
						<Divider />
						<CardContent>
							<Paper elevation={0} style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', border: 'none' }}>
								<Box style={{ width: question.type === 'Table' || question.type === 'Timeline' ? '100%' : '80%', alignItems: 'center', flexDirection: 'column', display: 'flex', border: 'none' }}> {getChart(question)} </Box>
							</Paper>
						</CardContent>
					</Card>
				</Grid>

			);
		})
	}


	return (
		<Grid container spacing={2}> {generateDOM()} </Grid>
	);

}

export default DynamicComponent;