import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import Box from '@mui/material/Box';

const Rowchart = (props: any) => {

  const [state, setState] = useState<any>({
    ...props,
    series: [{
      data: []
    }],
    options: {
      chart: {
        id: props.chartElementId ?? 'rowChart',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: 16
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            fontSize: 16
          }
        }
      }
    },
  })

  useEffect(() => {
    const rowdata = Array.isArray(props?.questions?.answer) ? props?.questions?.answer?.map((question: any) => Object.values(question)[1]) : [];
    let rowcat = Array.isArray(props?.questions?.answer) ? props?.questions?.answer?.map((question: any) => `${Object.values(question)[0]}`) : [];

    setState({
      series: [{
        data: rowdata
      }], 
      options: {
        chart: {
          id: props.chartElementId ?? 'rowChart',
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: rowcat,
          labels: {
            style: {
              fontSize: 16
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              fontSize: 16
            }
          }
        }
      },
    })
  }, [props.chartElementId, props?.questions?.answer])


  return (
    <Box id="chart" sx={{ width: '100%', marginBottom: 50 }}>
      {props?.questions?.answer ? <Chart options={state.options} series={state.series} type="bar" /> : null}
    </Box>
  );
}

export default Rowchart;