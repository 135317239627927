import * as React from 'react';
import { useState, useEffect } from 'react';
import {useDelete,useNotify,Notification} from 'react-admin';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { COMMENT_REPLY_LIMIT, REPLY_TEXT_LENGTH } from '../constant';
import { redirect, useLocation } from "react-router-dom";
import CommentForm from "./CommentForm";
import { removeTags } from '../utils';
import RecipeReviewCard from './Commentelement';
import { doQuery } from '../../utils/lib';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';


export const CommentItem = ({ comment, projectTask, setReloadTriggerID, resource, handleDocPreviewModalOpen, contacts, describe}:{ comment:any, projectTask: any, setReloadTriggerID: any, resource: string, handleDocPreviewModalOpen: any, contacts: any, describe: any}) => {

    const [isEditingMode, setIsEditingMode] = useState(false);
    const [isReplyingMode, setIsReplyingMode] = useState(false);

    const notify = useNotify();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [clickedCommentId, setClickedCommentId] = useState('');
    const [commentReplies, setCommentReplies] = useState<any[]>([]);
    const [isLoadingReplies, setIsLoadingReplies] = useState<boolean>(false);
    const [replyingComment, setReplyingComment] = useState<any>({});
    const [editingComment, setEditingComment] = useState<any>({});
    const [more , setMore] = useState(2);
    const [deleteOne] = useDelete();
    const location = useLocation();


    useEffect(() => {
        setIsLoadingReplies(true);
        doQuery(`SELECT * FROM ${resource} WHERE parent_comments = ${comment.id} ORDER BY createdtime DESC LIMIT ${0}, ${COMMENT_REPLY_LIMIT}`).then((result) => {
            setCommentReplies(result);
            setIsLoadingReplies(false);
        }).catch(() => {
            setIsLoadingReplies(false);
        })
    }, [comment.id, resource])

    useEffect(() => {
        if(clickedCommentId){
            const clickedCommentEl:any = document.getElementById(clickedCommentId);
            clickedCommentEl.scrollIntoView({ behavior: 'smooth', block: 'start'});
        }else{
            const aUrl = new URLSearchParams(location.search);
            const commentId: any = aUrl.get('commentId');
            if(commentId){
                redirect(`/${location.pathname}?${new URLSearchParams({commentId: commentId}).toString()}`);
                setTimeout(() => {
                    const clickedCommentEl:any = document.getElementById(commentId);
                    if(clickedCommentEl){
                        clickedCommentEl.scrollIntoView({ behavior: 'smooth', block: 'start'});
                    }
                }, 3000);
            }
        }
    }, [clickedCommentId, location.pathname, location.search])

    const deleteComment = (_comment: any) => {
        setIsLoading(true);
        deleteOne(resource, { id: _comment.id, previousData: _comment },
            {
                onSuccess: () => {
                    setReloadTriggerID(new Date());
                    setIsLoading(false);
                },
                onError: (error: any) => {
                    setIsLoading(false);
                    notify(error.message, {type: 'error'});
                }
            }
        );
    }

    const handleEnterEditMode = (aComment: any) => {
        setEditingComment(aComment);
        setIsEditingMode(true);
    };

    const handleEnterReplyingMode = (commentReplied: any) => {
        setReplyingComment(commentReplied);
        setIsReplyingMode(true);
    };

    const handleCancelReply = () => {
        setIsReplyingMode(false);
        setReplyingComment('');
    };

    const handleCancelEdit = () => {
        setEditingComment(null);
        setIsEditingMode(false);
    };


    return (
        <Box style={{display: 'flex', flexDirection: 'column', width: '100%'}} py={1} mt={-2}>
            
            {isEditingMode ? (  
                <CommentForm contacts={contacts} isNewRecord={false} comment={editingComment} handleCancel={handleCancelEdit} replyingComment={replyingComment} projectTask={projectTask} setReloadTriggerID={setReloadTriggerID} resource={resource} />
            ) : (
                <Box mt={1}>
                     {!comment.parent_comments &&

                     <RecipeReviewCard describe={describe} setMore={setMore} type={'comment'} maxWidth={'100%'} borderTopColor={'#58b6d5'} isLoading={isLoading} deleteComment={deleteComment} setReloadTriggerID={setReloadTriggerID} isReplyingMode={isReplyingMode} replyingComment={replyingComment} projectTask={projectTask} setClickedCommentId={setClickedCommentId} handleDocPreviewModalOpen={handleDocPreviewModalOpen} resource={resource} handleEnterEditMode={handleEnterEditMode} handleEnterReplyingMode={handleEnterReplyingMode} isLoadingReplies={isLoadingReplies} comment={comment} parentCommentID={comment.id} aComment={comment} >     
                              <>
                                {isReplyingMode &&
                                     <Box mt={2}>
                                         <em><FormatQuoteIcon /><small>{ removeTags(replyingComment?.commentcontent)?.slice(0, REPLY_TEXT_LENGTH) } </small> {'...' } <FormatQuoteIcon /></em>
                                         <CommentForm contacts={contacts} isNewRecord={true} comment={null} handleCancel={handleCancelReply} replyingComment={replyingComment} projectTask={projectTask} setReloadTriggerID={setReloadTriggerID} resource={resource} />
                                     </Box>
                                 }
                    
                                 {
                                     commentReplies?.slice(0, more).map((reply: any) => {
                                         return (
                                            <RecipeReviewCard key={reply.id} describe={describe} setMore={setMore} type={'reply'}  maxWidth={'90%'} borderTopColor={'#70e0bb'} isLoading={isLoading} deleteComment={deleteComment} replyingComment={replyingComment} projectTask={projectTask} setClickedCommentId={setClickedCommentId} handleDocPreviewModalOpen={handleDocPreviewModalOpen} resource={resource} handleEnterEditMode={handleEnterEditMode} handleEnterReplyingMode={handleEnterReplyingMode} isLoadingReplies={isLoadingReplies} comment={reply} parentCommentID={''} aComment={reply} isReplyingMode={undefined} setReloadTriggerID={undefined} ><></> </RecipeReviewCard>
                                         )
                                     })
                                 }
                                {commentReplies?.length > 1 && more === 2 &&
                                    <Box sx={{width: '90%', marginLeft: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <Typography variant="body2" onClick={() => {setMore(commentReplies?.length)}} sx={{cursor: 'pointer'}}>Show more</Typography>
                                    </Box> 
                                }
                                {commentReplies?.length > 1 && more > 2 &&
                                    <Box sx={{width: '90%', marginLeft: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <Typography variant="body2" onClick={() => {setMore(2)}} sx={{cursor: 'pointer'}}> Show less</Typography>
                                    </Box> 
                                }
                             </>
                       </RecipeReviewCard>
                    }

                </Box>
            )}
            <Notification />
        </Box>
    )

}