import React from 'react';
import TrashIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReplyIcon from '@mui/icons-material/Reply';
import { CommentContent } from './CommentContent';
import {DateField} from 'react-admin';
import { CircularProgress, Divider, Button, Typography, Avatar, Collapse, CardActions, CardContent, CardHeader, Card, Box, useTheme, useMediaQuery } from '@mui/material';



export default function RecipeReviewCard({ resource, parentCommentID, aComment, comment, handleEnterEditMode, handleEnterReplyingMode, isLoadingReplies, handleDocPreviewModalOpen, setClickedCommentId, isReplyingMode, deleteComment, isLoading, children, maxWidth, borderTopColor, type, setMore, describe }: Readonly<{ parentCommentID: any, aComment: any, comment: any, handleEnterEditMode: any, handleEnterReplyingMode: any, isLoadingReplies: any, resource: any, handleDocPreviewModalOpen: any, setClickedCommentId: any, isReplyingMode: any, replyingComment: any, projectTask: any, setReloadTriggerID: any, children: any, deleteComment: any, isLoading: any, maxWidth: any, borderTopColor: any, type: any, setMore: any, describe: any }>) {

  const theme = useTheme();
  const expanded = true;
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const commentcreator = Array?.isArray(describe?.Contacts?.userlist) ? describe?.Contacts?.userlist?.find((creator: { userid: any; }) => creator.userid === comment?.creator) : {};

  return (
    <Card elevation={3} sx={{ borderTop: `4px solid ${borderTopColor}`, marginTop: 4, width: '100%', marginLeft: type === 'comment' ? '0%' : '10%' }}>
      <CardHeader
        avatar={
          <Avatar aria-label="username" sx={{backgroundColor: theme.palette.primary.main}}>
            {commentcreator?.username?.charAt(0) || ''}
          </Avatar>
        }
        action={ !isSmall &&
          <Box sx={{mt: 1.5}}>
            <DateField
              source="createdtime"
              record={comment}
              variant="body2"
              showTime
              locales="en"
              options={{
                dateStyle: 'full',
                timeStyle: 'short',
              }}
              sx={{px: 1, color: '#000'}}
            />
          </Box>
        }
        title={<Typography component="span" variant="body2" sx={{marginTop: 5, borderRadius: 5, color: '#000'}}>{commentcreator?.username}</Typography>}
        subheader={isSmall &&
          <DateField
            source="createdtime"
            record={comment}
            variant="body2"
            showTime
            locales="en"
            options={{
              dateStyle: 'full',
              timeStyle: 'short',
            }}
          />
        }
      />
      <Divider />
      <CardContent style={{ backgroundColor: '#fafafa' }}>
        <CommentContent handleDocPreviewModalOpen={handleDocPreviewModalOpen} aComment={comment} setClickedCommentId={setClickedCommentId} resource={resource} />
      </CardContent>
      <Divider />
      <CardActions disableSpacing sx={{justifyContent: 'end'}}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            sx={{backgroundColor: 'red'}}
            size="small"
            title="Delete"
            onClick={() => deleteComment(aComment)}
            endIcon={<TrashIcon />}
          >
            {isLoading ? <CircularProgress color="primary" size={20} /> : 'Delete'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{margin: theme.spacing(1), backgroundColor: '#50abdb'}}
            size="small"
            title="Edit"
            onClick={() => handleEnterEditMode(aComment)}
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
          {parentCommentID &&
            <Button
              variant="contained"
              color="primary"
              sx={{margin: theme.spacing(1), backgroundColor: '#50abdb'}}
              size="small"
              title="Reply"
              onClick={() => { setMore(2); handleEnterReplyingMode(aComment); }}
              endIcon={<ReplyIcon />}
            >
              Reply
            </Button>
          }
        </Box>

      </CardActions>
      <Collapse style={{ width: '100%', height: 'auto' }} in={expanded} timeout="auto" unmountOnExit>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 20,
            paddingRight: 20,
            position: 'relative',
            paddingBottom: '0px !important',
          }}
        >
          {children}
        </CardContent>
      </Collapse>
    </Card>
  );
}
