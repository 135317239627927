import React, { useState, useEffect } from 'react';
import { Edit, usePermissions, useResourceContext } from 'react-admin';
import { getDataFromLocalDb } from '../../utils/Helpers';
import { TABLE_DESCRIBE } from '../../local-db';
import {EditTitle} from '../Title';
import EditForm from '../EditForm';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


export const CbEditTabGuesser = (props:any) => {
	
	const resource: any = useResourceContext();
	const [ labelField, setLabelField ] = useState<string>(props.label??'');
	const [describe, setDescribe] = useState<any>({});
	const [blocks, setBlocks] = useState<any[]>([]);
	const allPermissions: any = usePermissions();
	const modPermission = allPermissions ? allPermissions?.permissions?.filter((permission: any) => permission.module === resource)[0]?.permissions : null;

	

	useEffect(() => {
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then(async (result: any) =>  {
			setDescribe(result);
			let bfields: any[] = [];
			let mfields: any[] = result[resource]?.fields ?? [];
			for (const value of mfields) {
				if (!value.block) {
					continue;
				}
				let bidx = bfields.findIndex((element :any) => element.id === value.block.blockid);
				if (bidx===-1) {
					bfields.push({
						id: value.block.blockid,
						sequence: value.block.blocksequence,
						label: value.block.blocklabel,
						name: value.block.blockname,
						fields: []
					});
					bidx = bfields.findIndex((element :any) => element.id === value.block.blockid);
				}
				if(value.editable !== false && value.displaytype !== '4') {
					bfields[bidx].fields.push(value);
				}
			}
			const allBlocks: any[] = bfields.filter((fieldBlock: any) => fieldBlock.fields.length > 0);
			setBlocks(allBlocks);
			if(!props.label){
				const labelFields = result[resource]?.labelFields;
				if(labelFields){
					let aLabelField = labelFields.split(',');
					aLabelField = aLabelField[0] ? aLabelField[0] : '';
					setLabelField(aLabelField);
				}
			}
		});
	}, [props.label, resource])
	
	return (
		<Box px={0.5} >
			<Grid container spacing={1}>
				<Grid item xs={12} md={12}>
					<Box className='raPageContent'>
						<Edit {...props} resource={resource} title={ <EditTitle resource={resource} showTitle={true} value={props?.record} linkField={labelField}  /> }  >
							<EditForm describe={describe} blocks={blocks} modPermission={modPermission} {...props} resource={resource} />
						</Edit>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
};

