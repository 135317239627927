import React from 'react';
import Demographs from '../demographs';
import Box from '@mui/material/Box';



const Dashboard = () => {

  return (
    <Box sx={{flexGrow: 1}}>
      <Demographs />
    </Box>
  );
}

export default Dashboard;
