import { EditButton, Notification, useTranslate, ReferenceField, TextField } from 'react-admin';
import { Draggable } from 'react-beautiful-dnd';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from 'react-router-dom';
import { Avatar, useTheme, Box, Card, Typography, CardActions, IconButton } from '@mui/material';
import common from '@mui/material/colors/common';
import { getAssignee } from '../utils/Helpers';



export const TaskCard = ({ task, index, resource, handleQuickCreateModal, modPermission }: { task: any; index: number, resource: string, handleQuickCreateModal: any, modPermission: any }) => {

    const theme = useTheme();
    const translate = useTranslate();


     if (!task) return null;

    return (
        <>
        <Draggable draggableId={String(task.id)} index={index}>
            {(provided, snapshot) => (
                <Box
                    sx={{
                        marginBottom: theme.spacing(1),
                    }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <Card
                        sx={{
                            opacity: snapshot.isDragging ? 0.9 : 1,
                            transform: snapshot.isDragging ? 'rotate(-2deg)' : '',
                            border: () => {
                                if(task.regression === 1 || task.regression === '1' || task.regression === 'yes'){
                                    return `1px solid ${theme.palette.error.dark}`
                                } else if(task.change_freq === 1 || task.change_freq === '1' || task.change_freq === 'yes') {
                                    return `1px solid ${theme.palette.warning.light}`
                                } else {
                                    return '1px solid #ddd'
                                }
                            },
                            
                        }}
                        elevation={snapshot.isDragging ? 3 : 3}
                    >
                        {task.parenttask &&
                            <Box py={1} px={2} display={'flex'} style={{width: '100%'}}>
                                <Typography variant="body2" color="textSecondary" component="span"> {translate('Parent')}:&nbsp;</Typography>
                                <ReferenceField key='refparenttask' label='' source='parenttask' record={task} reference={resource} link='show' sortBy={resource+'.parenttask'} >
                                    <TextField key='refparenttask' source='projecttaskname' />
                                </ReferenceField>
                            </Box>
                        }
                        <Box py={0} px={2} display={'flex'} style={{width: '100%'}}>
                            <Box mt={1} justifyContent="start" style={{width: '100%'}}>
                            <Box sx={{position: 'relative',display: 'flex',flexDirection: 'row',alignItems: 'center',justifyContent: 'space-between'}}>
                                <Typography variant="subtitle2" component={Link} to={`/${resource}/${task?.id}/show`} color="primary" style={{textDecoration: 'none'}}>
                                    {task.projecttaskname}
                                </Typography>
                                <IconButton edge='end' disableFocusRipple disableRipple disableTouchRipple color='primary' onClick={ () => handleQuickCreateModal(task, true)} title={translate('Time Control')}  >
                                    <AccessTimeIcon fontSize="large" />
                                </IconButton>
                            </Box>
                                <Typography variant="subtitle2" style={{fontSize: '12px'}}>{task.projecttask_no}</Typography>
                                <Box mt={1} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', position: 'relative'}}>
                                    <Typography variant="subtitle2" style={{fontSize: '12px'}}>{task.projecttasktype}</Typography>
                                    <Typography variant="subtitle2" style={{fontSize: '12px', textAlign: 'end'}}>{task.projecttaskpriority}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <CardActions>
                            <Box px={1} width='100%'>
                                <Typography variant="body2" color="textSecondary" component="span">{translate('Effort')}: {task.effortdevel} </Typography>
                                <Typography variant="body2" color="textSecondary" component="span"> {task.projecttaskprogress} </Typography>
                                {modPermission?.update && 
                                <EditButton
                                    to={`/${resource}/${task.id}`}
                                    resource={resource}
                                    label={translate('Edit')}
                                    record={JSON.parse(JSON.stringify(task))}
                                    sx={{marginLeft: theme.spacing(1), textTransform: 'none'}}
                                />
                                }
                            </Box>
                            <Box style={{float: 'right'}}>
                                <Avatar 
                                    title={task?.ename ||  task?.assigned_user_idename?.reference}
                                    alt={task?.ename ||  task?.assigned_user_idename?.reference}
                                    sx={{cursor: 'pointer', objectFit: 'contain', width: theme.spacing(5), height: theme.spacing(5), color: theme.palette.getContrastText(common.black)}}
                                    //onClick={() => redirect(`/Contacts/${task.assigned_user_id}/show`)}
                                >
                                    <>{getAssignee(task)}</>
                                </Avatar>
                            </Box>
                        </CardActions>
                    </Card>
                </Box>
            )}
        </Draggable>
        <Notification />
        </>
    );
};
