import React, { useState, useEffect } from 'react';
import { List, useListContext, Loading, useTranslate } from 'react-admin';
import CbRecordDetail from './cbRecordDetail';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {Link} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../types';
import { authProvider } from '../authProvider';
import CbCreateRelatedRecordDrawer from './CbCreateRelatedRecordDrawer';
import DocumentPreviewModal from './DocumentPreview/DocumentPreviewModal';
import { getListColumns } from '../utils/lib';
import { TABLE_AUTH } from '../local-db';
import { getDataFromLocalDb } from '../utils/Helpers';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { AccordionProps, AccordionSummaryProps, Box, Button, Typography, styled } from '@mui/material';


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(() => ({
    root: {
        boxShadow: 'none',
        background: 'inherit',
        borderBottom: '1px solid #eee',
        padding: '1px 0',
        border: 'none',
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: '0',
        },
    },
    expanded: {},
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<></>} {...props} />
  ))(({ theme }) => ({
    root: {
        marginBottom: -8,
        padding: '0 15px',
        minHeight: 15,
        '&$expanded': {
            minHeight: 56,
            margin: '12px 0',
        },
    },
    expanded: {},
}));
export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    '& .list-page': {
        width: '100%',
    },
}));
  
const pagesize = 3;

export const CbRelatedList = ({id, describe, basePath, relatedModule, relatedParentModule, moduleRelationType } : {id: string, describe: any, basePath: string, relatedModule: any, relatedParentModule: string, moduleRelationType: string }) => {
    const resource = relatedModule?.name ?? '';
    let realResourceName = resource;
    if(realResourceName === 'Project'){
        realResourceName = 'Projects';
    }
    const translate = useTranslate();
    const theme = useSelector((state: AppState) => state.theme);
    const [title, setTitle] = useState<string>(`${resource} (${0})`);
    const linkfields = describe[resource]?.filterFields?.linkfields ?? [];
    const [expanded, setExpanded] = useState<any>('');
    const [ modPermissions, setModPermissions ] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [ fields, setFields ] = useState<any[]>([]);

	const defaultSearchquery: any = {};
	defaultSearchquery[relatedModule?.relatedfield] = id;
	defaultSearchquery['relatedModule'] = relatedParentModule;
	defaultSearchquery['moduleRelationType'] = moduleRelationType;
	defaultSearchquery['joinCondition'] = 'AND';
    const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
    const [ clickedItem, setClickedItem ] = useState<any>({});
    const [user, setUser] = useState({});
    

    useEffect(() => {
        getDataFromLocalDb(TABLE_AUTH.tableName).then((result) => {
            setUser(result?.user??{});
        });
        authProvider.getModulePermission(resource).then((result: any) => {
            setModPermissions(result);
        });

        getListColumns(relatedParentModule).then((result) => { 
            let relatedListColumnNames: any[] = [];
            let modFields = describe[resource]?.filterFields?.fields ?? [];
            let realResourceName = resource;
            if(realResourceName === 'Project'){
                realResourceName = 'Projects';
            }
            if(describe[resource]?.ListFieldsName){
                for (const key in describe[resource].ListFieldsName) {
                    if (Object.hasOwn(describe[resource].ListFieldsName, key)) {
                        relatedListColumnNames.push(describe[resource].ListFieldsName[key]);
                    }
                }
                // eslint-disable-next-line array-callback-return
                let relatedFields = modFields.filter((field: any) => {
                    if(relatedListColumnNames.includes(field.name)) return field;
                })
                setFields(relatedFields);
            } else {
                const relModFilterFields = describe[realResourceName]?.filterFields?.fields ?? [];
                const relModFields = describe[realResourceName]?.fields ?? [];
                // eslint-disable-next-line array-callback-return
                let relfields = relModFields.filter((field: any) => {
                    if(relModFilterFields.includes(field.name)) {
                        return field;
                    };
                })
                if(relfields.length > 0){
                    setFields(relfields);
                } else {
                    setFields([]);
                }
            }
        }).catch(() => {
            console.log(translate('translations.somethingWentWrong'));
            const relModFilterFields = describe[realResourceName]?.filterFields?.fields ?? [];
                const relModFields = describe[realResourceName]?.fields ?? [];
                const relfields: any[] = [];
                relModFields.forEach((field: any) => {
                    if(relModFilterFields.includes(field.name)) {
                        relfields.push(field);
                    };
                })
                if(relfields.length > 0){
                    setFields(relfields);
                } else {
                    setFields([]);
                }
        })
        
    }, [describe, realResourceName, relatedParentModule, resource, translate])

    const handleChange = (panel: any) => (event: any, newExpanded: any) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleDrawer = () => {
        setOpen(!open);
    }

    const handleDocPreviewModalOpen = (record: any, _moduleName: string, field:any =null) => {
        if(record){
			record['title'] = field?.label ?? '';
			record['_downloadurl'] = record[field?.name] ?? '';
			if(!record.filetype){
				const urlStr: string[] = record[field?.name] ? record[field.name].split('.') : [];
				record['filetype'] = urlStr[urlStr.length-1] ?? '';
			}
		}
		setClickedItem(record);
        setDocPreviewOpen(true);
    };


    return (
        <Box mt={2} >
            {/**
             * TransitionProps={{ unmountOnExit: true }} 
             * Records will be fetched when the accordion is opened, which causes difficulties on 
             * knowing the total number of records. But in case of performance, this feature is good. 
             */}
            <DocumentPreviewModal record={clickedItem} title={clickedItem?.title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />
            <Accordion square expanded={expanded === resource} onChange={handleChange(resource)}>
                <Box display={'flex'}>
                    <Box mt={-2} style={{width: '100%'}} >
                        <AccordionSummary>
                            { expanded ? <ExpandMoreIcon color="primary" /> : <ChevronRight color="primary" /> } 
                            <Typography>
                                {title}
                            </Typography>
                        </AccordionSummary>
                    </Box>
                    <Box mx={2} mt={-1} >
                        {modPermissions?.create && 
                             <Button
                             variant="text"
                             color={theme === 'dark' ? 'inherit' : 'primary'}
                             onClick={() => handleDrawer()}
                         >
                             {`+ ${translate('translations.addAction')}`}
                         </Button>
                        }
                    </Box>
                </Box>
                
                <AccordionDetails>
                    <List
                        resource={resource}
                        filterDefaultValues={defaultSearchquery}
                        title={<></>}
                        perPage={pagesize}
                        actions={false}
                        pagination={false}
                        component="div"
                        >
                            <ListContent id={id} handleDocPreviewModalOpen={handleDocPreviewModalOpen} moduleRelationType={moduleRelationType} relatedfield={relatedModule?.relatedfield} fields={fields} describe={describe} linkfields={linkfields} resource={resource} relatedParentModule={relatedParentModule} setTitle={setTitle} />
                    </List>
                </AccordionDetails>
            </Accordion>
            {   open && 
			    <CbCreateRelatedRecordDrawer user={user} handleDocPreviewModalOpen={handleDocPreviewModalOpen} relatedFields={fields} linkfields={linkfields} relatedModDetails={relatedModule} open={open} toggleRelatedModuleDrawer={handleDrawer} describe={describe} relatedFieldValue={id} modPermissions={modPermissions} />
		    }
        </Box>
    )
}

const ListContent = (props:any) => {
    const translate = useTranslate();
    const { data, total = 0, isLoading } = useListContext<any>();
    
    props.setTitle(`${props.resource} (${total ?? 0})`);

    return (
        <Box>
            {isLoading && 
                <Loading loadingPrimary={""} loadingSecondary={""} />
            }
            {data?.map((record: any) => 
                <CbRecordDetail key={record.id} handleDocPreviewModalOpen={props.handleDocPreviewModalOpen} record={record} fields={props.fields} describe={props.describe} linkfields={props.linkfields} resource={props.resource} />
            )}
            {total > pagesize &&
                <Typography variant="inherit" component={Link} to={`/${props.resource}?relmod=${props.relatedParentModule}&relfield=${props.relatedfield}&relfieldval=${props.id}&reltype=${props.moduleRelationType}`} color="primary" sx={{textDecoration: 'none'}}>
                    {translate('translations.viewAll')}
                </Typography>
            }
        </Box>
    )


}