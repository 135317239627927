import * as React from 'react';
import CustomFieldLink from './CustomFieldLink';
import cbUtils from './corebosUtils/corebosUtils';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';



const CbRecordDetail = ({record, fields, describe, linkfields = [], resource, handleDocPreviewModalOpen } : {record: any, fields: any[], describe: any, linkfields: any[], resource: string, handleDocPreviewModalOpen: any}) => {

    return (
       <Card sx={{ marginBottom: '15px', border: '1px solid #eee' }}>
			<CardContent>
				{
					fields.map((field: any) => {
						let handleFieldClick: any = null;
						if(field.uitype === '28' || field.uitype === '69'){
							handleFieldClick = handleDocPreviewModalOpen;
						}
						return (
							<Box key={field.name} sx={{display: 'flex'}}>
								<Typography variant="subtitle2"> {field.label} </Typography>
								<span style={{margin: 'auto 5px auto 2px'}}>{':'}</span>
								{linkfields.includes(field.name) ? <CustomFieldLink resource={resource} record={record} linkField={field.name} key={field.name} /> : cbUtils.field2DisplayRecord(field, record, resource, describe, '', '', handleFieldClick)}
							</Box>
						 )
					})
				}
			</CardContent>
	   </Card>
    )
}

export default CbRecordDetail;