import Typography from "@mui/material/Typography";
import { useRedirect } from "react-admin";


const CustomFieldLink = (propsParam: {resource: any, record: any, linkField: string  }) => {

    const redirect = useRedirect();

    return (
        <Typography sx={{textDecoration: 'none !important', cursor: 'pointer'}} onClick={() => redirect(`/${propsParam.resource}/${propsParam.record?.id}/show`)} color="primary">
            {propsParam.record ? propsParam.record[`${propsParam.linkField}`] : ''}
        </Typography>
    )
}

export default CustomFieldLink;