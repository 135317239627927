import React from "react";
import { Filter, DateInput, TextInput, SelectInput, useInput, BooleanInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { OnlyMineInput } from "./OnlyMineInput";
import { dateParser } from "../utils/Helpers";

const FormattedBooleanInput = (props: any) => {
    const input: any = useInput(props);
    const isChecked = {checked: false};

    if (Number(input.input.value)) {
        isChecked.checked = true;
    }
    return (<BooleanInput {...props} options={isChecked}/>);
};

const formatSearchObject = (field: any, searchText: any) => {
    if (!searchText) {
        return;
    }
    let srch: any = {};
    srch[field] = searchText;
    return srch;
}

export const CBListFilter = (props: any) => {
    return (
        <Filter {...props} sx={{marginBottom: '20px'}}>
            {/* <SearchInput source={'cblistsearch_'+props.resource} alwaysOn /> */}
            {props.onlyMine && 
                <OnlyMineInput alwaysOn fieldName={props.onlyMine.fieldName} btnLabel={props.onlyMine.btnLabel} />
            }
            { 
                props.fields.map((field: any) => {
                    if(typeof field === 'object'){
                        switch (field.uitype) {
                            case '5': //date type
                                return (props?.disabledfield === field.name) ? <DateInput key={field.name} source={field.name} label={field.label} alwaysOn disabled parse={dateParser} sx={props.disabledfieldStyle ?? {}} /> : <DateInput key={field.name} source={field.name} label={field.label} parse={dateParser}/>;
                            case '50': //date type
                                return (props?.disabledfield === field.name) ? <DateInput key={field.name} source={field.name} label={field.label} alwaysOn disabled parse={dateParser} sx={props.disabledfieldStyle ?? {}} /> : <DateInput key={field.name} source={field.name} label={field.label} parse={dateParser}/>;
                            case '53': { // User Relation: Assigned To
                                const userlist = props.describe[props.resource]?.userlist ?? [];

                                return (
                                    <AutocompleteInput 
                                        key={field.name}
                                        label={field.label} 
                                        source={field.name} 
                                        choices={userlist} 
                                        optionText="username" 
                                        optionValue="username" 
                                    />
                                );
                            }
                            case '10': {//Module Relation
                                
                                if(props?.disabledfield === field.name && props.disabledfieldStyle){
                                    return null;
                                }

                                if (field.type.refersTo.length === 0) {
                                    return <></>
                                }
                                if (!props?.describe[field.type.refersTo[0]]) {
                                    return <></>
                                }
                                const refmod = props?.describe[field.type.refersTo[0]] ?? {};
                                let labelFields = refmod.labelFields;

            
                                if (labelFields && labelFields.indexOf(',') > -1) {
                                    labelFields = labelFields.split(',')[0];
                                }

                                
                                return (props?.disabledfield === field.name) ? 
                                    <ReferenceInput
                                        disabled
                                        alwaysOn
                                        sx={props.disabledfieldStyle ?? {}}
                                        variant="outlined"
                                        key={field.name}
                                        label={field.label}
                                        source={field.name}
                                        reference={refmod.name}
                                        filterToQuery={(searchText: string) =>
                                            formatSearchObject(labelFields, searchText)
                                        }>
                                        <AutocompleteInput
                                            variant="outlined"
                                            key={'ref' + field.name}
                                            optionText={labelFields}
                                            label={field.label}
                                        />
                                    </ReferenceInput> :

                                        <ReferenceInput
                                        variant="outlined"
                                        key={field.name}
                                        label={field.label}
                                        source={field.name}
                                        reference={refmod.name}
                                        filterToQuery={(searchText: string) =>
                                            formatSearchObject(labelFields, searchText)
                                        }>
                                        <AutocompleteInput
                                            variant="outlined"
                                            key={'ref' + field.name}
                                            optionText={labelFields}
                                            label={field.label}
                                        />
                                        </ReferenceInput> 
                            }
                            case '15': //Picklist
                                return (props?.disabledfield === field.name) ? <TextInput key={field.name} source={field.name} label={field.label} alwaysOn disabled sx={props.disabledfieldStyle ?? {}} /> : <SelectInput key={field.name} source={field.name} label={field.label} choices={field.type.picklistValues} optionText="value" optionValue="label" defaultValue={field.defaultValue}/>;
                            case '56': //Checkbox
                                return (props.disabledfield === field.name) ? <FormattedBooleanInput key={field.name} source={field.name} defaultValue={field.defaultValue} label={field.label} alwaysOn disabled sx={props.disabledfieldStyle ?? {}} /> : <FormattedBooleanInput key={field.name} source={field.name} label={field.label} />;
                            default:
                                if(props?.disabledfield === field.name){
                                    return <TextInput key={field.name} source={field.name} label={field.label} alwaysOn disabled sx={props.disabledfieldStyle ?? {}} />
                                }
                                
                                return (props?.disabledfield !== field.name && field?.defaultfilter) ? <TextInput key={field.name} source={field.name} label={field.label} alwaysOn /> : <TextInput key={field.name} source={field.name} label={field.label} />;
                        }
                    }else{
                        return (props?.disabledfield === field) ? <TextInput key={field.name} source={field} label={field} alwaysOn disabled sx={props.disabledfieldStyle ?? {}}  /> : <TextInput key={field.name} source={field} label={field} />;
                    }
                })
            }
        </Filter>
    );
}