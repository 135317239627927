import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import {
    List,
    MenuItem,
    ListItemIcon,
    Typography,
    Collapse,
    Tooltip,
    Box,
    useTheme,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';


interface Props {
    dense: boolean;
    handleToggle: () => void;
    icon: ReactElement;
    isOpen: boolean;
    name: string;
    children: ReactNode;
    sidebarIsOpen: boolean;
}

const SubMenu = (props: Props) => {
    
    const theme = useTheme();
    const { handleToggle, isOpen, name, icon, children, dense, sidebarIsOpen } = props;


    const header = (
        <MenuItem dense={dense} onClick={handleToggle} title={name}>
            <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                {isOpen ? <ExpandLess /> : icon }
            </ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {name}
            </Typography>
        </MenuItem>
    );

    return (
        <Box>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={name} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse 
                in={isOpen} 
                timeout="auto" 
                unmountOnExit
                sx={{
                    position: 'absolute',
                    background: '#f9f9f9',
                    minWidth: '160px',
                    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                    padding: '6px 4px',
                    zIndex: 1,
                }}
            >
                    <List
                        dense={dense}
                        component="div"
                        disablePadding
                        sx={{
                            '& a': {
                                transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                                paddingLeft: sidebarIsOpen ? theme.spacing(4) : theme.spacing(2),
                            },
                        }}
                    >
                        {children}
                    </List>
            </Collapse>
        </Box>
    );
};

export default SubMenu;