import React, { useState } from "react";
import {
  Loading,
  MenuItemLink,
  useTranslate,
} from "react-admin";
import SubMenu from "./SubMenu";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuIcon from '@mui/icons-material/Menu';
import { IconSettings, Icon } from '@salesforce/design-system-react';



const MenuItemIcon = ({ icon }: { icon: any }) => {
  if (!icon) return <MenuIcon />;
  return (
    <IconSettings iconPath="/icons" style={{ minWidth: '40px', }}>
      <div className={`${icon?.containerClass}`}>
        <Icon
          category={icon?.library}
          name={icon?.icon}
          className={`${icon.class}`}
          size="small"
          style={{ width: '1.2rem', height: '1.2rem' }}
        />
      </div>
    </IconSettings>
  )
}

const CBMenuItemLink = ({ menuItem, dense }: { menuItem: any, dense: boolean }) => {
  if (menuItem.type === 'url') {
    return (
      menuItem.menuItemVisibility && (<ul className="MuiList-root MuiList-padding" tabIndex={-1}>
        <li>
          <a tabIndex={0} role={'menuitem'} aria-disabled={false} href={menuItem.name} target="_blank" rel="noreferrer" className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root RaMenuItemLink RaMenuItemLink-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button">
            {menuItem.icon &&
              <MenuItemIcon icon={menuItem.icon} />
            }
            <span style={{ marginLeft: '4px', color: 'white' }}>{menuItem.label}</span>
          </a>
        </li>
      </ul>)
    )
  }

  return (
    menuItem.menuItemVisibility && (

      <MenuItemLink
        key={menuItem.name}
        to={{
          pathname: `/${menuItem.name}`,
        }}
        primaryText={menuItem.label}
        leftIcon={<MenuItemIcon icon={menuItem.icon} />}
        dense={dense}
        className="RaMenuItemLink"        
      />
    )
  )
}

const AppMenu = ({ menuitemslist, sidebarisopen, dense, isloadingmenu } : { menuitemslist: any[], sidebarisopen: boolean, dense: boolean, isloadingmenu: boolean }) => {

  const [open2ndLevel, setOpen2ndLevel] = useState(false);
  const [open, setOpen] = useState(true);
  const [anchorElIndex, setAnchorElIndex] = useState("");
  const [anchorParentElIndex, setAnchorParentElIndex] = useState("");
  const translate = useTranslate();


  const handleToggle = (parentMenuIndex: any, menuIndex: any) => {
    if (!parentMenuIndex) {
      setOpen(!open);
    } else {
      setOpen2ndLevel(!open2ndLevel);
    }
    setAnchorParentElIndex(parentMenuIndex);
    setAnchorElIndex(menuIndex);
  };


  return (
    <Box 
      sx={{
        '& .MuiMenuItem-root': {
          fontSize: '14px'
        }, 
        '& .MuiCircularProgress-root': {
          width: '50px !important', 
          height: '50px !important', 
          color: 'white !important'
        }, 
        '& .RaMenuItemLink': {
            color: '#fff !important',
        },
        '& .RaMenuItemLink-active': {
            borderLeft: '3px solid #fff',
            fontWeight: 'bolder',
        },
      }}
    >
      {isloadingmenu
        ? <Loading loadingPrimary="" loadingSecondary="" />
        : <>
          <MenuItemLink
            key="home"
            to={{
              pathname: '/',
            }}
            primaryText={translate('Dashboard')}
            leftIcon={<DashboardIcon sx={{ color: 'white' }} />}
            dense={dense}
            className="RaMenuItemLink"
          />
          <List sx={{paddingTop: 0, paddingBottom: 0, '& ul': { paddingTop: 0, paddingBottom: 0}}}>
            {menuitemslist?.map((menu: any, menuIndex: number) => {
              return (
                <Box key={'menuContainer'+menuIndex}>
                  {menu.menuItems.length <= 0 ? (
                    <CBMenuItemLink key={menu.name+'_'+menuIndex} menuItem={menu} dense={dense} />
                  ) : (
                    menu?.menuItemVisibility &&
                    (<SubMenu
                      key={menu.name + menuIndex + "submenu"}
                      handleToggle={() => handleToggle("", menu.menuId)}
                      isOpen={
                        open &&
                        (menu.menuId === anchorParentElIndex ||
                          menu.menuId === anchorElIndex)
                      }
                      name={menu.label}
                      icon={<MenuItemIcon icon={menu.icon} />}
                      dense={dense}
                      sidebarIsOpen={sidebarisopen}
                    >
                      {menu.menuItems.map((menuItem2: any, index2: number) => {
                        return (
                          <>
                            {menuItem2?.menuItems?.length <= 0 ? (
                              <CBMenuItemLink key={menuItem2.name +'_'+index2} menuItem={menuItem2} dense={dense} />
                            ) : (
                              <Box ml={2.2}>
                                <SubMenu
                                  key={menuItem2.name + index2 + "submenu"}
                                  handleToggle={() =>
                                    handleToggle(
                                      menuItem2.parentMenu,
                                      menuItem2.menuId
                                    )
                                  }
                                  isOpen={open2ndLevel && (menuItem2.menuId === anchorElIndex)}
                                  name={menuItem2.label}
                                  icon={<MenuItemIcon icon={menuItem2.icon} />}
                                  dense={dense}
                                  sidebarIsOpen={sidebarisopen}
                                >
                                  {menuItem2.menuItems.map(
                                    (menuItem3: any, index3: number) => {
                                      return (
                                        <CBMenuItemLink key={menuItem3.name +'_'+index3} menuItem={menuItem3} dense={dense} />
                                      );
                                    }
                                  )}
                                </SubMenu>
                              </Box>
                            )}
                          </>
                        );
                      })}
                    </SubMenu>)
                  )}
                </Box>
              )
            })}
          </List>
        </>
      }
    </Box>
  );
};

export default AppMenu;
