import React, { useContext, useEffect, useState } from 'react';
import {
    List,
    ListProps,
    ExportButton,
    TopToolbar,
    Loading,
    Button,
    useTranslate,
    useGetIdentity,
    useRedirect,
    useResourceContext,
    useResourceDefinition,
    Pagination,
} from 'react-admin';
import { CBListFilter } from '../components/CBListFilter';
import { TaskListContent } from './TaskListContent';
import { useLocation } from "react-router";
import queryString from 'query-string';
import { getDataFromLocalDb } from '../utils/Helpers';
import { TABLE_DESCRIBE } from '../local-db';
import { CbListGuesser } from '../components/corebosGuessers/cbListGuesser';
import AddIcon from '@mui/icons-material/Add';
import QuickCreateModal from '../components/QuickCreateModal';
import { authProvider } from '../authProvider';
import { ContentContext } from '../contentContext';
import { doRetrieve, getModDescribe } from '../utils/lib';
import { Box, useTheme } from '@mui/material';



const TaskListPagination = () => <Box sx={{my: 1}}> <Pagination rowsPerPageOptions={[100, 200, 300, 400]} /> </Box>;

export const TaskList = (props: ListProps) => {
    
    const resource: any = useResourceContext();
    const { options } = useResourceDefinition({resource: resource});
    const resourceOptions: any = options;
    const extraFields = resourceOptions?.extraFields;
    const contentheight = useContext(ContentContext);
    const [describe, setDescribe] = useState(null);
    const { identity } = useGetIdentity();
    const location = useLocation();
    const [project, setProject] = useState<any>(null);
    const [modPermission, setModPermission] = useState<any>({});
    const urlQueryString: any = queryString.parse(location?.search);
    let { projectid, view } = urlQueryString;


    if (!projectid && urlQueryString.filter) {
        const filter = JSON.parse(urlQueryString.filter);
        projectid = filter?.projectid ?? '';
    }

    let defaultSearchquery = urlQueryString?.filter ? JSON.parse(urlQueryString.filter) : {};
    for (const key in urlQueryString) {
        if (typeof key === 'string' && key !== 'filter' && key !== 'view') {
            defaultSearchquery[key] = urlQueryString[key];
        }
    }

    const [queryParams, setQueryParams] = useState<any>(defaultSearchquery);


    const [taskFilterFields, setTaskFilterFields] = useState([]);
    const [isLoadigProject, setIsLoadigProject] = useState(true);
    const translate = useTranslate();
    const [quickCreateOpen, setQuickCreateOpen] = useState<boolean>(false);
    const [modalData, setModalData] = useState<any>({});


    useEffect(() => {
        getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
            setDescribe(result);
            const newQueryParams = queryParams;
            if (extraFields) {
                let fields = result[resource]?.fields ?? [];
                fields = fields.map((field: any) => field.name);
                fields = fields && fields.length > 0 ? fields.join(',') + `, ${extraFields}` : '*';
                newQueryParams[`${'assigned_user_id : (Users) id'}`] = identity?.id;
                newQueryParams['extraFields'] = fields;
            }
            newQueryParams['cbfilter_projecttaskstatus'] = {
                fieldname: 'projecttaskstatus',
                groupid: 'ragroup',
                groupjoin: 'AND',
                joincondition: 'AND',
                operation: 'is not',
                value: 'Archived',
                valuetype: 'raw'
            }
            setQueryParams(newQueryParams);
        });
        authProvider.getModulePermission(resource).then((result: any) => {
            setModPermission(result);
        })

        if (projectid) {
            getModDescribe(resource).then((modDescribe: any) => {
                let filterFields = modDescribe?.filterFields?.fields ?? [];
                filterFields = filterFields.filter((field: string) => field !== 'projectid');
                filterFields.push('projecttaskstatus')
                for (let index = 0; index < filterFields.length; index++) {
                    const filterField = modDescribe?.fields.filter((field: any) => field.name === filterFields[index])[0];
                    if (filterField) {
                        filterFields[index] = JSON.parse(JSON.stringify(filterField));
                    }
                }
                setTaskFilterFields(filterFields);
            });

            setIsLoadigProject(true);
            doRetrieve(projectid).then((result: any) => {
                setProject(result);
                setIsLoadigProject(false);
            })
        } else {
            setIsLoadigProject(false);
        }

    }, [extraFields, identity, projectid, queryParams, resource]);


    const handleQuickCreateModal = (data: any, open: boolean) => {
        setModalData(data);
        setQuickCreateOpen(open);
    };

    if(!projectid || view !== 'kanban'){
        return <CbListGuesser {...props} />
    }


    return (
        <>
            <QuickCreateModal afterSave={null} resource='Timecontrol' relatedMod={resource} defaultValues={{ relatedto: modalData?.id }} title={modalData?.projecttaskname} open={quickCreateOpen} handleQuickCreateModal={handleQuickCreateModal} />
            {taskFilterFields.length > 0 ? (
                    <List
                        {...props}
                        perPage={100}
                        title={project?.projectname}
                        sort={{ field: 'id', order: 'ASC' }}
                        filters={<CBListFilter fields={taskFilterFields} disabledfield={'projectid'} describe={describe} onlyMine={{ fieldName: `${'assigned_user_id : (Users) id'}`, btnLabel: translate('Assigned to me') }} {...props} />}
                        filterDefaultValues={queryParams}
                        actions={<TaskActions filterFields={taskFilterFields} describe={describe} resource={resource} projectid={project?.id} />}
                        pagination={<TaskListPagination />}
                        component="div"
                        disableSyncWithLocation
                    >
                        <Box sx={{width: window.innerWidth - 300, overflow: 'auto',minHeight: contentheight-40,  height: 'auto', backgroundColor: '#eaeaee', mt: 2}}>
                              <TaskListContent isLoadigProject={isLoadigProject} modPermission={modPermission} handleQuickCreateModal={handleQuickCreateModal} resource={resource} redirectUrl={`/${resource}?filter=${JSON.stringify({ projectid: project?.id })}`} />
                        </Box>
                    </List>
                ) : (
                    <Loading />
                )
            }
        </>
    );
};


const TaskActions = (props: any) => {
    const theme = useTheme();
    const translate = useTranslate();
    const redirect = useRedirect();

    return (
        <TopToolbar>
            <CBListFilter context="button" fields={props.filterFields} disabledfield={'projectid'} describe={props.describe} />
            <ExportButton />
            <Button onClick={() => redirect(`/${props.resource}/create/?projectid=${props.projectid}`)} color='primary' variant='contained' label={translate('New Task')} sx={{marginLeft: theme.spacing(2)}}>
                <AddIcon />
            </Button>
        </TopToolbar>
    );
};

