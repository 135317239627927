import React, { useState } from 'react';
import { Box, Button, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import {Notification, useTranslate, useRedirect} from 'react-admin';
import { clearTable, TABLE_AUTH, TABLE_DESCRIBE, TABLE_LIST_VIEWS, TABLE_PERMISSIONS } from '../local-db';


const Health = () => {

    const translate = useTranslate();
    const redirect = useRedirect();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const clearData = async () => {
        setIsLoading(true);
        await clearTable(TABLE_AUTH.tableName);
        await clearTable(TABLE_PERMISSIONS.tableName);
        await clearTable(TABLE_DESCRIBE.tableName);
        await clearTable(TABLE_LIST_VIEWS.tableName);
        localStorage.clear();
        setIsLoading(false);
        redirect('/login');
    }


    return (
        <Card elevation={2}
            sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                    width: '50%',
                },
                padding: '20px',
                position: 'absolute',
                margin: 'auto',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '75%',
                backgroundColor: theme.palette.background.paper,
                minHeight: '-webkit-fill-available',
            })}
        >
            <CardContent sx={{position: 'absolute', top: '50%', left: '3%', right: '3%', transform: 'translateY(-50%)', margin: 'auto', border: '2px solid #ddd'}}>
                <Typography variant="subtitle1">{translate('ClearingStorageInfo')}</Typography>
                <Box my={2} sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                        fullWidth
                        startIcon={isLoading ? <CircularProgress color="inherit" size={24} /> : <SettingsIcon />}
                        variant="contained"
                        color="error"
                        size="large"
                        onClick={() => clearData()}
                    >
                        {translate('Clear Storage')}
                    </Button>
                    <Button
                        fullWidth
                        startIcon={<HomeIcon />}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => redirect('/')}
                        sx={{ml: 2}}
                    >
                        {translate('GoBack')}
                    </Button>
                </Box>
            </CardContent>
            <Notification />
        </Card>
    )
}

export default Health