import React, { useId, useState } from 'react';
import { DropzoneComponent } from 'react-dropzone-component';
import { Dropzone, prepareFileToUpload} from '../utils/Helpers';
import { doCreate } from '../utils/lib';
import { useNotify, useTranslate } from 'react-admin';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {useDropzone} from 'react-dropzone';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';
import "dropzone/dist/dropzone.css";
import "react-dropzone-component/styles/filepicker.css";
import { ACCEPTED_UPLOAD_FILES, ALLOWED_FILES_EXTENSIONS } from '../constant';


export const DocumentUpload = ({uploadResource, assigned_user_id, relationsIds, relProjectId, afterUpload = null, disableAutoUpload = true, dropzoneSelectorIdBtn=null, dropzoneSelectorId= '', afterSave=null} : {uploadResource?: string, assigned_user_id: any, relationsIds: any, relProjectId: string, afterUpload?: any, disableAutoUpload?: boolean, dropzoneSelectorIdBtn?: any, dropzoneSelectorId?: string, afterSave?: any}) => {

	const fileId = useId();
	const [uploading, setUploading] = useState<boolean>(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const [debounceKey, setDebounceKey] = useState<any>(0);
	const notify = useNotify();
	const translate = useTranslate();
	const allowedFileTypes: string[] = ALLOWED_FILES_EXTENSIONS;


	const {getRootProps, getInputProps, open} = useDropzone({
		noClick: true, 
		noKeyboard: true, 
		maxFiles:1,
		accept: ACCEPTED_UPLOAD_FILES,
		onDrop: (acceptedFiles) => {
			const file = acceptedFiles[0];
			const fileExtension: string = file.name.split('.').pop()??'';
			if(!allowedFileTypes.includes(fileExtension)){
				notify(translate('Unsupported file type...'), {type: 'error'});
			} else {
				setUploading(true);
				prepareFileToUpload(file, '', relationsIds, relProjectId, assigned_user_id).then((docData: any) => {
					doCreate(uploadResource ?? 'Documents', docData).then(() => {
						notify(translate('File uploaded successfully'), {type: 'success'});
						if(afterUpload){
							afterUpload();
						}
					}).catch(() => {
						notify(translate('Invalid file type or the file is too large'), {type: 'error'});
					}).finally(() => {
						setUploading(false);
					})
				})
			}
		}
	});

	const dropzoneComponentConfig : any = {
        autoDiscover: false,
        iconFiletypes: allowedFileTypes,
        //showFiletypeIcon: true,
        postUrl: 'no-url',
    }

	const djsConfig = { 
        autoProcessQueue: false,
        //addRemoveLinks: true,
     }

    const eventHandlers = {
        addedfile: async (file: any) => {
			setFileList(fileList => [...fileList, file]);
			return false;
        }, 
        init: function () {
          Dropzone.autoDiscover = false;
        }
    }

    const clearDropzone = () => {
        setFileList([]);
        setDebounceKey(new Date().getTime());
    }

	const removeFileFromList = (fileToRemove: any) => {
        setFileList(fileList.filter((file: any) => file.name !== fileToRemove.name))
    }

	const uploadFile = async({fileToUpload, relationsIds, relProjectId, title = '', assigned_user_id} : {fileToUpload:any, relationsIds:any, relProjectId:any, title:string, assigned_user_id: string}) => {
		const fileExtension = fileToUpload.name.split('.').pop()
		if (dropzoneComponentConfig.iconFiletypes.includes(fileExtension)) {
			setUploading(true)
			const docData = await prepareFileToUpload(fileToUpload, title, relationsIds, relProjectId, assigned_user_id);
			return doCreate(uploadResource ?? 'Documents', docData).then(() => {
				setUploading(false);
				notify(translate('File uploaded successfully'), {type: 'success'});
				Promise.resolve();
			}).catch((error: any) => {
				notify(error, {type: 'error'});
				Promise.resolve();
			}).finally(() => {
				setUploading(false);
			})
		} else {
			notify(translate('translations.unsupportedFileType'), {type: 'error'});
		}
	};

	const handleUpload = async() => {
		if(fileList.length > 0){
			for (const element of fileList) {
				const uploadFileData: any = {
					fileToUpload: element,
					relationsIds: relationsIds ?? '',
					relProjectId: relProjectId ?? '',
					title: '',
					assigned_user_id: assigned_user_id,
				}
				await uploadFile(uploadFileData);
			}
		}
		if(afterUpload){
			afterUpload();
		}
		clearDropzone();
	}

	if(!disableAutoUpload){
		return (
			<div {...getRootProps()}>
				<input {...getInputProps()} />
				<Button 
					onClick={open} 
					title={translate('Upload')} 
					color='inherit' 
					startIcon={ uploading ? <CircularProgress color='inherit' size={24} /> : <AddIcon /> }
					disabled={uploading}
				> 
					<></>
				</Button>
			</div>
		)
	}

	return (
		<Box my={4} px={3} >
            <Box mt={4}>
                <DropzoneComponent
                    key={debounceKey}
                    djsConfig={djsConfig}
                    config={dropzoneComponentConfig}
                    eventHandlers={eventHandlers} 
                />

                {uploading &&
                   <LinearProgress />
                }

                {fileList.length > 0 &&
                    <Box>
						{fileList.map((file: any, index: number) => (
							<Box display="flex" key={fileId+'_'+index}>
								{file.name}
								<Typography component="span" onClick={(e) => removeFileFromList(file)}><RemoveIcon /></Typography>
							</Box>
						))}
                    </Box>
                }
            </Box>
			<Box width={'40%'} mx={'auto'} my={'15px'}>
				<Button
					onClick={handleUpload}
					color="primary"
					variant="contained"
					startIcon={ uploading ? <CircularProgress color={uploading ? 'primary' : 'secondary' } size={24} /> : <></>}
					disabled={uploading || fileList.length <= 0}
					style={{textTransform: 'none', width: '100%'}}
				>
					{translate('translations.upload')}
				</Button>
			</Box>
		</Box>
	);

};
