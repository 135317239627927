import React, { useState, useEffect } from 'react';
import { usePermissions, useResourceContext, useTranslate } from 'react-admin';
import { getDataFromLocalDb } from '../../utils/Helpers';
import { TABLE_DESCRIBE } from '../../local-db';
import queryString from 'query-string';
import { ListView } from '../ListView';
import Box from '@mui/material/Box';


export const CbListGuesser = (props: any) => {
	const resource: any = useResourceContext();
	const allPermissions: any = usePermissions();
	const modPermission = allPermissions ? allPermissions?.permissions?.filter((permission: any) => permission.module === resource)[0]?.permissions : null;
	const [describe, setDescribe] = useState<any>({});
	const [fields, setFields] = useState<any[]>([]);
	const [label, setLabel] = useState<string>('');
	const [labelField, 	setLabelField] = useState<string>('');
	const [linkFields, setLinkFields] = useState<any[]>([]);
	const [filterFields, setFilterFields] = useState<any[]>([]);
	const translate = useTranslate();
	let defaultSearchquery: any = {};
	const { relmod, relfield, relfieldval, reltype, project_task } = queryString.parse(props?.location?.search);

	if(project_task){
		defaultSearchquery['project_task'] = project_task;
	}
	if(relmod && relfieldval && reltype){
		defaultSearchquery[`${relfield}`] = relfieldval;
		defaultSearchquery['relatedModule'] = relmod;
		defaultSearchquery['moduleRelationType'] = reltype;
		defaultSearchquery['joinCondition'] = 'AND';
	}
	

	
	useEffect(() => {
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
			return result;
		}).then(describeData => {
			setDescribe(describeData);
			setLabel(describeData[resource]?.label);
			setLinkFields(describeData[resource]?.filterFields?.linkfields ?? []);
			let labelfields = describeData[resource].labelFields.split(',');
			setLabelField(labelfields[0]);
			let ffields = describeData[resource]?.filterFields?.fields ?? [];
			let relatedfields =describeData[resource]?.filterFields?.relatedfields ? Object.values(describeData[resource]?.filterFields?.relatedfields) : [];
			let objFilterFields = [];
			let modFields = [];

			for (const value of ffields) {
				const field = describeData[resource]?.fields.find((element: any) => {
					return element.name === value;
				});
				if(field){
					modFields.push(field);
					objFilterFields.push(field);
				}
			}
			if(relatedfields?.length > 0){
               modFields = [...modFields, ...relatedfields];
			   objFilterFields = [...objFilterFields, ...relatedfields]
			}

			setFilterFields(objFilterFields);
			setFields(modFields);
		}).catch(() => {
			console.log(translate('translations.somethingWentWrong'));
		})
	}, [relfield, relfieldval, relmod, reltype, resource, translate])



	return (
		<>
		{!modPermission 
			? 	<Box justifyContent="center" alignContent="center" alignItems="center"> 
					<h2>ACCESS DENIED</h2>
				</Box>
			: 	<ListView 
					resource={resource} 
					basePath={props?.basePath} 
					fields={fields} 
					filterFields={filterFields} 
					linkFields={ linkFields }
					labelField={ labelField }
					describe={describe}
					title={label}
					filterDefaultValues={ defaultSearchquery }
					relmod={relmod}
					relfield={ relfield }
					relfieldval={relfieldval}
					otherProps={props}
				/>
		}
		</>
	)
};
