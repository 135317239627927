import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { getDataFromLocalDb, dateTimeParser, handleSpecialChars, uploadFile } from '../utils/Helpers';
import { remoteValidate, doInvoke } from '../utils/lib';
import { TABLE_DESCRIBE } from '../local-db';
import cbUtils from './corebosUtils/corebosUtils';
import { Create, SimpleForm, Toolbar, SaveButton, useCreate, useUpdate, useNotify, useTranslate, useGetIdentity, useRedirect, TabbedForm, TabbedFormTabs, FormTab } from 'react-admin';
import { authProvider } from '../authProvider';
import { SaveImageHandler } from 'react-mde';
import { MarkDownInput } from './MarkDown';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';



const CustomToolbar = (props: any) => {

	const translate = useTranslate();
	const { modPermission, handleClose, isLoading} = props;

	return (
		<Toolbar {...props}>
			{modPermission && (modPermission.create || modPermission.update) && 
				<SaveButton
					label="Save"
					icon = { isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary' } size={24} /> : <></>}
					disabled={isLoading}
					size="medium"
					{...props}
				/>
			}
			<Box mx={1}>
				<Button onClick={handleClose} color="primary" variant='outlined'>
					{translate('Cancel')}
				</Button>
			</Box>
		</Toolbar>
	);
}

const QuickCreateModal = ({defaultValues, record, isNewRecord=true, quickCreate=true, action='', resource, relatedMod, title, open, handleQuickCreateModal , afterSave = null, fullWidth=false, fullScreen= false, maxWidth="md" } : { defaultValues: any, record?: any, isNewRecord?: boolean, quickCreate?: boolean, action?: string, resource: string, relatedMod: string, title: any, open: boolean, handleQuickCreateModal: any , afterSave?: any, fullWidth?: boolean, fullScreen?: boolean, maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false}) => {

	const [ describe, setDescribe ] = useState<any>({});
	const [ fields, setFields ] = useState<any[]>([]);
	const [ modPermission,  setModPermission ] = useState<any>({});
	const [ create ] = useCreate();
	const notify = useNotify();
	const [isLoading, setIsLoading] = useState(false);
	const translate = useTranslate();
	const { identity } = useGetIdentity();
	const redirect = useRedirect();
	const [ update ] = useUpdate();
	const excludeFields: string[] = ['id', 'projecttasknumber', 'projecttask_no'];
	const [markdownInputValue, setMarkdownInputValue] = useState({});
	const [uploadedFileList, setUploadedFileList] = useState<any[]>([]);
	const [blocks, setBlocks] = useState<any[]>([]);


	if(!defaultValues){
		defaultValues = {};
	}

	if(resource === 'cbCalendar'){
		defaultValues['dtstart'] = new Date();
		const dtend = new Date();
		dtend.setMinutes(dtend.getMinutes() + 10);
		defaultValues['dtend'] = dtend;
	}


	useEffect(() => {
		const getModViewFields = (allFields: any[]) => {
			if(resource === 'ProjectTask'){
				if(action === 'review'){
					return allFields.filter((field: any) => (field.name === 'review' || field.name === 'evalcomment') );
				} 
				if(action === 'assign'){
					return allFields.filter((field: any) => field.name === 'assigned_user_id');
				} 
			} else if(resource === 'cbStakeHolder') {
				return allFields.filter((field: any) => (field.name === 'stakeholder' || field.name === 'relto') );
			} else if(resource === 'cbCalendar'){
				return allFields.filter((field: any) => (field.quickcreate === true && field.mandatory === true && field.name !== 'assigned_user_id') || field.name === 'project_task');
			} else {
				return allFields.filter((field: any) => field.quickcreate === true);
			}
		}
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
			setDescribe(result);
			let modFields = result[resource]?.fields ?? [];
			if(!quickCreate){
				let bfields :any[] = [];
				let mfields :any = modFields;
				for (const value of mfields) {
					if (!value.block) {
						continue;
					}
					let bidx = bfields.findIndex((element :any) => element.id === value.block.blockid);
					if (bidx===-1) {
						bfields.push({
							id: value.block.blockid,
							sequence: value.block.blocksequence,
							label: value.block.blocklabel,
							name: value.block.blockname,
							fields: []
						});
						bidx = bfields.findIndex((element :any) => element.id === value.block.blockid);
					}
					bfields[bidx].fields.push(value);
				}
				setBlocks(bfields);
			} else {
				modFields = getModViewFields(modFields);
			}
			setFields(modFields);
		});
		authProvider.getModulePermission(resource).then((result: any) => {
			setModPermission(result);
		})
	}, [quickCreate, resource, action])

	const handleClose = () => {
		handleQuickCreateModal(false, '');
	};

	const updateRecord = useCallback(
		async (values: any, currentMarkdownInputValue: any, fileList: any[]) => {
			const newValues: any = Object.assign(values, currentMarkdownInputValue);
			for (const key in newValues) {
				const value = newValues[key];
				if (value?.rawFile) {
					const result: any = await handleUpload(value.rawFile, '');
					newValues[key] = result?._downloadurl ?? '';
				} else {
					newValues[key] = handleSpecialChars(newValues[key]);
				}
			}
			const validationRes: any = await remoteValidate(record.id, resource, newValues);
			if (!validationRes.isValid){
				setIsLoading(false);
				notify(validationRes?.errors[0] ?? '', {type: 'error'});
				return;
			}
			update(resource, { id: record.id, data: newValues, previousData: record }, 
				{
					onSuccess: (result: any) => {
						const fileIds: any[] = [];
						fileList.forEach((uploadedDocument: any) => {
							fileIds.push(uploadedDocument.id);
						}); 
						const message = resource === 'ProjectTask' ?  translate('Record updated successfully') : translate('Record created successfully');
						if(fileIds.length > 0){
							doInvoke('SetRelation', { relate_this_id: result?.data.id, with_these_ids: JSON.stringify(fileIds)}, 'POST').then(() => {
								setIsLoading(false);
								handleQuickCreateModal(null, false);
								notify(message, {type: 'success'});
								if(afterSave){
									afterSave(resource);
								}
							}).catch(() => {
								notify(`Upload: ${translate('Something went wrong')}`, {type: 'error'});
							}).finally(() => {
								setIsLoading(false);
							});
						} else {
							if(afterSave){
								afterSave(resource);
							}
							setIsLoading(false);
							handleQuickCreateModal(null, false);
							notify(message, {type: 'success'});
					}
					},
					onError: (error: any) => {
						setIsLoading(false);
						notify(error?.message, {type: 'error'});
					}
				}
			)
		},
		[afterSave, handleQuickCreateModal, notify, record, resource, translate, update],
	);

	const createRecord = useCallback(
		async (values: any, currentMarkdownInputValue: any, exclFields: any[], fileList: any[]) => {
			let dataToSend: any = {};
			const newValues: any = Object.assign(values, currentMarkdownInputValue);
			for (const key in newValues) {
				const value = newValues[key];
				if (value?.rawFile) {
					const result = await handleUpload(value.rawFile, '');
					newValues[key] = result?._downloadurl ?? '';
				} else {
					newValues[key] = handleSpecialChars(newValues[key]);
				}

				if(!exclFields.includes(key)){
					dataToSend[key] = newValues[key];
				}
			}
			const validationRes: any = await remoteValidate('', resource, dataToSend);
			if (!validationRes.isValid){
				setIsLoading(false);
				notify(validationRes?.errors[0] ?? '', {type: 'error'});
				return;
			}
			create(resource, {data: dataToSend}, {
				onSuccess: (result: any) => {
					const relatedIds: any[] = [];
					fileList.forEach((uploadedDocument: any) => {
						relatedIds.push(uploadedDocument.id);
					}); 
					const message = resource === 'Timecontrol' ?  translate('TimeControl created successfully') : translate('Record created successfully');
					if(relatedIds.length > 0){
						doInvoke('SetRelation', { relate_this_id: result?.id, with_these_ids: JSON.stringify(relatedIds)}, 'POST').then(() => {
							setIsLoading(false);
							handleQuickCreateModal(null, false);
							notify(message, {type: 'success'});
						}).catch(() => {
							notify(`Upload: ${translate('Something went wrong')}`, {type: 'error'});
						}).finally(() => {
							setIsLoading(false);
						});
					} else {
						setIsLoading(false);
						handleQuickCreateModal(null, false);
						notify(message, {type: 'success'});
						if(afterSave){
							afterSave(resource);
						}
					}
				},
				onError: () => {
					setIsLoading(false);
					notify(translate('Something went wrong'), {type: 'error'});
				},
			});
		},
		[afterSave, create, handleQuickCreateModal, notify, resource, translate],
	);

	const saveRecord = useCallback(
		async (values: any, exclFields: string[], fileList: any[], currentMarkdownInputValue: any) => {
			values['assigned_user_id'] = identity?.id ?? '';
			if(resource === 'cbCalendar'){
				values['dtstart'] = dateTimeParser(new Date(values.dtstart).toISOString());
				values['dtend'] = dateTimeParser(new Date(values.dtend).toISOString());
			}
			setIsLoading(true);
			if(!isNewRecord){
				updateRecord(values, currentMarkdownInputValue, fileList);
			} else {
				createRecord(values, currentMarkdownInputValue, exclFields, fileList);		 
	 		}
	},
		[resource, isNewRecord, identity, updateRecord, createRecord],
	);

	const handleMarkdownField = (fieldName :string, value :string, cureentMarkdownInputValue :any) => {
		cureentMarkdownInputValue[fieldName] = value;
		setMarkdownInputValue(cureentMarkdownInputValue);
	}

	const handleUpload = async (file: any, title: string) => {
		setIsLoading(true);
		const result  = await uploadFile(file, title, '', '', '');
        if(result instanceof Error) {
			setIsLoading(false);
			return null;
        }
		setUploadedFileList(uploadedFileList => [...uploadedFileList, result]);
		setIsLoading(false);
		return result;
	}
	
	const saveThisImage: SaveImageHandler = async function*(data: ArrayBuffer, file: Blob) {
        const title = '';
        const result  = await handleUpload(file, title);
		if(result instanceof Error) {
            yield '';
            return true;
        }
        yield result?._downloadurl; 
        return !!result?._downloadurl;
    };

    return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll={'paper'}
				aria-labelledby="quick-create"
				aria-describedby="quick-create-modal"
				maxWidth={maxWidth}
				fullWidth={fullWidth}
				fullScreen={fullScreen}
			>
				<DialogTitle id="quick-create">{title}</DialogTitle>
				<DialogContent dividers >
						<Create
                            resource={resource}
                            title={<></>}
                            >
                            { quickCreate
							?	<SimpleForm onSubmit={(values: any) => saveRecord(values, excludeFields, uploadedFileList, markdownInputValue)} defaultValues={defaultValues} toolbar={<CustomToolbar isLoading={isLoading} modPermission={modPermission} handleClose={handleClose} />} >
									<Box py={3} sx={{width: '100%'}}>
										<>
											{
												fields.map((field: any) => {
													if(resource === 'Timecontrol' && field.name === 'relatedto'){
														return <></>
													}
													let relMod = relatedMod;
													if(field.uitype === '19'){
														return (
															<Box key={field.name} my={1}>
																<Typography variant="subtitle2"> {field.label} </Typography>
																<MarkDownInput
																	record={defaultValues}
																	source={field.name}
																	handleMarkdownField={handleMarkdownField}
																	saveThisImage={saveThisImage}
																	cureentMarkdownInputValue={markdownInputValue}
																/>
															</Box>
														)
													} 
													
													if(field.name === 'stakeholder' && resource === 'cbStakeHolder'){
														relMod = 'Contacts';
													}
													
													return cbUtils.field2InputElement(field, resource, {fullWidth: true}, describe, '', relMod, {}, {}, {});
												})
											}
										</>
									</Box>
								</SimpleForm>
							:	<TabbedForm onSubmit={(values: any) => saveRecord(values, excludeFields, uploadedFileList, markdownInputValue)} defaultValues={defaultValues}  toolbar={<CustomToolbar isLoading={isLoading} modPermission={modPermission} handleClose={handleClose} />} syncWithLocation={false} tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />} >
									{
										blocks.map((block, bidx) => {
											return (
												<FormTab key={"fbrefblk" + bidx} label={block.name}>
													<Box sx={{width: '100%'}} py={3}>
														<Grid container spacing={2}>
															{block.fields.map((field: any) => {
																let relMod = relatedMod;
																if (field.uitype === "19") {
																	return (
																		<Grid key={field.name} item xs={12} md={12}>
																		<Box my={1}>
																			<Typography variant="subtitle2"> {" "} {field.label}{" "} </Typography>
																			<MarkDownInput
																				record={defaultValues}
																				source={field.name}
																				handleMarkdownField={handleMarkdownField}
																				saveThisImage={saveThisImage}
																				cureentMarkdownInputValue={markdownInputValue}
																			/>
																		</Box>
																		</Grid>
																	);
																}
																if(field.name === 'stakeholder' && resource === 'cbStakeHolder'){
																	relMod = 'Contacts';
																}

																const cbField = cbUtils.field2InputElement(field, resource, { fullWidth: true }, describe, '', relMod, {}, {}, {});
																if(cbField){
																	return (
																		<Grid key={field.name} item xs={12} md={6}>
																			{ cbField }
																		</Grid>
																	)
																}
																
																return <></>;
															})}
														</Grid>
													</Box>
												</FormTab>
											);
										})
									}
								</TabbedForm>
						}
                        </Create>
				</DialogContent>
				{resource !== 'Timecontrol' &&
					<DialogActions>
						<Box mx={2}>
							{ !isNewRecord &&
								<Button onClick={() => redirect(`/${resource}/${record?.id}`)} color="primary" >
									{ translate('ra.action.edit') }
								</Button>
							}
							{ isNewRecord &&
								<Button onClick={() => redirect(`/${resource}/create`)} color="primary" >
									{ quickCreate ? translate('Go to full form') : translate('Create New') }
								</Button>
							}
						</Box>
					</DialogActions>
				}
			</Dialog>
		</div>
    )
}

export default QuickCreateModal;