import React, { useState } from 'react';
import { CircularProgress, Typography, Grid, Box, Button, Card, CardContent, TextField } from '@mui/material';
import {useLogin, useNotify, useRedirect, Notification} from 'react-admin';
import { COMPANY_LOGO_URL } from '../constant';


const Login = (props: any) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);

    const redirect = useRedirect();
    const login = useLogin();
    const notify = useNotify();

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        login({ username, password }).then((res) => {
            redirect('/');
        }).catch(() => {
            notify('Invalid username or password', {type: 'error'});
            setIsLoading(false);
        });
    };


    return (
        <Box 
            sx={(theme) => ({
                backgroundColor: theme.palette.background.paper,
                '& .MuiFormLabel-root': {
                    opacity: '1 !important',
                    color: '#BDBDBD',
                },
                '& .MuiTextField-root': {
                    margin: '10px auto',
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: '#DCDCDC',
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: '#DCDCDC',
                    },
                    '&:hover fieldset': {
                    borderColor: '#DCDCDC',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: '#DCDCDC',
                    },
                },
                '& .MuiTypography-body1': {
                    fontSize: '12px'
                },
                '& .MuiBox-root': {
                    width: '100%',
                }
            })}
        >
            <Card elevation={0} sx={{height: '100vh', overflowY: 'auto'}}>
                <CardContent>
                    <Grid container spacing={0} >
                        <Grid item xs={12} md={5} >
                            <Box ml={-5} my={3}> <img src={'appLoginBg.png'} alt={'Company Logo'} /> </Box>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box
                                sx={(theme) => ({
                                    borderRadius: '5px',
                                    border: '1px solid #ddd',
                                    padding: '20px 30px !important',
                                    [theme.breakpoints.up('md')]: {
                                        width: '50% !important',
                                    },
                                    width: '80% !important',
                                    margin: '100px auto !important',
                                })}
                            >
                                { COMPANY_LOGO_URL && 
                                    <Box style={{width: '75%', margin: 'auto'}}>
                                        <img src={COMPANY_LOGO_URL} alt={'Company Logo'} style={{width: '100%'}} />
                                    </Box>
                                }
                                <Typography component="div">
                                    <Box textAlign="center" my={2} sx={{fontSize: '20px'}}>
                                        Login
                                    </Box>
                                </Typography>
                                <form onSubmit={onSubmit}>
                                    <Box>
                                        <TextField
                                        label="Username"
                                        //placeholder="Email"
                                        name={'username'}
                                        fullWidth
                                        id="usernameInput"
                                        variant="outlined"
                                        size="small"
                                        required
                                        onChange={e => setUsername(e.target.value)}
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                        label="Password"
                                        //placeholder="Password"
                                        name={'password'}
                                        type={'password'}
                                        fullWidth
                                        id="passwordInput"
                                        variant="outlined"
                                        size="small"
                                        required
                                        onChange={(e: any) => setPassword(e.target.value)}
                                        />
                                    </Box>
                                    <Box my={2}>
                                        <Button 
                                            fullWidth 
                                            type={'submit'} 
                                            disabled={isLoading} 
                                            startIcon = { isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary' } size={24} /> : <></>}
                                            variant="contained" 
                                            color="primary" 
                                            size="large" 
                                            sx={{boxShadow: 'none', padding: '7px 22px'}}
                                        >
                                            Login
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        </Grid>
                    </Grid>
                    <Notification />
                </CardContent>
            </Card>
        </Box>
    );
};

export default Login;