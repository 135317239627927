import React from 'react';
import CustomFieldLink from './CustomFieldLink';
import { useEditContext } from 'react-admin';


export const EditTitle = ({ resource, label, showTitle = true, value = null, linkField } : { resource: string, label?: any, showTitle: boolean, value?: any, linkField: string }) => {

	const { record } = useEditContext();
	const cbRecord: any = value ?? record;

	
	if(!showTitle) return <></>;
	if(label) return label;
	if(!linkField) return <></>;
	return (
		<>{' '}<CustomFieldLink resource={resource} record={cbRecord}  linkField={linkField} /></>
	)
   
};

const Title = ({ resource, label, showTitle = true, value = null, linkField } : { resource: string, label?: any, showTitle: boolean, value?: any, linkField: string }) => {

	const cbRecord: any = value;

	
	if(!showTitle) return <></>;
	if(label) return label;
	if(!linkField) return <></>;
	return (
		<>{' '}<CustomFieldLink resource={resource} record={cbRecord}  linkField={linkField} /></>
	)
   
};

export default Title;