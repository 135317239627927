import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';


const DocumentPreview = ({ type = '', fileUrl, title } : { type: string, fileUrl: any, title: string }) => {

    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    if(type.split('/')[0] === 'image'
       || type === 'png'
       || type === 'jpg'
       || type === 'jpeg'
       || type === 'svg'){
        return (
            <Box style={{width: isMobile ? '100%' : '50%'}} mx={'auto'}>
                <img src={fileUrl} alt={title} style={{width: '100%', height: '100%', margin: 'auto'}} />
            </Box>
        )
    }


    return <iframe src={fileUrl} style={{width: '100%', height: '100%'}} title='Document Preview' />
}

export default DocumentPreview;