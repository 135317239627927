import React from "react";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";



const Pagination = ({ page, perPage, offset, setOffset, setPage, isLoading, total } : { page: number, perPage: number, offset: number, total: number, isLoading: boolean, setOffset: any, setPage: any }) => {

    const paginate = (pageNumber: number, direction: number) => {
        let newOffset = direction >= 0 ? (pageNumber * perPage) : offset - perPage;
        newOffset = newOffset >= 0 ? newOffset : 0;
        setOffset(newOffset);
        setPage(pageNumber + direction);
    };

    return (
        <Box py={1} display="flex">
            <IconButton color="primary"   onClick={() => paginate(page, -1)} disabled={ page <= 1 || isLoading } aria-label="Next">
                <NavigateBeforeIcon />
            </IconButton>
            <Box mt={1.6} fontWeight="bold"> { isLoading ? <CircularProgress color="primary" size={24} /> : <Typography> {page} </Typography> } </Box>
            <IconButton color="primary" onClick={() => paginate(page, 1)}  disabled={total <= page * perPage || isLoading } aria-label="Next">
                <NavigateNextIcon />
            </IconButton>
        </Box>
    )
}

export default Pagination;