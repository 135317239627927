import { USER_AVATAR_MALE, USER_AVATAR_FEMALE } from './constant';
import {
    saveDataToLocalDB,
    clearTable,
    TABLE_PERMISSIONS,
    TABLE_DESCRIBE,
    TABLE_LIST_VIEWS,
    TABLE_AUTH,
} from './local-db';
import { getDataFromLocalDb } from './utils/Helpers';
import { doInvoke, doLogin, doLoginPortal, doQuery, getLastError, hasError, setSession } from './utils/lib';

const window_: any = window;
if (window_.coreBOS === undefined) {
    window_.coreBOS = {};
}
window_.coreBOS.LoginEvent = new CustomEvent('coreBOSLoginEvent', {});


const handleAuthentication = async (logindata: any) => {
    if (logindata !== false && hasError(logindata)) {
        let coreboslogindata = logindata['result']
        const userId = coreboslogindata?.userId || coreboslogindata?.user?.id;
        coreboslogindata = {...coreboslogindata, userId: userId };
        setSession({ sessionName: coreboslogindata?.sessionName, userId: coreboslogindata?.userId });
        const res = await doInvoke('getPortalUserInfo');
        const userLanguage = res?.language.split('_')[0] ?? 'en'; // Set default locale to english
        localStorage.setItem('locale', userLanguage);
        const contactdata = await doQuery(`SELECT * FROM Contacts WHERE assigned_user_id=${res?.id} LIMIT 1`)
        const contact = Array.isArray(contactdata) && contactdata[0] ? contactdata[0] : {};
        const contactid: string = contact?.id ?? coreboslogindata?.user?.contactid;
        const authData = { user: { ...res, gender: contact?.gender }, contactid: contactid, contact: contact, logindata: coreboslogindata, language: res?.language }; // update this
        await saveDataToLocalDB(TABLE_AUTH.tableName, authData, false);
        await clearTable(TABLE_PERMISSIONS.tableName);
        await clearTable(TABLE_DESCRIBE.tableName);
        return Promise.resolve(authData);
    } else {
        return Promise.reject(new Error(getLastError()));
    }
}

export const authProvider = {

    login: async ({ username, password }: { username: string, password: string }) => {
        await clearTable(TABLE_AUTH.tableName);
        localStorage.removeItem('currenttimestate');
        localStorage.removeItem('Timerstate');
        localStorage.removeItem('Record');

        let logindata: any = null;
        logindata = await doLogin(username, password, false).then((logindata: any) => {
            return handleAuthentication(logindata);
        }).catch(() => {
            return null;
        });
        if(!logindata){
            logindata = await doLoginPortal(username, password, 'plaintext', 'Contacts').then((logindata: any) => {
                return handleAuthentication(logindata);
            }).catch(() => {
                return null;
            })
        }

        if(!logindata){
            return Promise.reject(new Error(getLastError()));
        }
        return Promise.resolve(logindata); 
    },
    // called when the user clicks on the logout button
    logout: async () => {
        await clearTable(TABLE_AUTH.tableName);
        await clearTable(TABLE_PERMISSIONS.tableName);
        await clearTable(TABLE_DESCRIBE.tableName);
        await clearTable(TABLE_LIST_VIEWS.tableName);
        localStorage.clear();
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: () => Promise.resolve(),
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
        const authData = await getDataFromLocalDb(TABLE_AUTH.tableName);
        const coreboslogindata = authData?.logindata ?? null;
        return coreboslogindata ? Promise.resolve() : Promise.reject(new Error('Authentication failed!'));
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: async () => {
        const permissions = await getDataFromLocalDb(TABLE_PERMISSIONS.tableName);
        return permissions;
    },
    getIdentity: async () => {
        let corebosUser = await getDataFromLocalDb(TABLE_AUTH.tableName);
        corebosUser = corebosUser?.user ? corebosUser : null;

        if (corebosUser) {
            return Promise.resolve({
                id: corebosUser?.user?.id,
                contactid: corebosUser?.contactid || corebosUser?.user?.id,
                fullName: corebosUser?.contact?.id ? corebosUser?.contact?.firstname +' '+ corebosUser?.contact?.lastname : corebosUser?.user?.first_name +' '+ corebosUser?.user?.last_name,
                avatar: corebosUser.user?.gender === 'Male' ? USER_AVATAR_MALE : USER_AVATAR_FEMALE,
                user: corebosUser?.user,
            });
        }

        return Promise.reject(new Error('Unable to get user data!'));
    },

    //Custom methods
    getModulePermission: async (moduleName: string, permissions: any[] = []) => {
        let modulePermission = null;
        if (permissions.length === 0) {
            const allPermissions = await getDataFromLocalDb(TABLE_PERMISSIONS.tableName);
            modulePermission = allPermissions.filter((permission: any) => permission.module === moduleName);
        } else {
            modulePermission = permissions.filter(permission => permission.module === moduleName);
        }

        const modPermission = modulePermission?.[0]?.permissions || null;
        return modPermission;
    },
};
