import React from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import Login from './auth/Login';
import { authProvider } from './authProvider';
import CustomLayout from './layout/Layout';
import i18nProvider from './components/i18n/i18nProvider';
import { CbListGuesser } from './components/corebosGuessers/cbListGuesser';
import { CbEditTabGuesser } from './components/corebosGuessers/cbEditTabGuesser';
import { CbShowTabGuesser } from './components/corebosGuessers/cbShowTabGuesser';
import {TaskList} from './tasks/TaskList';
import { TaskShow } from './tasks/TaskShow';
import { COREBOS_URL, APP_NAME, APP_META_TAGS } from './constant';
import { Seo } from './components/Seo';
import { getDataFromLocalDb } from './utils/Helpers';
import { TABLE_AUTH } from './local-db';
import { themeReducer, menuPositionReducer, appMenuReducer, taskReducer, projecttaskReducer, datestartReducer, timecontrolReducer, stopwatchReducer } from './app-reducers/appReducer';
import { lightTheme } from './layout/themes';
import { Provider } from 'react-redux';
import {combineReducers} from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import CbCreateTabGuesser from './components/corebosGuessers/cbCreateTabGuesser';
import Dashboard from './dashboard/Index';
import { getListTypes, RaCbDataProvider, setSession, setURL } from './utils/lib';
import { ThemeProvider } from '@mui/material';
import { Route } from "react-router-dom";
import Health from './components/Health';



const _window: any = window;
if (_window.coreBOS === undefined) {
	_window.coreBOS = {};
}
window.addEventListener('coreBOSSessionExpiredEvent', function (e) {
	authProvider.logout().then(() => {
		window.location.reload();
	});
}, false);
window.addEventListener('coreBOSAuthorizationRequiredEvent', function (e) {
	authProvider.logout().then(() => {
		window.location.reload();
	});
}, false);
setURL(COREBOS_URL);

const AppWrapper = () => {
	const rootReducer = combineReducers({
		theme: themeReducer,
		menuPosition: menuPositionReducer,
		appMenu: appMenuReducer,
		timer: taskReducer,
		record: projecttaskReducer,
		datestart: datestartReducer,
		tc: timecontrolReducer,
		stopwatch: stopwatchReducer,
	});

	const store = configureStore({
		reducer: rootReducer
	})

	return (
	  <Provider store={store}>
		<App />
	  </Provider>
	)
}

const App = () => {

	const fetchResources = async (permissions: any[]) => {

		const authUser: any = await getDataFromLocalDb(TABLE_AUTH.tableName);
		const loggedInData: any = authUser?.logindata ?? null;
		if (loggedInData?.userId) {
			setSession({ sessionName: loggedInData.sessionName, userId: loggedInData.id });
			const res: any = await getListTypes();
			const resourceList: any[] = [];
			for (const key in res) {
				if (Object.hasOwn(res, key)) {
					resourceList.push(res[key]);
				}
			}
			const resources: any[] = resourceList.map((item: any) => {
				return <Resource
					key={item.name}
					name={item.name}
					list={ item.name === 'ProjectTask' ? TaskList : CbListGuesser}
					show={ item.name === 'ProjectTask' ? TaskShow : CbShowTabGuesser}
					create={ item.name === 'Timecontrol' ? <></> : CbCreateTabGuesser}
					edit={CbEditTabGuesser}
					options={{ label: item.name, extraFields: 'Users.ename', show: true, permission: permissions?.filter(permission => permission.module === item.name) }} />;
			});
			return resources;
		} else if(window.location.hash === '#/clear-storage') {
			return [];
		} else {
			window.location.href = '#/login';
			return [];
		}
	}


	return (
		<ThemeProvider theme={lightTheme}>
			<Seo title={APP_NAME} description='' meta={APP_META_TAGS} />
			<Admin
				title={APP_NAME}
				loginPage={Login}
				dataProvider={RaCbDataProvider}
				authProvider={authProvider}
				i18nProvider={i18nProvider}
				layout={CustomLayout}
				dashboard={Dashboard}
				theme={lightTheme}
				disableTelemetry // Disable react-admin applications send an anonymous request on mount to a telemetry server operated by marmelab.
			>
				<CustomRoutes noLayout >
					<Route path="/clear-storage" element={<Health />} />
				</CustomRoutes>
				{(permissions: any) => fetchResources(permissions)}
			</Admin>
		</ThemeProvider>
	)
}

export default AppWrapper;
