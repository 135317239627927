import { DateField, EmailField, FunctionField, ImageField, NumberField, RichTextField, SelectField, TextField, UrlField } from "react-admin";
import { FormattedBooleanField, RefModField, RefModRecordField } from "./corebosUtils";
import Box from "@mui/material/Box";



export const FieldDisplay = ({ field, module, describe = null, referToMod = '', referenceCustomLink = '', handleClick = null }: { field: any, module: string, describe?: any, referToMod?: string, referenceCustomLink?: string, handleClick?: any }) => {

    let userlist = [];
    
    switch (Number(field.uitype)) {
        case 21: // TextBox small
            return <TextField key={field.name} label={field.label} source={field.name} />;
        case 19: // TextBox big
            return <RichTextField key={field.name} label={field.label} source={field.name} />;
        case 5: // Date
            return <DateField key={field.name} label={field.label} source={field.name} />;
        case 50: // DateTime
            return <DateField key={field.name} label={field.label} source={field.name} showTime />;
        case 7: // Number
            return <NumberField key={field.name} label={field.label} source={field.name} />;
        case 9: // Percentage
            return <NumberField key={field.name} label={field.label} source={field.name} options={{ style: 'percent' }} />;
        case 71: // Currency
            return <NumberField key={field.name} label={field.label} source={field.name} options={{ style: 'currency', currency: 'EUR' }} />;
        case 10: { // Module Relation
            if (!describe) {
                return <></>;
            }
            let refMod = '';
            if (referToMod) {
                refMod = referToMod;
            } else if (field.type.refersTo && Array.isArray(field.type.refersTo) && field.type.refersTo.length > 1) {
                refMod = field.type.refersTo;
            } else {
                refMod = field.type.refersTo[0] ?? '';
            }

            return <RefModField refMod={refMod} describe={describe} field={field} handleClick={handleClick} referenceCustomLink={referenceCustomLink} />
        }
        case 101: // User Relation
        case 53: // User Relation: Assigned To
            userlist = describe[module]?.userlist ?? [];
            return (
                <SelectField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    choices={userlist}
                    optionText="username"
                    optionValue="userid"
                />
            );
        case 52: // User Relation: Created and Modified by
            userlist = describe[module]?.userlist ?? [];
            return <SelectField key={field.name} label={field.label} source={field.name} choices={userlist} optionText="username" optionValue="userid" />;
        case 13: // Email
            return <EmailField key={field.name} label={field.label} source={field.name} sx={{ textDecoration: 'none' }} />;
        case 17: // URL
            return <UrlField key={field.name} label={field.label} source={field.name} />;
        case 56: // Checkbox
            return <FormattedBooleanField key={field.name} label={field.label} source={field.name} />;
        case 28: // Image
        case 69: // Image
            return <>
                {handleClick ?
                    <FunctionField key={'ref' + field.name} label={field.label} render={(aRecord: any) => {
                        const filetype: string = aRecord.filetype ?? '';
                        if(filetype.startsWith('image/')){
                            return (
                                <Box onClick={() => handleClick(aRecord, module, field)} sx={{ cursor: 'pointer' }}> 
                                    <ImageField key={field.name} label={field.label} source="_downloadurl" record={aRecord} /> 
                                </Box>
                            )
                        }

                        return <UrlField key={field.name} label={field.label} source="_downloadurl" record={aRecord} />
                    }
                    } />
                    : <ImageField key={field.name} label={field.label} source="_downloadurl" />}
            </>
        case 77:
        case 15: // SelectWithRole,
        case 16: // Select,
        case 1613: // SelectModules,
        case 1024: // SelectRoles,
        case 33: // SelectMultiple,
        case 3313: // SelectModulesMultiple,
            return <SelectField key={field.name} label={field.label} source={field.name} choices={field.type.picklistValues} optionText="label" optionValue="value" />;
        case 1:
        case 11: // Phone
        case 14: // Time
        case 85: // Skype
        case 4:  // mod_alert_arr.AutoGenerated,
        default:
            return <TextField key={field.name} label={field.label} source={field.name} />;
    }

}

export const FieldRecordDisplay = ({ field, module, record, describe = null, referToMod = '', referenceCustomLink = '', handleClick = null }: { field: any, module: string, record: any, describe?: any, referToMod?: string, referenceCustomLink?: string, handleClick?: any }) => {

    let userlist = []
		switch (Number(field.uitype)) {
			case 21: // TextBox small
				return <TextField key={field.name} label={field.label} source={field.name}  />;
			case 19: // TextBox big
				return <RichTextField key={field.name} label={field.label}  source={field.name} record={record} />;
			case 5: // Date
				return <DateField key={field.name} label={field.label}  source={field.name} record={record} />;
			case 50: // DateTime
				return <DateField key={field.name} label={field.label} source={field.name} record={record} showTime />;
			case 7: // Number
				return <NumberField key={field.name} label={field.label} source={field.name} record={record} />;
			case 9: // Percentage
				return <NumberField key={field.name} label={field.label} source={field.name} record={record} options={{ style: 'percent' }} />;
			case 71: // Currency
				return <NumberField key={field.name} label={field.label} source={field.name} record={record} options={{ style: 'currency', currency: 'EUR' }} />;
			case 10: { // Module Relation
				if(!describe){
					return <></>;
				}
				let refMod = '';
				if(referToMod){
					refMod = referToMod;
				} else if(field.type.refersTo && Array.isArray(field.type.refersTo) && field.type.refersTo.length > 1){
						refMod = field.type.refersTo;
				} else {
						refMod = field.type.refersTo[0] ?? '';
				}

				return <RefModRecordField refMod={refMod} describe={describe} field={field} handleClick={handleClick} referenceCustomLink={referenceCustomLink} record={record} />
			}
			case 101: // User Relation
			case 53: // User Relation: Assigned To
				userlist = describe[module]?.userlist ?? [];
				return (
					<SelectField
						key={field.name}
						label={field.label}
						source={field.name}
						record={record}
						choices={userlist}
						optionText="username"
						optionValue="userid"
					/>
				);
			case 52: // User Relation: Created and Modified by
				userlist = describe[module]?.userlist ?? [];
				return <SelectField key={field.name} label={field.label} source={field.name} record={record} choices={userlist} optionText="username" optionValue="userid" />;
			case 13: // Email
				return <EmailField key={field.name} label={field.label} source={field.name} record={record}  sx={{textDecoration: 'none'}} />;
			case 17: // URL
				return <UrlField key={field.name} label={field.label} source={field.name} record={record}  />;
			case 56: // Checkbox
				return <FormattedBooleanField key={field.name} label={field.label} source={field.name} record={record} />;
			case 28: // Image
			case 69: // Image
				return <>
					{ handleClick ? 
					<FunctionField key={'ref'+field.name} label={field.label} render={(aRecord: any) => {
						return (
							<Box onClick={ () => handleClick(aRecord, module, field)} sx={{cursor: 'pointer'}}> <ImageField key={field.name} label={field.label} source={field.name} record={record} /> </Box>
						)}
					} />
					: <ImageField key={field.name} label={field.label} source={field.name} record={record} />} 
				</>
            case 77:
			case 15: // SelectWithRole,
			case 16: // Select,
			case 1613: // SelectModules,
			case 1024: // SelectRoles,
			case 33: // SelectMultiple,
			case 3313: // SelectModulesMultiple,
				return <SelectField key={field.name} label={field.label} source={field.name} record={record} choices={field.type.picklistValues} optionText="label" optionValue="value" />;
			case 1:
			case 11: // Phone
			case 14: // Time
			case 85: // Skype
			case 4:  // mod_alert_arr.AutoGenerated,
			default:
				return <TextField key={field.name} label={field.label} source={field.name} record={record} />;
		}

}