import Box from '@mui/material/Box';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const Scatterchart = (props: any) => {

  const { questions } = props;
  const data = Array.isArray(questions?.answer) ? questions?.answer?.map((ans: any) => {
    const value: any = Object.values(ans)[1];
    return {
      x: Object.values(ans)[0],
      y: parseInt(value)
    }
  }) : [];

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300, width: '100%' }}>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          style={{width: '100%', Height: 400}}
          margin={{
            top: 0,
            right: 50,
            bottom: 150,
            left: 50,
          }}
        >
          <CartesianGrid />
          <XAxis dataKey="x" name="service_contract_tc" angle={-45} textAnchor="end" minTickGap={-200} />
          <YAxis type="number" dataKey="y" name="sum" />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Scatter name="A school" data={data} fill="#8884d8" />
        </ScatterChart>
      </ResponsiveContainer>
    </Box>
  );

}

export default Scatterchart;
