import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useId } from "react";


const Numberchart = (props: any) => {

  const randomKey = useId();
  const { questions } = props;
  const results = questions?.answer;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200, width: '100%', padding: 50 }}>
      {Array?.isArray(results) && results?.map((result: any, index: number) => {
        const value: any = Object.values(result)[0];

        return (
          <Typography key={randomKey+index} component="span" sx={{ color: '#000', fontSize: 50 }}> {value} </Typography>
        )
      })}
    </Box>
  );

}

export default Numberchart;
