import React, { useEffect, useState } from 'react';
import DynamicComponent from '../components/DynamicComponent';
import { doInvoke, doQuery } from '../utils/lib';
import Grid from '@mui/material/Grid';
import { Loading, useGetIdentity } from 'react-admin';



const Demographs = () => {

    const [questionsdata, setQuestionsdata] = useState<any>(null);
    const {identity} = useGetIdentity();
    const [isLoadingQustion, setIsLoadingQustion] = useState<boolean>(false);


    useEffect(() => {
        doQuery('select id from cbQuestion where active_reactadmin=1').then((cbQuestions: any) => {
            setIsLoadingQustion(true);
            const idarray = cbQuestions?.map((item: any) => { return item?.id })?.toString();
            const userid: any = identity?.id || '';
            const params: any = {"{assigneduserid}": userid };
            doInvoke('cbQuestionAnswer', { qid: idarray, params: JSON.stringify(params) }, 'POST').then((data: any) => {
                let questions: any[] = [];
                if(data && typeof data === 'object'){
                    questions.push(data);
                }
                if(Array.isArray(data)){
                    questions = data;
                }
                setQuestionsdata(questions);
            }).catch(() => {
                console.log('Something went wrong');
            })
        }).catch(() => {
            console.log('Something went wrong');
        }).finally(() => {
            setIsLoadingQustion(false);
        })
    }, [identity?.id])

    return (
        <Grid container spacing={2} sx={{paddingTop: 20}}>
            <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={2}>
                    {isLoadingQustion
                        ? <Loading loadingPrimary='' loadingSecondary='' />
                        : <>
                            {questionsdata && questionsdata.length > 0 &&
                                <DynamicComponent questionsdata={questionsdata} />
                            }
                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}


export default Demographs;