import * as React from 'react';
import { useState, useEffect } from 'react';
import {ShowBase,useRecordContext,useRedirect,Button,useDelete,useNotify,Notification,EditButton,useRefresh,Loading,useTranslate,ReferenceManyField,useGetIdentity, useResourceContext, usePermissions,} from 'react-admin';
import {Box,Typography,Divider,Grid,Card,CardContent,useMediaQuery,CardHeader,Paper,Chip, List,ListItem,ListItemSecondaryAction,ListItemText,CircularProgress,IconButton,Menu,MenuItem, useTheme} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { CommentList } from './comment/CommentList';
import CommentForm from './comment/CommentForm';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import QuickCreateModal from '../components/QuickCreateModal';
import { CustomUrlField, getDataFromLocalDb } from '../utils/Helpers';
import { TABLE_DESCRIBE } from '../local-db';
import cbUtils from '../components/corebosUtils/corebosUtils';
import { MarkDownPreview } from '../components/MarkDown';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CustomComponentList from './CustomComponentList';
import { DocumentUpload } from '../components/DocumentUpload';
import DoneIcon from '@mui/icons-material/Done';
import { doDelete, doQuery, doRevise } from '../utils/lib';
import { useParams } from 'react-router-dom';



const ITEM_HEIGHT = 48;

export const TaskShow = (props: any) => {

    const [reloadTriggerID, setReloadTriggerID] = useState<any>(null);
    const refresh = useRefresh();

    useEffect(() => {
        refresh();
    }, [reloadTriggerID, refresh])

    return (
        <ShowBase resource={props?.resource} id={props?.id}>
            <TaskShowContent {...props} reloadTriggerID={reloadTriggerID} setReloadTriggerID={setReloadTriggerID} resource={props?.resource} />
        </ShowBase>
    );
};

const TaskShowContent = (props: any) => {
    const params: any = useParams();
	const id = params?.id || '';
    const resource: any = useResourceContext();
    const theme = useTheme();
    const record = useRecordContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const [deleteOne] = useDelete();
    const resourceComment = 'ModComments';
    const allPermissions: any = usePermissions();
	const modPermission = allPermissions ? allPermissions?.permissions?.filter((permission: any) => permission.module === resource)[0]?.permissions : null;
    const translate = useTranslate();
    const [quickCreateOpen, setQuickCreateOpen] = useState<boolean>(false);
    const [quickCreateAction, setQuickCreateAction] = useState<string>(''); // assign, review
    const [filterFields, setFilterFields] = useState<any[]>([]);
    const [describe, setDescribe] = useState<any>({});
    const [labelField, setLabelField] = useState<any>('');
    const [taskActivities, setTaskActivities] = useState<any[]>([]);
    const [modalResource, setModalResource] = useState<string>('Timecontrol');
    const isMedium = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
    const [quickCreate, setQuickCreate] = useState<boolean>(true);
    const [isNewRecord, setIsNewRecord] = useState<boolean>(true);
    const [quickFormDefaultValues, setQuickFormDefaultValues] = useState<any>({});
    const { identity } = useGetIdentity();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [contacts, setContacts] = useState<any[]>([]);
    const [taskDocuments, setTaskDocuments] = useState<any[]>([]);
    const open = Boolean(anchorEl);


    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        doQuery(`SELECT * FROM JustLogIt WHERE ProjectTask.id='${id}' ORDER BY createdtime DESC LIMIT 5`).then((result: any) => {
            setTaskActivities(result);
        }).catch((err: any) => {
            console.log('error', err);
            console.log('Something went wrong');
        })
        doQuery(`SELECT * FROM Contacts`).then((result: any) => {
            setContacts(result)
        }).catch((err: any) => {
            console.log('error', err);
            console.log('Something went wrong');
        });
        doQuery(`SELECT * FROM documents  where related.projectTask='${id}' ORDER BY createdtime DESC`).then((result: any) => {
            setTaskDocuments(result);
        }).catch((err: any) => {
            console.log('error', err);
            console.log('Something went wrong');
        })
        getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
            setDescribe(result);
            const parenttask = result[resource]?.fields?.filter((field: any) => field.name === 'parenttask')[0] || null;
            const modFields = result[resource]?.fields ?? [];
            const fFields = result[resource]?.filterFields?.fields ?? [];
            let lfield = result[resource].labelFields.split(',');
            lfield = lfield[0] ?? '';
            setLabelField(lfield);
            let filters = [];
            for (const value of modFields) {
                if (fFields.includes(value.name)) {
                    filters.push(value);
                }
            }
            if (record?.parenttask) {
                filters.push(parenttask)
            }
            const extraFilterFields: any[] = modFields.filter((field: any) => field.name === 'devopstask')
            setFilterFields([...filters, ...extraFilterFields]);
        });
    }, [resource, id, record?.parenttask])


    const handleDelete = (record: any) => {
        setIsLoading(true);
        deleteOne(props?.resource, { id: record.id, previousData: record },
            {
                onSuccess: () => {
                    setIsLoading(false);
                    notify('Record deleted', {type: 'success'});
                    redirect(`${resource}`);

                },
                onError: (error: any) => {
                    setIsLoading(false);
                    notify(error.message, {type: 'error'});
                }
            }
        );
    }

    const handleQuickCreateModal = (open: boolean, module = '', createQuick = true, newRecord = true, defaults: any = null, action = '') => {
        setIsNewRecord(newRecord);
        setQuickCreate(createQuick);
        setModalResource(module);
        setQuickCreateOpen(open);
        setQuickFormDefaultValues(defaults);
        setQuickCreateAction(action);
    };

    const afterSave = (mod: string = '') => {
        props.setReloadTriggerID(new Date().getTime());
    };


    const handleDocumentDelete = (aDocument: any, currentDocumentList: any[]) => {
        const documentList: any[] = currentDocumentList;
        setTaskDocuments((prevDocuments) => prevDocuments.filter((prevDocument) => prevDocument.id !== aDocument.id));
        doDelete(aDocument.id).catch((error: any) => {
            notify(error.message, {type: 'error'});
            setTaskDocuments(documentList);
        })
    }

    const afterUpload = () => {
        doQuery(`SELECT * FROM documents  where related.projectTask='${id}' ORDER BY createdtime DESC`).then((result: any) => {
            setTaskDocuments(result);
        }).catch((err: any) => {
            console.log('error', err);
            console.log('Something went wrong');
        })
    }

    const updateStatus = (aRecord: any, newStatus: string) => {
        setIsLoading(true);
        const dataTosend = Object.assign(aRecord, { projecttaskstatus: newStatus });
        doRevise(props?.resource, { id: dataTosend?.id, projecttaskstatus: newStatus }).then(() => {
            props?.setReloadTriggerID(new Date().getTime());
        }).catch(() => {
            notify(translate('Something went wrong'), {type: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }


    if (!record) return <Loading />;



    return (
        <>
            {quickCreateOpen &&
                <QuickCreateModal afterSave={afterSave} record={record} quickCreate={quickCreate} action={quickCreateAction} isNewRecord={isNewRecord} resource={modalResource} relatedMod={resource} defaultValues={quickFormDefaultValues} title={record?.projecttaskname} open={quickCreateOpen} handleQuickCreateModal={handleQuickCreateModal} fullWidth={!quickCreate} />
            }
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} >
                    <Box mt={2}>
                        <Card elevation={1} style={{ width: 'auto', padding: '16px' }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} >
                                        <Typography variant="h6">{record[labelField]} </Typography>
                                        <Typography variant="body2">{record.projecttask_no}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <Box justifyContent={isMedium ? "end" : "start"} style={{ display: 'flex' }}>
                                            <Button color='primary' variant="outlined" size="small" sx={{marginLeft: theme.spacing(1), textTransform: 'none'}} onClick={() => updateStatus(record, 'Approved')} disabled={isLoading} label={translate('Approve')} >
                                                {isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary'} size={12} /> : <DoneIcon />}
                                            </Button>

                                            <Button color='primary' size="small" sx={{marginLeft: theme.spacing(1), textTransform: 'none'}} onClick={() => handleQuickCreateModal(true, 'Timecontrol', true, true, { relatedto: record?.id })} label={translate('Time Control')} >
                                                <AccessTimeIcon />
                                            </Button>

                                            {modPermission?.update &&
                                                <EditButton
                                                    to={`/${resource}/${record.id}`}
                                                    label="Edit"
                                                    record={JSON.parse(JSON.stringify(record))}
                                                    sx={{marginLeft: theme.spacing(1), textTransform: 'none'}}
                                                    size="small"
                                                    {...props}
                                                />
                                            }

                                            <Box >
                                                    <IconButton
                                                        aria-label="more"
                                                        aria-controls="long-menu"
                                                        aria-haspopup="true"
                                                        onClick={handleClick}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="long-menu"
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={open}
                                                        onClose={handleClose}
                                                        slotProps={{
                                                            paper: {
                                                                sx: {
                                                                    maxHeight: ITEM_HEIGHT * 4.5,
                                                                    width: '20ch',
                                                                }
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => {
                                                            handleClose();
                                                            redirect(`/ProjectTask/create?projectid=${record?.projectid}&parenttask=${record?.id}`)
                                                        }}>
                                                            {modPermission?.create &&
                                                                <Button color='primary' label={translate('Create SubTask')} >
                                                                    <AddIcon />
                                                                </Button>}
                                                        </MenuItem>
                                                        <MenuItem onClick={() => {
                                                            handleClose();
                                                            handleQuickCreateModal(true, 'ProjectTask', false, true, record)
                                                        }}>
                                                            {modPermission?.create &&
                                                                <Button color='primary' disabled={isLoading} label={translate('Duplicate')} >
                                                                    <FileCopyIcon />
                                                                </Button>
                                                            }
                                                        </MenuItem>
                                                        <MenuItem onClick={() => {
                                                            handleClose();
                                                            handleDelete(record)
                                                        }}>
                                                            {modPermission?.delete &&
                                                                <Button 
                                                                    sx={{
                                                                        textTransform: 'none',
                                                                        color: theme.palette.error.main,
                                                                    }}
                                                                    disabled={isLoading} label={translate('ra.action.delete')} >
                                                                    <DeleteIcon />
                                                                </Button>
                                                            }
                                                        </MenuItem>
                                                    </Menu>
                                                
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box mt={3}>
                                    <Grid container spacing={2}>
                                        {filterFields.map((field: any) => {
                                            let referenceCustomLink = '';
                                            if (field.name === 'projectid') {
                                                referenceCustomLink = `/${'ProjectTask'}?projectid=${record?.projectid}&view=kanban`;
                                            }
                                            return (
                                                <>
                                                    {field.name !== labelField &&
                                                        <Grid item xs={6} md={2} >
                                                            <Box>
                                                                <Typography color="textSecondary" variant="body2">
                                                                    {field.label}
                                                                </Typography>
                                                                {cbUtils.field2DisplayRecord(field, record, resource, describe, '', referenceCustomLink)}
                                                            </Box>
                                                        </Grid>
                                                    }
                                                </>
                                            )
                                        })
                                        }
                                    </Grid>
                                </Box>
                                <Box mt={2} className='cbRaMarkdownPreview'>
                                    <MarkDownPreview record={record} source='description' />
                                </Box>
                            </CardContent>
                        </Card>
                        <Box>
                            <Divider />
                            <Box>
                                <CommentForm contacts={contacts} handleCancel={null} replyingComment={null} comment={null} isNewRecord={true} projectTask={record} setReloadTriggerID={props.setReloadTriggerID} resource={'ModComments'} />
                            </Box>
                            <Box>
                                <CommentList contacts={contacts} resource={resourceComment} reloadTriggerID={props.reloadTriggerID} setReloadTriggerID={props.setReloadTriggerID} projectTask={record} describe={describe} />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box my={2}>
                        <Card elevation={2} >
                            <CardHeader
                                sx={{background: '#397F95', color: 'white', fontWeight: 'bold', '& .assignedChip': { background: 'rgb(225 244 247)', marginTop: '4px', color: '#000000'}, '& .deleteIconChip': { color: '#000000'}}}
                                title={translate('Documents')}
                                action={
                                    <>
                                        {modPermission?.update &&
                                            <DocumentUpload
                                                disableAutoUpload={false}
                                                dropzoneSelectorId="projectTaskFileDropzone"
                                                assigned_user_id={identity?.id ?? ''}
                                                relationsIds={record.id}
                                                relProjectId={record.projectid}
                                                afterUpload={afterUpload}
                                            />
                                        }
                                    </>
                                }
                            />
                            <List>
                                {taskDocuments.map((document: any) => {
                                    return (
                                        <ListItem
                                            key={document.id}
                                            style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <CustomUrlField record={document} source='_downloadurl' urlTextField='notes_title' target='_blank' />
                                                }
                                                sx={{overflow: 'hidden'}}
                                            />
                                            <ListItemSecondaryAction>
                                                {modPermission?.update &&
                                                    <Chip
                                                        onDelete={() => handleDocumentDelete(document, taskDocuments)}
                                                        size="medium"
                                                        sx={{background: '#fff'}}
                                                    />
                                                }
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Card>
                    </Box>
                    <Box my={2}>
                        <Card elevation={2} >
                            <CardHeader
                                sx={{background: '#397F95', color: 'white', fontWeight: 'bold', '& .assignedChip': { background: 'rgb(225 244 247)', marginTop: '4px', color: '#000000'}, '& .deleteIconChip': { color: '#000000'}}}
                                title={translate('Activities')}
                                subheader={<Typography >{record[labelField]}</Typography>}
                            />
                            <Timeline sx={{align: 'left'}} className='justLogItTimeline'>
                                {
                                    taskActivities.map((activity: any) => {
                                        return (
                                            <TimelineItem key={activity.id}>
                                                <TimelineSeparator>
                                                    <TimelineDot>
                                                        <HistoryIcon />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Paper style={{ border: 'none' }} elevation={0}>
                                                        <Typography variant="body1" color="textPrimary"> {activity.doneon}, {activity.hora} </Typography>
                                                        <Typography variant="body2" color="inherit"> {activity.message_log} </Typography>
                                                    </Paper>
                                                </TimelineContent>
                                            </TimelineItem>
                                        )
                                    })
                                }
                            </Timeline>
                        </Card>
                    </Box>
                    {
                        <ReferenceManyField perPage={10} reference={resource} target="parenttask" filter={{ parenttask: record.id }}>
                            <CustomComponentList describe={describe} {...props} resource={resource} />
                        </ReferenceManyField>
                    }
                </Grid>
            </Grid>

            <Notification />
        </>
    );
};
