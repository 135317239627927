export const status = [
    'Backlog',
    'Approved',
    'To Do',
    'Doing',
    'To Be Tested',
    'Done'
];

export const statusNames = {
    'Backlog': 'Backlog',
    'Approved': 'Approved',
    'To Do': 'To Do',
    'Doing': 'Doing',
    'To Be Tested': 'To Be Tested',
    'Done': 'Done',
};


export const statusChoices = status.map(type => ({
    id: type,
    /* @ts-ignore */
    name: statusNames[type],
}));
