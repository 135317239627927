import React, { useState } from 'react';
import ReactMde from "react-mde";

//@ts-ignore
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";


export const converter = new Showdown.Converter({
	tables: true,
	simplifiedAutoLink: true,
	strikethrough: true,
	tasklists: true,
	smartIndentationFix: true,
	openLinksInNewWindow:true,
	emoji: true,
	underline: true,
	simpleLineBreaks: true,
	backslashEscapesHTMLTags: true,
	excludeTrailingPunctuationFromURLs: true,
});

export const MarkDownPreview = ({ record, source } : { record: any, source: string }) => {

    return (
		<div className="cbRaMarkdownPreview">
			<ReactMde
				value={record[source]}
				selectedTab={'preview'}
				generateMarkdownPreview={markdown =>
					Promise.resolve(converter.makeHtml(markdown))
				}
				toolbarCommands= {[]}
			/>
		</div>
	)
};

export const MarkDownInput = ({ record, source, handleMarkdownField, saveThisImage, cureentMarkdownInputValue } : { record: any, source: string, handleMarkdownField: any, saveThisImage: any, cureentMarkdownInputValue: any }) => {

	const [value, setValue] = useState<any>(record?.source || '');
    const [selectedTab, setSelectedTab] = useState<'preview' | 'write'>('write');

	const handleValue = (newValue: any) => {
		setValue(newValue);
		handleMarkdownField(source, newValue, cureentMarkdownInputValue);
	}


    return (
		<ReactMde
			value={value}
			onChange={(value) => handleValue(value)}
			selectedTab={selectedTab}
			onTabChange={setSelectedTab}
			generateMarkdownPreview={markdown =>
				Promise.resolve(converter.makeHtml(markdown))
			}
			childProps={{
			writeButton: {
				tabIndex: -1
			}
			}}
			minEditorHeight={300}
			maxEditorHeight={800}
			minPreviewHeight={300}
			paste={{
				saveImage: saveThisImage,
				//multiple: false,
				//command: '',
				//accept: ''
			}}
		/>
	)
};
