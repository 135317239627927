import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditButton, ShowButton, DeleteButton, Button, useTranslate, useRefresh, useNotify } from 'react-admin';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { doRevise } from '../utils/lib';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';



const RowAction = (allProps: any) => {

	const navigate = useNavigate();
	const { basePath, record, resource, modPermission, ...props } = allProps;
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const open = Boolean(anchorEl);
	const notify = useNotify();
	const translate = useTranslate();
	const refresh = useRefresh();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const updateStatus = (data: any, newValues: any) => {
		const dataTosend = Object.assign(data, newValues);
		setIsLoading(true);
		doRevise(resource, dataTosend).then(() => {
			refresh();
		}).catch(() => {
			notify(translate('Something went wrong'), {type: 'error'});
		}).finally(() => {
			setIsLoading(false);
		})
	}

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-label="more"
				aria-controls="rowMenu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="rowMenu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
			>
				<List component="nav" disablePadding={false}>
					<ListItem><ShowButton resource={resource} record={record} sx={{marginTop: '-10px', textTransform: 'none'}} /></ListItem>
					{modPermission?.update ?
						<ListItem><EditButton resource={resource} record={record} sx={{marginTop: '-10px', textTransform: 'none'}} /> </ListItem>
						: null}
					{resource && resource === 'Project' &&
						<ListItem sx={{marginTop: '-10px', textTransform: 'none'}} >
							<Button
								label={translate('Tasks')}
								color="primary"
								onClick={() => {
									navigate(`/${'ProjectTask'}?projectid=${record?.id}&view=kanban`
									)
									window.location.reload()
								}}
								size="small"
								sx={{textTransform: 'none', '& .MuiButton-startIcon': { marginRight: 1}}}
								startIcon={<AssignmentTurnedInIcon />}
							/>
						</ListItem>
					}
					{resource && resource === 'cbCalendar' &&
						<ListItem sx={{marginTop: '-10px', textTransform: 'none'}} >
							<Button
								label={translate('Finish')}
								color="primary"
								onClick={() => updateStatus(record, { eventstatus: 'Completed' })}
								sx={{textTransform: 'none', '& .MuiButton-startIcon': { marginRight: 1}}}
								disabled={isLoading}
								size="small"
								startIcon={<DoneRoundedIcon />}
							/>
						</ListItem>
					}
					{modPermission?.delete &&
						<ListItem><DeleteButton {...props} undoable={false} resource={resource} basePath={basePath} record={record} sx={{marginTop: '-10px', textTransform: 'none'}} /> </ListItem>
					}
					{/* {modPermission && modPermission.delete ? 
						 <ListItem><DeleteButton basePath={basePath} record={record} sx={{marginTop: '-10px', textTransform: 'none'}}} /> </ListItem>
						: null} */}
				</List>
			</Menu>
		</>
	)
};

export default RowAction;