import englishMessages from 'ra-language-english';

const ra = englishMessages.ra;

ra.page.dashboard = 'Dashboard';
ra.page.empty = 'Empty';
ra.page.invite = 'Invite';
ra.action.export = 'Export';
ra.action.add_filter = 'Search by filter'


const en = {
    ra,
    dashboard: {
        searchInputPlaceholder: "Search...",
    },
    translations: {
        somethingWentWrong: "Something went wrong",
        all: "All",
        dataUpdated: "Data updated",
        menuPosition: "Menu Position",
        left: "Left",
        right: "Right",
        top: "Top",
        upload: "Upload",
        unsupportedFileType: "Unsupported file type",
        relateAction: "Relate",
        selectAction: "Select",
        backAction: "Back",
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        configuration: "Configuration",
        profile: "Profile",
        viewAll: "View all",
        addAction: "Add",
        associateNew: "Associate New",
        associateExisting: "Associate Existing",
        cancelAction: "Cancel",
        closeAction: "Close",
        associateThisRecord: "Associate new/existing to this record",
    },
    "incorrect response: INVALID_SESSIONID: Session Identifier provided is Invalid": 'Invalid Session',
    "Theme Setting": "Theme Setting",
    "Theme Color": "Theme Color",
    "Menu Position": "Menu Position",
    "Top": "Top",
    "Left": "Left",
    "Right": "Right",
    "Position": "Position",
    "Time Control": "Time Control",
    "Dashboard": "Dashboard",
    "Cancel": "Cancel",
    "File": "File",
    "Search": "Search",
    "View All": "View All",
    "Search for": "Search for",
    "Assigned to me": "Assigned to me",
    "Data saved successfully": "Data saved successfully",
    "Something went wrong": "Something went wrong",
    "Something went wrong, try again later": "Something went wrong, try again later",
    "Edit": "Edit",
    "New Task": "New Task",
    "Archive": "Archive",
    "Version": "Version",
    "A new version was created successfully": "A new version was created successfully",
    "Drop your file here to upload or click to select": "Drop your file here to upload or click to select",
    "TimeControl created successfully": "TimeControl created successfully",
    "TimeControl updated successfully": "TimeControl updated successfully",
    "Record created successfully": "Record created successfully",
    "Activities": "Activities",
    "Activity": "Activity",
    "Create Calendar Task": "Create Calendar Task",
    "Tasks": "Tasks",
    "Calendar": "Calendar",
    "Go to full form": "Go to full form",
    "Preview": "Preview",
    "Stop": "Stop",
    "Pause": "Pause",
    "Duplicate": "Duplicate",
    "Are you sure": "Are you sure",
    "You are about to duplicate this record": "You are about to duplicate this record",
    "Yes": "Yes",
    "No": "No",
    "Create SubTask": "Create SubTask",
    "Review": "Review",
    "Parent": "Parent",
    "Create New": "Create New",
    "Assignee": "Assignee",
    "Assigned To": "Assigned To",
    "Sub Tasks": "Sub Tasks",
    "Subscribers": "Subscribers",
    "Add Subscriber": "Add Subscriber",
    "File uploaded successfully": "File uploaded successfully",
    "Documents": "Documents",
    "Upload": "Upload",
    "Unsupported file type": "Unsupported file type",
    "Invalid file type or the file is too large": "Invalid file type or the file is too large",
    "Description": "Description",
    "Start Time": "Start Time",
    "End Time": "End Time",
    "Total Time": "Total Time",
    "Maximum": "Maximum",
    "hours": "hours",
    "Create TimeControl": "Create TimeControl",
    "Finish": "Finish",
    "Approve": "Approve",
    "Effort": "Effort",
    "Invalid username or password": "Invalid username or password",
    "Filters": "Filters",
    "Worked Hours": "Worked Hours",
    "GoBack": "Go Back",
    "Clear Storage": "Clear Storage",
    "ClearingStorageInfo": "By Clcking the button below, your local storage for this application will be deleted and all your saved data will lost",

};

export default en;
