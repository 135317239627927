import React, { useState, useEffect } from 'react';
import { 
    List, 
    BulkDeleteButton,
    Datagrid,
    FunctionField,
    useTranslate,
    useRefresh,
    Button,
    CreateButton,
} from 'react-admin';
import DocumentPreviewModal from './DocumentPreview/DocumentPreviewModal';
import ResponsiveList from './ResponsiveList';
import { ListActionToolbar } from './ListActionToolbar';
import { CBListFilter } from './CBListFilter';
import CustomFieldLink from './CustomFieldLink';
import cbUtils from './corebosUtils/corebosUtils';
import RowAction from './RowAction';
import { authProvider } from '../authProvider';
import QuickCreateModal from './QuickCreateModal';
import { CustomEmpty } from './CustomEmpty';
import AddIcon from '@mui/icons-material/Add';
import { AsideDetailView } from './AsideDetailView';
import { FormControl, MenuItem, Select, Typography, Box, useMediaQuery } from '@mui/material';
import {Link} from 'react-router-dom';
import { doInvoke } from '../utils/lib';
import { CbStaticFILTER } from '../types';



const BulkActionButtons = (props: any) => (
    <BulkDeleteButton {...props} />
);

const MasterListAction = (props: any) => {

    const translate = useTranslate();
    let params = '';
    const { filterDefaultValues, relmod, relfieldval, resource, handleQuickCreateModal, selectedStaticFilter, handleFilterChange, staticFilters } = props;

    if(filterDefaultValues){
        params = `relatedModule=${relmod}&relatedField=${relfieldval || 'relations'}&relatedFieldValue=${relfieldval}`
    }

    return (
        <Box>
            <ListActionToolbar handleCreate={ resource === 'cbCalendar' ? handleQuickCreateModal : null} {...props} params={params} />
            <Box display="flex" sx={{mt: 4}}>
                <Typography variant="subtitle2" sx={{ marginTop: '32px'}}>{translate('Filters')}</Typography>
                <FormControl sx={{minWidth: 240, marginTop: (theme: any) => theme.spacing(2), marginLeft: (theme: any) => theme.spacing(1), display: 'flex'}}>
                    <Select
                        id="staticFilterSelect"
                        value={selectedStaticFilter}
                        onChange={handleFilterChange}
                        variant="outlined"
                    >
                        {staticFilters.map((staticFilter: any) => <MenuItem key={staticFilter.name} value={staticFilter}> {staticFilter.name} </MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
}


const EmptyActionButton = (props: any) => {

    const{ resource, handleQuickCreateModal } = props;

    if(resource === 'cbCalendar' ){
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleQuickCreateModal(null, true) } 
                label="Create"
                title="Create"
            >
            <AddIcon />
          </Button>
        )
    }

    return <CreateButton variant="contained" {...props} />
}

export const ListView = ({resource, basePath, fields, filterFields, describe, linkFields=[], labelField='', title='', filterDefaultValues = {}, relmod='', relfield='', relfieldval='', rel_id='', otherProps } : {resource: string, basePath: string, fields: any[], filterFields: any[], linkFields: any[], labelField: string, describe: any, title: any, filterDefaultValues: any, relmod?: any, relfield?: any, relfieldval?: any, rel_id?: string,  otherProps: any }) => {

    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const [docPreviewOpen, setDocPreviewOpen] = useState<boolean>(false);
	const [ clickedItem, setClickedItem ] = useState<any>({});
    const [ modPermissions, setModPermissions ] = useState<any>(null);
    const translate = useTranslate();
    const [quickCreateOpen, setQuickCreateOpen] = useState<boolean>(false);
    const [asideModalOpen, setAsideModalOpen] = useState<boolean>(false);
    const [selectedStaticFilter, setSelectedStaticFilter] = useState<CbStaticFILTER>({});
	const [staticFilters, setStaticFilters] = useState<CbStaticFILTER[]>([]);
    const refresh = useRefresh();
    const [ selectedRecord, setSelectedRecord ] = useState<any>(null);
    let asideModalFields: any[] = describe[resource]?.fields ?? [];
    asideModalFields = asideModalFields.filter((field: any) => field.summary === 'B');


    useEffect(() => {
        authProvider.getModulePermission(resource).then((result: any) => {
            setModPermissions(result);
        });
        const getStaticFilters = (filters: any, key: string) => {
            let advcriteriaEVQL = JSON.parse(filters[key].advcriteriaEVQL)?.reduce((a: any, v: any, index: number) => ({ ...a, [`cbfilter_${index}`]: v}), {});
            return advcriteriaEVQL;
        }
        doInvoke('getFiltersByModule', {module: resource}, 'POST').then((result) => {
			const {filters} = result;
			if(filters){
				const staticFiltersList: CbStaticFILTER[]  = Object.keys(filters).map((key) => {
					try {
						let advcriteriaEVQL = getStaticFilters(filters, key);
						return {name: filters[key].name, fields: filters[key].fields, status: filters[key].status, default: filters[key].default, filters: advcriteriaEVQL};
					} catch (error) {
						return {name: filters[key].name, fields: filters[key].fields, status: filters[key].status, default: filters[key].default, filters: {}};
					}
				});
				setStaticFilters(staticFiltersList);
			}
		}).catch((err) => {
			console.log(translate(`translations.somethingWentWrong`, err));
		});
    }, [resource, translate])
    
    const updatedfilterfields = filterFields.map((fil, index) => {
        if(index === 0){
            return {...fil, defaultfilter: true}
        }else {
            return {...fil, defaultfilter: false}
        }
    });

    const handleFilterChange = (event: any) => {
        setSelectedStaticFilter(event.target.value);
    };

    const handleDocPreviewModalOpen = (record: any, _moduleName: string, field: any=null) => {
        if(record){
			record['title'] = field?.label ?? '';
			//record['_downloadurl'] = record[field?.name] ?? '';
			if(!record.filetype){
				const urlStr: string[] = record[field?.name] ? record[field.name].split('.') : [];
				record['filetype'] = urlStr[urlStr.length-1] ?? '';
			}
		}
		setClickedItem(record);
        setDocPreviewOpen(true);
    };

    const handleQuickCreateModal = (_data: any, open: boolean) => {
        setQuickCreateOpen(open);
    };

    const onRowClick = ({record, open} : {record: any, open: boolean}) => {
        handleAsideModal(record, open);
        return '';
    }

    const handleAsideModal = (record: any, open: boolean) => {
        setSelectedRecord(record);
        setAsideModalOpen(open);
    }


    return (
        <>
            <DocumentPreviewModal record={clickedItem} title={clickedItem?.notes_title} open={docPreviewOpen} setOpen={setDocPreviewOpen} />
            { resource === 'cbCalendar' &&
                <>
                    <QuickCreateModal afterSave={() => refresh()}  resource={resource} relatedMod='ProjectTask' defaultValues={{project_task: otherProps?.project_task}} title={translate('Create Calendar Task')} open={quickCreateOpen} handleQuickCreateModal={handleQuickCreateModal} />
                    <AsideDetailView record={selectedRecord} fields={asideModalFields} linkFields={linkFields} labelField={labelField} describe={describe} resource={resource} open={asideModalOpen} handleAsideModal={handleAsideModal} />
                </>
            }
            <List
                {...otherProps}
                basePath={basePath}
                resource={resource}
                title={ title || <></>}
                perPage={10}
                filterDefaultValues={selectedStaticFilter?.filters || filterDefaultValues}
                filters={<CBListFilter fields={updatedfilterfields} describe={describe} disabledfield={relfield} disabledfieldStyle={{display: 'none'}} resource={resource} />}
                actions={<MasterListAction modPermission={modPermissions} filterDefaultValues={filterDefaultValues} relmod={relmod} relfieldval={relfieldval} resource={resource} handleQuickCreateModal={handleQuickCreateModal} selectedStaticFilter={selectedStaticFilter} handleFilterChange={handleFilterChange} staticFilters={staticFilters}  />}
                component="div"
                hasList={modPermissions?.read}
                hasCreate={modPermissions?.create}
                hasEdit={modPermissions?.update}
                hasShow={modPermissions?.read}
                empty={<CustomEmpty action={<EmptyActionButton resource={resource} handleQuickCreateModal={handleQuickCreateModal} />} {...otherProps} />}
            >
                <>
                    {isSmall
                    ? <ResponsiveList onRowClick={onRowClick} resource={resource} fields={filterFields} linkFields={linkFields} linkField={labelField} describe={describe} modPermission={modPermissions} />
                    : <Datagrid rowClick={false} bulkActionButtons={<BulkActionButtons  {...otherProps} />}>
                            {
                                fields.map((field: any) => {
                                    let handleFieldClick: any = null;
                                    if(field.uitype === '28' || field.uitype === '69'){
                                        handleFieldClick = handleDocPreviewModalOpen;
                                    }

                                    if(linkFields.includes(field.name) ){
                                        return (
                                            <FunctionField key={field.name} source={field.name} label={field.label} render={(record: any) => {
                                                return (
                                                    <>
                                                    { resource === 'cbCalendar'
                                                        ? <Typography variant="inherit" color="primary" className='previewBtnHolder' >
                                                                <> <Typography component={Link} to={`/${resource}/${record?.id}/show`} className='textLink' variant="inherit" color="primary" > { record[labelField]} </Typography> <Button onClick={() => onRowClick({record: record, open: true})} className='previewBtn' variant="outlined" color="primary" label={translate('Preview')} title={translate('Preview')} /> </>
                                                            </Typography>
                                                        :  <CustomFieldLink {...otherProps} linkField={field.name} key={field.name} record={record} basePath={basePath} resource={resource} />  
                                                    }
                                                    </>
                                            )}}/>
                                        )
                                    }
                                    
                                    return (
                                        <FunctionField key={field.name} source={field.name} label={field.label} render={(record: any) => {
                                            return (
                                                <> {cbUtils.field2DisplayRecord(field, record, resource, describe, '', '', handleFieldClick)} </>
                                        )}}/>
                                    )
                                })
                            }

                            <FunctionField render={(record: any) => {
                                return <RowAction modPermission={modPermissions} basePath={basePath} resource={resource} record={record} />
                            }}/>
                        </Datagrid>
                    }
                </>
            </List>
        </>
    )
}
