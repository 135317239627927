
import DataProvider from 'react-admin-corebos/corebosServerProvider';

// @ts-ignore
import * as cbconn from '@corebos/ws-lib/WSClientm';

export { DataProvider as RaCbDataProvider, cbconn as CoreBosWebService };

export interface SessionData {
    sessionName: string;
    userId: string;
}

export const setURL = (url: string) => {
    cbconn.setURL(url);
}

export const hasError = (data: any) : boolean => {
    return cbconn.hasError(data) === false;
}

export const getLastError = () => cbconn.lastError();

export const setSession = (sessionData: SessionData) => {
    cbconn.setSession(sessionData);
}

export const getChallenge = async (username: string, password: string) => {
    const challengeRes = await cbconn.doLogin(username, password);
    return challengeRes;
}

export const doLogin = async (username: string, password: string, withpassword?: boolean) => {
    const logindata = await cbconn.doLogin(username, password, withpassword);
    return logindata;
}

export const doLoginPortal = async (username: string, password: string, hashmethod: string = 'plaintext', entity: string = 'Contacts') => {
    const logindata = await cbconn.doLoginPortal(username, password, hashmethod, entity);
    return logindata;
}

export const doLogout = async () => {
    await cbconn.doLogout();
}

export const getListTypes = async () => {
    const listTypes = await cbconn.doListTypes();
    return listTypes;
}

export const getModDescribe = async (moduleName: string) => {
    const describe = await cbconn.doDescribe(moduleName);
    return describe;
}

export const doRetrieve = async (record: string) => {
    const result = await cbconn.doRetrieve(record);
    return result;
}

export const doMassRetrieve = async (ids: string[]) => {
    const result = await cbconn.doMassRetrieve(ids);
    return result;
}

export const doQuery = async (query: string) => {
    const result = await cbconn.doQuery(query);
    return result;
}

export const doQueryWithTotal = async (query: string) => {
    const result = await cbconn.doQueryWithTotal(query);
    return result;
}

export const doInvoke = async (method: string, params?: any, type?: string) => {
    const result = await cbconn.doInvoke(method, params, type);
    return result;
}

export const doCreate = async (moduleName: string, data: any) => {
    const result = await cbconn.doCreate(moduleName, data);
    return result;
}

export const doUpdate = async (moduleName: string, data: any) => {
    const result = await cbconn.doUpdate(moduleName, data);
    return result;
}

export const doRevise = async (moduleName: string, data: any) => {
    const result = await cbconn.doRevise(moduleName, data);
    return result;
}

export const doDelete = async (id: string) => {
    const result = await cbconn.doDelete(id);
    return result;
}

export const doMassDelete = async (ids: string[]) => {
    const result = await cbconn.doMassDelete(ids);
    return result;
}

export const massCreate = async (data: any) => {
    const result = await cbconn.doMassUpsert(data);
    return result;
}

export const getRelatedModules = async (moduleName: string) => {
    const result = await cbconn.doInvoke('ProcessMap', { mapid: `${moduleName}_DetailViewLayoutMapping` }, 'GET');
    return result;
}

export const doGetRelatedRecords = async (parentMod: string, parentId: string, relatedMod: string, queryParameters: any = {}) => {
    const result = await cbconn.doGetRelatedRecords(parentId, parentMod, relatedMod, queryParameters);
    return result;
}

export const getListColumns = async (moduleName: string) => {
    const result = await cbconn.doInvoke('ProcessMap', { mapid: `${moduleName}_ListColumns` }, 'GET');
    return result;
}

export const remoteValidate = async (id: string, module: string, values: any) => {
    const data = await cbconn.doValidateInformation(id, module, values)
    .catch((error: any) => {
        return error;
    });
    let errors: any = {}
    if(data && typeof data === 'object'){
        for (let [key, value] of Object.entries(data)) {
            const keyValue: any = value;
            errors[key] = keyValue[0]
        }
    }
    if (Object.keys(errors).length > 0) {
        return {isValid: false, errors: Object.values(errors)}
    }
    return {isValid: true, errors: []};
}

export const getMenu = async () => {
    const result = await cbconn.doInvoke('SearchGlobalVar', {gvname: 'BusinessMapping_Menu'}, 'GET').catch((err: any) => console.log(err));
    if(result?.id){
        let menu = await cbconn.doInvoke('ProcessMap', {mapid: result.id}, 'GET');
        return menu ? JSON.parse(menu) : null;
    }
    return null;
}