import React, { useState, useEffect } from 'react';
import {Identifier,Loading,useListContext} from 'react-admin';
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';
import isEqual from 'lodash/isEqual';

import { TaskColumn } from './TaskColumn';
import { status } from './status';
import { handleSpecialChars } from '../utils/Helpers';
import { doUpdate } from '../utils/lib';
import Box from '@mui/material/Box';

interface TasksByColumn {
    [status: string]: Identifier[];
}

const initialTasks: TasksByColumn = status.reduce(
    (obj, val) => ({ ...obj, [val]: [] }),
    {}
);

const getTasksByColumn = (
    ids: Identifier[],
    data: any,
): TasksByColumn => {
    const columns = ids.reduce(
        (acc, id) => {
            acc[data[id]?.projecttaskstatus]?.push(id);
            return acc;
        },
        status.reduce((obj, val) => ({ ...obj, [val]: [] }), {} as any)
    );
    status.forEach(val => {
        columns[val] = columns[val].sort(
            (a: Identifier, b: Identifier) => data[a].id - data[b].id
        );
    });
    return columns;
};

export const TaskListContent = (props: any) => {

    const {data, isLoading, refetch} = useListContext<any>();
    let ids: any = data?.map((aRecord: any) => aRecord.id);
    ids = ids ?? [];
    const dataMap: any = data?.reduce((a: any, v: any) => ({ ...a, [v.id]: v}), {});


    const [tasks, setTasks] = useState<TasksByColumn>(
        dataMap ? getTasksByColumn(ids, dataMap) : initialTasks
    );

    useEffect(() => {
        // On component mount, hide the overflown areas of the body
        document.body.style.overflow = 'hidden';

        return () => {
            // On component unmount, reset the overflow property of the body
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        if (isLoading) return;
        const newTasks = getTasksByColumn(ids, dataMap);
        if (isEqual(tasks, newTasks)) {
            return;
        }
        setTasks(newTasks);
    }, [dataMap, ids, isLoading, tasks]);


    const onDragEnd: OnDragEndResponder =  result => {
        const { destination, source, draggableId } = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            return;
        } else {

            const sourceColumn = Array.from(tasks[source.droppableId]);
            const destinationColumn = Array.from(
                tasks[destination.droppableId]
            );
            const sourceTask: any = dataMap[sourceColumn[source.index]];
            sourceColumn.splice(source.index, 1);
            destinationColumn.splice(destination.index, 0, draggableId);
            setTasks({
                ...tasks,
                [source.droppableId]: sourceColumn,
                [destination.droppableId]: destinationColumn,
            });

            sourceTask.projecttaskstatus = destination?.droppableId;
            const dataTosend: any = {};
            for (const key in sourceTask) {
                const value = sourceTask[key];
                if (value && typeof value !== 'object') {
                    dataTosend[key] = handleSpecialChars(value);
                }
            }
            doUpdate('ProjectTask', dataTosend).then(() => {
                refetch();
            });

        }
    };

    if (props.isLoadigProject || isLoading) return <Loading />;

    return (

        <DragDropContext onDragEnd={onDragEnd}>
            <Box sx={{ width: 320 * 8, height: 'auto' }}>
                <Box display="flex">
                    {status.map(val => (
                        <TaskColumn
                            height={props.height}
                            status={val}
                            taskIds={tasks[val]}
                            data={dataMap}
                            key={val}
                            resource={props?.resource}
                            handleQuickCreateModal={props.handleQuickCreateModal}
                            modPermission={props.modPermission}
                        />
                    ))}
                </Box>
            </Box>
        </DragDropContext>

    );
};
