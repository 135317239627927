import { useInput, BooleanInput, BooleanField, ImageField, UrlField } from 'react-admin';
import { clearTable, getDataFromLocalDB, saveDataToLocalDB, TABLE_DESCRIBE, TABLE_PERMISSIONS, TABLE_LIST_VIEWS } from '../local-db';
import { getModDescribe, doInvoke, doCreate } from './lib';
import DropzoneComponent from 'react-dropzone-component'
import * as DropZone from 'dropzone';
import { IconSettings, Icon } from '@salesforce/design-system-react';
import { authProvider } from '../authProvider';
import { Link } from '@mui/material';
import { CbMenuItem } from '../types';


const convert2Base64 = (document: any) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(document);
    return new Promise((resolve, reject) => {
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = (error: any) => reject(new Error(error));
    })
}

export const Dropzone = DropZone;
export const DropzoneComp = DropzoneComponent;


const getDataFromDB = async (tableName: string) => {
    const data: any = await getDataFromLocalDB(tableName);
	if(tableName === 'permissions' ){
		return data?.length ? data : null;
	}
	return data?.[0] || null;
}

export const dateRegexp = /(\d{4})-(\d{2})-(\d{2})/;

const parseDate = (v: any) => {

    if(!v) return;
    const date = new Date(v);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return [year, month, day].join("-");
};

export const formatSearchObject = (field:any, searchText:any)  => {
    if (!searchText) {
        return;
    }
    let srch:any = {};
    srch[field] = searchText;
    return srch;
}

export const FormattedBooleanInput = (props:any) => {
    const input: any = useInput(props);
    const isChecked = {checked: false};

    if (Number(input.input.value)) {
        isChecked.checked = true;
    }
    return (<BooleanInput {...props} options={isChecked}/>);
};

export const FormattedBooleanField = (props:any) => {
    props.record[props.source] = (props.record[props.source] === '1');

    return (
        <div>
            <div style={{width: '100%'}}>
                <label className="MuiFormLabel-root">{props.label}</label>
            </div>
            <BooleanField {...props} />
        </div>
    );
};

export const getDataFromLocalDb = async (tableName: string) => {
	const data = await getDataFromDB(tableName);
	return data;
}

export const loginAction = async (coreBOSDescribe: string[]) => {
    const cbWindow: any = window;
    if (cbWindow.coreBOS === undefined) {
        cbWindow.coreBOS = {};
    }
	await clearTable(TABLE_PERMISSIONS.tableName);
	await clearTable(TABLE_DESCRIBE.tableName);
    await clearTable(TABLE_LIST_VIEWS.tableName);
    let permissions: any[] = [];
    let Describe: any = {};

    return getModDescribe(coreBOSDescribe.join(',')).then((descinfo: any) => {
            for (let key in descinfo) {
                Describe[key] = descinfo[key];
                let modulePermissions = {
                    module: key,
                    permissions: {
                        create: descinfo[key].createable,
                        delete: descinfo[key].deleteable,
                        update: descinfo[key].updateable,
                        list: descinfo[key].retrieveable,
                    }
                };
                permissions.push(modulePermissions);
            }
            return permissions;
    }).then(async (result: any) => {
        await saveDataToLocalDB(TABLE_PERMISSIONS.tableName, result, true);
        let userListRes = await doInvoke('getAssignedUserList', {'module': coreBOSDescribe.join(',')});
        userListRes = JSON.parse(userListRes);

        for (const key in userListRes) {
            Describe[key].userlist = userListRes[key];
        }

        const viewsRes = await doInvoke('getViewsByModule', {'module': coreBOSDescribe.join(',')});

        await saveDataToLocalDB(TABLE_DESCRIBE.tableName, Describe, false);
        await saveDataToLocalDB(TABLE_LIST_VIEWS.tableName, viewsRes, false);

        return Promise.resolve(Describe);
    }).catch((error) => {
        let errorCode = error.toString().split(':')[2]?.trim() ?? '';
        if(errorCode === 'INVALID_SESSIONID' || errorCode === 'AUTHENTICATION_REQUIRED'){
            cbWindow.dispatchEvent(cbWindow.coreBOS.SessionExpired);
        }
    });
}

export const getModuleTabsWithFields = async (moduleName: string) => {
    const fields: any[] = [];
    const describe = await getDataFromDB(TABLE_DESCRIBE.tableName);
    if(describe == null) {
        return[];
    }

    if (!describe[moduleName]) {
        return [];
    }

    describe[moduleName].fields.forEach((e: any) => {
        if (e.block ) {
            fields.push({name: e.block.blockname, fields: [], tab: e.block.blocklabel});
        }
    });

    let tabs = new Map<any[], any>(fields.map((item:any) => [item['name'], item]));
    describe[moduleName].fields.forEach((e: any) => {
        if (e.block) {
            tabs.forEach((tab: any) => {
                if (tab.name === e.block.blockname) {
                    tab.fields.push(e);
                }
            });
        }
    });

    return tabs;
};

const getMenuItems = (menuItems: any[], moduleList: CbMenuItem[]) => {

    for (const menuItem of menuItems) {
        for (const key in menuItem.menuItems) {
            const item: CbMenuItem = menuItem.menuItems[key];
            item.menuItems = moduleList.filter((mod: CbMenuItem) => mod.parentMenu === item.menuId);
            menuItem.menuItems[key] = item;
        }
    }

    return menuItems;
}

export const prepareMenu = (menuMap: any) => {
    if(!menuMap) return [];
    const menu: any = {};
    const menuItems: CbMenuItem[] = [];
    const moduleList: CbMenuItem[] = [];

    for (const key in menuMap) {
        if(menuMap.hasOwnProperty(key)) {
            if(!menu.hasOwnProperty(key)) {
                const menuItem: CbMenuItem = {
                    menuId: menuMap[key]['evvtmenuid'],
                    type: menuMap[key]["mtype"],
                    name: menuMap[key]['mvalue'],
                    label: menuMap[key]['mlabel'],
                    parentMenu: menuMap[key]['mparent'],
                    menuItemSequence: menuMap[key]['mseq'],
                    menuItemVisibility: menuMap[key]['mvisible'] === '1',
                    permittedIds: menuMap[key]['mpermission'],
                    icon: menuMap[key]['icon'] ?? null
                }
                if(menuMap[key]["mparent"] === 0 || menuMap[key]["mparent"] === '0'){ // Menu tier (Menu heading)
                    menu[key] = menuItem;
                } else if(menuMap[key]["mtype"] === 'module' || menuMap[key]["mtype"] === 'menu' || menuMap[key]["mtype"] === 'url'){
                    menuItem.type = menuMap[key]["mtype"];
                    moduleList.push(menuItem)
                }
            }
        }
    }

    for (const key in menu) { // 1st Level
        menuItems.push({
            menuId: menu[key].menuId,
            type: menu[key].type,
            name: menu[key].name,
            label: menu[key].label,
            parentMenu: menu[key].parentMenu,
            menuItemSequence: menu[key].menuItemSequence,
            menuItemVisibility: menu[key].menuItemVisibility,
            permittedIds: menu[key].permittedIds,
            icon: menu[key].icon,
            menuItems: moduleList.filter((mod: CbMenuItem) => mod.parentMenu === menu[key].menuId)
        });
    }

    return getMenuItems(menuItems, moduleList);
}

export const prepareFileToUpload = async (fileToUpload: any, title: string, relationsIds: any, relProjectId: any, assigned_user_id: string) => {

    const base64document = await convert2Base64(fileToUpload);
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const fileNameFormat = `${title}_${yyyy}-${mm}-${dd}_${fileToUpload.name}`;

    const fileParams = {
        name: fileNameFormat,
        size: fileToUpload.size,
        type: fileToUpload.type,
        content: base64document,
    };


    const docData = {
        notes_title: `${fileParams.name}`,
        filename: fileParams,
        filetype: fileToUpload.type,
        filesize: fileToUpload.size,
        filelocationtype: 'I',
        filestatus: 1,
        relations: relationsIds,
        reltoproject: relProjectId,
        assigned_user_id: assigned_user_id,
    };

    return docData;

}

export const uploadFile = async (file: any, title: string, relationsIds: any, relProjectId: any, assigned_user_id: string) => {
    let assignedUserId = '';
    if(!assigned_user_id){
        const user = await authProvider.getIdentity();
        assignedUserId = user?.id;
    }
    const preparedFile = await prepareFileToUpload(file, title, relationsIds, relProjectId, assignedUserId);
    const result = await doCreate('Documents', preparedFile);
    return result;
}

export const handleFileDisplay = (file: any) => {

    if(file.filetype === 'image/png' || file.filetype === 'image/jpg' || file.filetype === 'image/jpeg'){
        return (
            <ImageField
                key={file.id}
                label={''}
                source="_downloadurl"
                record={file}
            />
        );
    }else{
        return (
            <UrlField source="_downloadurl" record={file}  label={''} target="_blank" />
        );
    }

}

export const CustomUrlField = ({ source, record, target='_self', rel='noopener noreferrer', urlTextField }:{ source: string, record: any, target?: string, rel?: string, urlTextField: string }) => {
    const value = record[source] || '';
    const urlText = record[urlTextField] || '';

    return (
        <Link href={value} target={target} rel={rel}>
            {urlText}
        </Link>
    );
}

export const convertFileToBase64 = async (file:any) => {
    const base64 = await convert2Base64(file);
    return base64;
}

export const ModMenuIcon = ({menu, moduleName, IconContainerStyle, IconStyle} : {menu: any[], moduleName: string, IconContainerStyle: any, IconStyle: any}) => {

    const foundMenuItem: any = menu.filter((menuItem: any) => menuItem.name === moduleName)[0];
    if(!foundMenuItem) {
        return <></>
    }

    return(
        <IconSettings iconPath="/icons" style={IconContainerStyle}>
            <div className={`${foundMenuItem?.icon?.containerClass}`}>
                <Icon
                    category={foundMenuItem?.icon?.library}
                    name={foundMenuItem?.icon?.icon}
                    className={`${foundMenuItem?.icon.class}`}
                    size="small"
                    style={IconStyle}
                />
            </div>
        </IconSettings>
    )
}
 // * function that returns only the maps related to the current field
 export const filterRelatedMaps = (dependencyMaps: any[], indexesToFind :any[number]) => {
    return dependencyMaps.filter((map: any, index) => indexesToFind.includes(index));
  };

export const dateParser = (v: any) => parseDate(v);
export const dateTimeParser = (v:any) => {
    if(!v) return;
    const dateTime = parseDate(v);
    if(!dateTime) return;
    const date = new Date(v);
    let match = dateRegexp.exec(date.toISOString());
    if (match === null) return;

    const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
    return `${dateTime} ${time}`;
}

const getFieldNames = (actionArray: any, actionName: string, parentFieldName: any, indexKey: number) => {
    const fieldNames: any[] = [];
    actionArray.forEach((action: any) => {
        fieldNames.push({
          parentFieldName: parentFieldName,
          dependentFieldName: action.field,
          index: indexKey,
          action: actionName,
        });
    });
    return fieldNames;
}

export const filterDependencyMaps = (currentMap :any, ) => {
    const parentFieldNames = Object.keys(currentMap?.fields);
    const allMaps :any[] = [];

      let fieldNames: any[] = [];
      if (parentFieldNames.length > 0) {
        parentFieldNames.forEach((parentFieldName: any, index: number) => {
          const parentFieldDependencyMaps = currentMap?.fields[parentFieldName]; // * get the map for every parent field
          parentFieldDependencyMaps.forEach((parentFieldDependencyMap: any) => {
            // * loop through parent field maps
            parentFieldDependencyMap.field = parentFieldName;
            allMaps.push(parentFieldDependencyMap);
            const actionNames = Object.keys(parentFieldDependencyMap?.actions); // *get the action names
            actionNames.forEach((actionName: string) => {
              //* loop through action names
              const actionArray = parentFieldDependencyMap?.actions[actionName]; // * get the array of actions which hold the dependent field names
              fieldNames = [...fieldNames, ...getFieldNames(actionArray, actionName, parentFieldName, index)];
            });
          });
        });

      }

      return {
        allMaps: allMaps,
        fieldNames: fieldNames,
    }
}


export const handleSpecialChars = (text: string) => {
    let newText = text;
    if(newText && typeof newText === 'string'){
        newText = newText.replaceAll("%22", "&quot;").replaceAll("&", "%26");
    }
    return newText;
}

export const formatStowatchTime = (timeInCentiSeconds: number, showCentiseconds: boolean = false) => {

    let minutes: any =  Math.floor((timeInCentiSeconds / 6000) % 60);
    let seconds: any =  Math.floor((timeInCentiSeconds % 6000) / 100);
    let centiSeconds: any = timeInCentiSeconds % 100;

    if(minutes < 10) {
        minutes = `0${minutes}`;
    }
    if(seconds < 10) {
        seconds = `0${seconds}`;
    }
    if(centiSeconds < 10) {
        centiSeconds = `0${centiSeconds}`;
    }

    return showCentiseconds ? `${minutes}:${seconds}:${centiSeconds}` : `${minutes}:${seconds}`;
}

export const convertToCentiSeconds = (timeInMilliseconds: number) => {
    return Math.round(timeInMilliseconds * 0.1);
}

export const convertCentiSecondsToDate = (timeInMilliseconds: number) => {
    return new Date(timeInMilliseconds * 10);
}

export const getNumberOfHours = (milliseconds: number, formatted: boolean = false) => {
    let hours = milliseconds / (1000 * 60 * 60);
    hours = formatted ? Math.abs(Math.round(hours)) : Math.abs(Math.round((hours + Number.EPSILON) * 100) / 100);
    return (formatted && hours < 10) ? `0${hours}` : hours;
}

export const getTimeFromHours = (hours: number) => {
    return hours * 1000 * 60 * 60;
}

export const getAssignee = (task: any) => {
    if(task?.ename ){
        return task.ename.slice(0,1);
    } else if(task?.assigned_user_idename?.reference){
        return task?.assigned_user_idename?.reference?.slice(0,1);
    } else {
        return 'X';
    }
}

