import { ThemeName, MenuPosition, UserMenu, Timer, TC } from '../types';

export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_MENU_POSITION = 'CHANGE_MENU_POSITION';
export const CHANGE_APP_MENU = 'CHANGE_APP_MENU';
export const TIME = 'TIME';
export const RECORD = 'RECORD';
export const DATESTART = 'DATESTART';
export const TIMECONTROL = 'TIMECONTROL';

export const changeTheme = (theme: ThemeName) => ({
    type: CHANGE_THEME,
    payload: theme,
});

export const changeMenuPostion = (postion: MenuPosition) => ({
    type: CHANGE_MENU_POSITION,
    payload: postion,
});

export const changeAppMenu = (menu: UserMenu) => ({
    type: CHANGE_APP_MENU,
    payload: menu,
});

export const Taskcounter = (time: Timer) => ({
    type: TIME,
    payload: time,
});

export const setProjecttaskrecord = (record: any) => ({
    type: RECORD,
    payload: record
})

export const setDatestart = (record: any) => ({
    type: DATESTART,
    payload: record
})

export const setTc = (tc: TC) => ({
    type: TIMECONTROL,
    payload: tc
})

// TC Stopwatch

export const initialState = {
    /**
     * Epoch timestamp of when the timer was started in centiseconds
     */
    started: 0,

     /**
     * Epoch timestamp of when the timer was ended in centiseconds
     */
    ended: 0,
  
    /**
     * Total time recorded when stopping the timer in centiseconds
     */
    recordedTime: 0,

    workingTask: null,

    tcModalOpen: false,

    tcModalClose: false,
};

export const startTimer = (time: any, workingTask: any) => {
    return {
      type: 'START_TIMER',
      time,
      ended: null,
      workingTask
    };
  }
  
export const stopTimer = (time: any, tcModalOpen: boolean) => {
  return {
    type: 'STOP_TIMER',
    time,
    ended: time,
    tcModalOpen
  };
}

export const closeModal = (tcModalClose: boolean) => {
  return {
    type: 'CLOSE_BUTTON',
    tcModalClose
  }
}
export const continueTask = () => {
  return {
    type: 'CONTINUE_TIMER',
  }
}

export const resetTimer = () => {
  return {
    type: 'RESET_TIMER'
  };
}

