import React, { cloneElement } from 'react';
import { ExportButton, sanitizeListRestProps, Button, TopToolbar, useRedirect, useTranslate } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';


export const ListActionToolbar = (props: any) => {

    const { resource, total, currentSort, maxResults, filters, displayedFilters, filterValues, basePath, modPermission, showFilter, handleCreate, ...rest } = props;
    const redirect = useRedirect();
    const translate = useTranslate();


    return (
        <TopToolbar style={{paddingTop:'0px', marginTop:'-10%', marginBottom:'-16%'}} my={2} display="flex" justifyContent="end" {...props} {...sanitizeListRestProps(rest)}>
              {filters && cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button'}) }
            <ExportButton 
                disabled={total === 0}
                resource={resource}
                maxResults={maxResults}
                size="small"
            /> 
            {modPermission?.create && resource !== 'Timecontrol' &&
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleCreate ? handleCreate(null, true) : redirect(`/${props.resource}/create?${props?.params}`)}
                    label={translate('ra.action.create')}
                    title={translate('ra.action.create')}
                >
                <AddIcon />
              </Button>
            }
        </TopToolbar>
    )
}