export const config =  {
  appName: 'SKHR Portal',
  appDescription: 'SKHR Portal',
  logoUrl: 'https://skhr.evolutivo.it/storage/2024/May/week2/8790_image_%2856%29.png',
  metaTags: [
    {
      name: 'reactadmin',
      content: 'SKHR Production',
    },
  ],
  server: {
    url: 'https://hr.evolutivo.it/',
  },
  processFlowModules: [
    {moduleName: 'Project', field: 'projectstatus', pfId: '117986'}
  ],
  userAvatarFemale: 'femaleavatar.jpeg',
  userAvatarMale: 'profilemale.jpeg',
}

