import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useTranslate, Create, SimpleForm, SaveButton, Button, useNotify, useRedirect, List, Datagrid, useUnselectAll, Filter, SearchInput, useRefresh, FunctionField } from 'react-admin';
import cbUtils from './corebosUtils/corebosUtils';
import { doInvoke } from '../utils/lib';
import ResponsiveList from './ResponsiveList';
import CustomFieldLink from './CustomFieldLink';
import { DocumentUpload } from './DocumentUpload';
import { styled, CircularProgress, Box, Tab, Tabs, Drawer, Typography, Toolbar, AppBar, useMediaQuery } from '@mui/material';


interface StyledTabsProps {
    children?: React.ReactNode;
    onChange: (event: React.SyntheticEvent, newValue: string) => void;
}
interface StyledTabProps {
    label: string;
    value: string;
}
export const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
))(({ theme }) => ({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, .125)',
        '& > span': {
            maxWidth: '100%',
            width: '100%',
            backgroundColor: '#fff',
        },
    },
}));
const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    root: {
        border: '1px',
        background: '#fff',
        borderColor: '#eee',
        borderStyle: 'solid',
        textTransform: 'none',
        '&:first-child': {
            borderRight: 'none',
        },
        fontWeight: theme.typography.fontWeightRegular,
        '&:focus': {
            opacity: 1,
        },
    },
}));


const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children || <CircularProgress color="inherit" size={18} thickness={5} />}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const CbFilters = (props: any) => {
    return (
        <Box>
            <Filter {...props} >
                <SearchInput source={'cblistsearch_' + props.resource} alwaysOn fullWidth />
            </Filter>
        </Box>
    )
}

const CustomToolbar = (props: any) => {

    const translate = useTranslate();
    const { isLoading, onSuccess, toggleRelatedModuleDrawer } = props;


    return (
        <Toolbar {...props}>
            <SaveButton
                label={translate('ra.action.save')}
                icon={isLoading || props.saving ? <CircularProgress color={isLoading || props.saving ? 'primary' : 'secondary'} size={24} /> : <></>}
                disabled={props.pristine || isLoading || props.saving}
                //submitOnEnter={true}
                redirect={'show'}
                size="small"
                onSuccess={(record: any) => onSuccess(record, true)}
                {...props}
            />
            <SaveButton
                label={translate('ra.action.save') + ' & ' + translate('ra.action.add')}
                icon={isLoading || props.saving ? <CircularProgress color={isLoading || props.saving ? 'primary' : 'secondary'} size={24} /> : <></>}
                disabled={props.pristine || isLoading || props.saving}
                //submitOnEnter={true}
                redirect={false}
                to={{ state: { skipFormReset: false } }}
                size="small"
                style={{ margin: 'auto 10px' }}
                onSuccess={(record: any) => onSuccess(record, false)}
                {...props}
            />
            <Button
                label={translate('ra.action.cancel')}
                variant='outlined'
                onClick={toggleRelatedModuleDrawer}
                size='small'
                color="primary"
            />
        </Toolbar>
    );
}

const BulkActionButtons = (props: any) => {

    const { relateRecords } = props;

    const translate = useTranslate();

    return (
        <Button
            label={translate('translations.relateAction')}
            color="primary"
            variant={'contained'}
            onClick={() => relateRecords(props)}
        />
    );
}

const CbCreateRelatedRecordDrawer = ({ relatedModDetails, relatedFieldValue, open, toggleRelatedModuleDrawer, describe, relatedFields, linkfields, modPermissions, handleDocPreviewModalOpen, user, ...props }: { relatedModDetails: any, relatedFieldValue: any, open: boolean, toggleRelatedModuleDrawer: any, describe: any, relatedFields: any[], linkfields: any[], modPermissions: any, handleDocPreviewModalOpen: any, user: any }) => {
    const resource: string = relatedModDetails?.name || '';
    const [value, setValue] = useState<any>(0);
    const translate = useTranslate();
    const fields = describe[resource]?.fields;
    const [isLoading, setIsLoading] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const defaultValues: any = {};
    defaultValues[relatedModDetails?.relatedfield] = relatedFieldValue;
    const unselectAll = useUnselectAll(resource);
    const refresh = useRefresh();
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));



    const relateRecords = ({ selectedIds }: { selectedIds: any[] }) => {
        const dataToSend = {
            relate_this_id: relatedFieldValue,
            with_these_ids: JSON.stringify(selectedIds)
        };
        doInvoke('SetRelation', dataToSend, 'POST').then(() => {
            notify(translate('translations.dataUpdated'), { type: 'success' });
            refresh();
        }).catch((err: any) => {
            notify(translate('translations.somethingWentWrong'), { type: 'error' });
        }).finally(() => {
            unselectAll();
        })
    }

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    const onSuccess = (res: any, redirection = true) => {
        const dataToSend = {
            relate_this_id: relatedFieldValue,
            with_these_ids: JSON.stringify([res?.id])
        };
        doInvoke('SetRelation', dataToSend, 'POST').then(() => {
            if (redirection) {
                redirect(`/${resource}/${res?.id}/show`);
            } else {
                // Reset form
                notify('Saved!');
                refresh();
                defaultValues[relatedModDetails?.relatedfield] = relatedFieldValue;
            }
        }).catch((err) => {
            notify(err);
        }).finally(() => {
            setIsLoading(false);
        })
    };

    const handleAfterUpload = () => {
        refresh();
        defaultValues[relatedModDetails?.relatedfield] = relatedFieldValue;
        toggleRelatedModuleDrawer();
    }


    return (
        <Drawer
            hideBackdrop={true}
            open={open}
            anchor='right'
            sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
        >
            <AppBar position="static" color="primary" style={{ boxShadow: 'none' }}>
                <Toolbar>
                    <Typography sx={{ flexGrow: 1 }}>
                        {translate('translations.associateThisRecord')}
                    </Typography>
                    <IconButton edge="end" color="inherit" aria-label="close button" onClick={() => toggleRelatedModuleDrawer({}, false)} >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Box sx={{ flexGrow: 1, width: '90%', margin: 'auto' }}>
                <Box sx={{ width: '100%', marginTop: '30px', backgroundColor: '#fff' }}>
                    <StyledTabs onChange={handleChange} aria-label={`${value}tab`}>
                        <StyledTab value={value} label={translate('translations.associateNew')} />
                        {relatedModDetails && relatedModDetails.moduleRelationType === 'N:N' &&
                            <StyledTab value={value} label={translate('translations.associateExisting')} />
                        }
                    </StyledTabs>
                </Box>
                <Box mt={-2.1} >
                    <TabPanel value={value} index={0}>
                        {resource === 'Documents'
                            ? <DocumentUpload afterUpload={handleAfterUpload} uploadResource={resource} assigned_user_id={user?.id} relationsIds={relatedFieldValue} relProjectId={''} />
                            : <Create
                                resource={resource}
                                title={<></>}
                            >
                                <SimpleForm defaultValues={defaultValues} toolbar={<CustomToolbar isLoading={isLoading} onSuccess={onSuccess} toggleRelatedModuleDrawer={toggleRelatedModuleDrawer} />} style={{ border: 'none' }}>
                                    {
                                        fields.map((field: any) => {
                                            return cbUtils.field2InputElement(field, resource, { fullWidth: true }, describe);
                                        })
                                    }
                                </SimpleForm>
                            </Create>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box mt={5}>
                            <List
                                resource={resource}
                                title={<></>}
                                perPage={10}
                                filters={<CbFilters />}
                                actions={false}
                                component="div"
                                {...props}
                            >
                                <>
                                    {isSmall
                                        ? <ResponsiveList resource={resource} fields={relatedFields} linkFields={linkfields} linkField={linkfields[0]} describe={describe} modPermission={modPermissions} />
                                        : <Datagrid bulkActionButtons={<BulkActionButtons relateRecords={relateRecords} />}>
                                            {
                                                relatedFields.map((field: any, index: number) => {
                                                    if (field.uitype === '28') {
                                                        return (
                                                            <FunctionField key={field.name+index} label={translate('File')} render={(record: any) => {
                                                                return (
                                                                    <>
                                                                        {record.filetype.split('/')[0] === 'image'
                                                                            ? <Box onClick={() => handleDocPreviewModalOpen(record)} sx={{ maxWidth: '40%', margin: 'auto', cursor: 'pointer' }}>
                                                                                <img
                                                                                    src={record?._downloadurl}
                                                                                    alt={record?.notes_title}
                                                                                    style={{ width: '100%', margin: 'auto', cursor: 'pointer' }}
                                                                                />
                                                                                </Box>
                                                                            : <Button
                                                                                key={field.name+index+'btn'}
                                                                                color='primary'
                                                                                label={record?.notes_title}
                                                                                onClick={() => handleDocPreviewModalOpen(record)}
                                                                            />
                                                                        }
                                                                    </>

                                                                )
                                                            }
                                                            } />
                                                        )
                                                    } else {
                                                        return linkfields.includes(field.name) ? <CustomFieldLink record={{}} resource={resource} linkField={field.name} key={field.name+index} {...props} /> : cbUtils.field2DisplayElement(field, resource, describe);
                                                    }
                                                })
                                            }
                                        </Datagrid>
                                    }
                                </>
                            </List>
                        </Box>
                    </TabPanel>
                </Box>
            </Box>
        </Drawer>
    )
}

export default CbCreateRelatedRecordDrawer;