import * as React from 'react';
import { useState } from 'react';
import { handleSpecialChars, uploadFile } from '../../utils/Helpers';
import {
    useNotify,
    Notification,
    useCreate,
    useUpdate,
    useGetIdentity,
    useTranslate,
} from 'react-admin';
import {Box, CircularProgress, Button as CommentButton, useTheme} from '@mui/material';
import { converter } from '../../components/MarkDown';
import { doInvoke } from '../../utils/lib';
import ReactMde, { SaveImageHandler } from "react-mde";
import "dropzone/dist/dropzone.css";
import "react-dropzone-component/styles/filepicker.css";
import "react-mde/lib/styles/css/react-mde-all.css";



export const CommentForm = ({ projectTask, resource, setReloadTriggerID, handleCancel=null, replyingComment=null, comment, isNewRecord=false, contacts }:{ projectTask: any, resource: string, setReloadTriggerID: any, handleCancel: any, replyingComment: any, comment: any, isNewRecord: boolean, contacts: any }) => {

    const theme = useTheme();
    const notify = useNotify();
    const translate = useTranslate();
    const [create] = useCreate();
    const [update] = useUpdate();
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedFileList, setUploadedFileList] = useState<any[]>([])
    const [value, setValue] = useState<any>(comment?.commentcontent || '');
    const [selectedTab, setSelectedTab] = useState<any>("write");
    const { identity } = useGetIdentity();

    const suggestionarray = Array.isArray(contacts) && contacts?.length > 0 ? contacts?.map((contact: any) => {
        return  {
            preview: contact?.firstname,
            value: `@${contact?.firstname}`,
          }
    }) : []


    const filterSuggestions = (suggestions: any[], text: string) => suggestions.filter((i) => i.preview.toLowerCase().includes(text.toLowerCase()));

    const loadSuggestions = (text: any) => {
        return new Promise<any>((accept, reject) => {
          setTimeout(() => {
            const suggestions = filterSuggestions(suggestionarray, text);
            accept(suggestions);
          }, 250);
        });
    }



    const handleSave = (fileList: any[]) => {
         setIsLoading(true);
         if(isNewRecord){
            const values: any = {};
            values.assigned_user_id = identity?.id;
            values.related_to = projectTask?.id;
            values.parent_comments = replyingComment?.id??'';
            values.commentcontent = handleSpecialChars(value);
            for (const key in values) {
				values[key] = handleSpecialChars(values[key]);
			}
            create(resource, {data: values}, {
                onSuccess: async (result: any) => {
                    const fileIds: any[] = [];
                    fileList.forEach((uploadedDocument: any) => {
                        fileIds.push(uploadedDocument.id);
                    });
                    doInvoke('SetRelation', { relate_this_id: result?.id, with_these_ids: JSON.stringify([...fileIds, projectTask?.id])}, 'POST').then(() => {
                        setUploadedFileList([]);
                        setValue('');
                        setReloadTriggerID(new Date().getTime());
                    }).catch(() => {
                        notify('Upload: Something went wrong, try again later', {type: 'error'});
                    }).finally(() => {
                        setIsLoading(false);
                    })
                },
                onError: () => {
                    setIsLoading(false);
                    notify('Something went wrong, try again later', {type: 'error'});
                },
            });
         }else{
            const dataToSend: any = comment;
            dataToSend.commentcontent = handleSpecialChars(value);;
            setIsLoading(true);
            update(resource, { id: dataToSend.id, data: dataToSend, previousData: comment }, 
                {
                    onSuccess: (result: any) => {
                        const fileIds: any[] = [];
                        uploadedFileList.forEach((uploadedDocument: any) => {
                            fileIds.push(uploadedDocument.id);
                        });
                        doInvoke('SetRelation', { relate_this_id: result?.data.id, with_these_ids: JSON.stringify([...fileIds, projectTask?.id])}, 'POST').then(() => {
                            setUploadedFileList([]);
                            setValue('');
                            setReloadTriggerID(new Date().getTime());
                        }).catch((err) => {
                            notify('Upload: Something went wrong, try again later', {type: 'error'});
                        }).finally(() => {
                            handleCancel();
                            setIsLoading(false);
                        })
                    },
                    onError: (error: any) => {
                        setIsLoading(false);
                        notify('Something went wrong, try again later', {type: 'error'});
                    }
                }
            ) 
         } 
    }

    const saveThisImage: SaveImageHandler = async function*(data: ArrayBuffer, file: Blob) {
        const title = `${projectTask?.projecttask_no}`;
        setIsLoading(true);
        const result: any  = await uploadFile(file, title, '', '', '');
        if(result instanceof Error) {
            setIsLoading(false);
            yield '';
            return true;
        }
        setIsLoading(false);
        setUploadedFileList(uploadedFileList => [...uploadedFileList, result]);
        yield result?._downloadurl; 
        return !!result?._downloadurl;
    };

    return (
        <Box my={4}>
            <ReactMde
                value={value}
                onChange={setValue}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                loadSuggestions={loadSuggestions}
                generateMarkdownPreview={markdown =>
                    Promise.resolve(converter.makeHtml(markdown))
                }
                childProps={{
                writeButton: {
                    tabIndex: -1
                }
                }}
                paste={{
                    saveImage: saveThisImage,
                    //multiple: false,
                    //command: '',
                    //accept: ''
                }}
            />
            <Box style={{width: '25%', margin: '10px auto', float: 'right'}}>
            <CommentButton
                    startIcon={ isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary' } size={24} /> : <></>}
                    disabled={ !value || isLoading }
                    onClick={() => handleSave(uploadedFileList)}
                    color="primary"
                    variant='contained'
                    sx={{textTransform: 'none', width: '100%', marginRight: theme.spacing(1)}}
                >
                    {translate('ra.action.save')}
                </CommentButton>
                {handleCancel &&
                    <CommentButton
                        onClick={handleCancel}
                        color="primary"
                        sx={{textTransform: 'none', width: '100%', marginRight: theme.spacing(1)}}
                    >
                        {translate('Cancel')}
                    </CommentButton>
                }
            </Box>
            <Notification />
        </Box>
    )

}

export default CommentForm;