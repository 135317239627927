
import { config } from './config';


export const APP_NAME = config.appName;
export const APP_DESCRIPTION = config.appDescription;
export const APP_META_TAGS = config.metaTags;
export const  COREBOS_URL = config.server?.url;
export const USER_AVATAR_FEMALE = config.userAvatarFemale;
export const USER_AVATAR_MALE = config.userAvatarMale;
export const IMAGE_FULL_PATH = 'fullpath';
export const IMAGEINFO_FULL_PATH = 'imageinfo.fullpath';
export const BUSINESS_PROCESS_FLOW = config.processFlowModules;
export const COMPANY_LOGO_URL = config.logoUrl;
export enum FIELD_DEPENDENCY_ACTIONS {
    CHANGE = 'change',
    HIDE = 'hide',
    SET_OPTIONS = 'setoptions',
    DELETE_OPTIONS = 'deloptions',
    READONLY = 'readonly',
    fieldDep_GetFields = 'fieldDep_GetField',
}
export const ACCEPTED_UPLOAD_FILES: any = {
    'image/*': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-powerpoint': [],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
    'application/vnd.oasis.opendocument.text': [],
    'application/vnd.ms-excel': [],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    'text/*': [],
    'text/html': [],
    'text/plain': [],
}
export const ALLOWED_FILES_EXTENSIONS: string[] = [
    'jpg', 
    'jpeg', 
    'png', 
    'jfif', 
    'gif', 
    'svg', 
    'csv', 
    'pdf', 
    'xlsx', 
    'xls', 
    'doc', 
    'docx',
    'tid', 
    'odt', 
    'pptx', 
    'ppt'
];
