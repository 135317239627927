import { cloneElement } from "react";
import { filterRelatedMaps } from "../utils/Helpers";
import { FIELD_DEPENDENCY_ACTIONS } from "../constant";
import { doQuery } from "../utils/lib";
import { useFormContext } from 'react-hook-form';


export const ParentDependencyField = ({
    field,
    dependencyMaps,
    formattedDepenedncyMap, // *map that contains {dependant field name, parent field name, and the index of the map in @dependencyMaps}
    input
  }: {
    field: any;
    dependencyMaps: any[];
    formattedDepenedncyMap :any;
    input: any;
  }) => {
    
    const {setValue} = useFormContext();
    let inputProps = {...input?.props};


    const getRelatedFields = (query: string, relatedRecordFields: string, referenceModule: string, destinationFields: string[]) => {
      doQuery(query).then((res: any) => {
        let queryResult :any = res[0];

        // * this array will contain maps with @{sourceFieldName, destinationFieldName, and the new value}
        const formattedActionMaps :any[] = relatedRecordFields.split(',').map((relatedRecordFieldName :string, index :number)=>{
          if(relatedRecordFieldName.split('.')[0] === referenceModule){ //* string is of this format: 'Module.field'
            return {sourcefieldname: relatedRecordFieldName, destinationFieldName: destinationFields[index],newValue: queryResult[relatedRecordFieldName.toLowerCase().replace('.','')]};
          }
          return {sourcefieldname: relatedRecordFieldName, destinationFieldName: destinationFields[index],newValue: queryResult[relatedRecordFieldName]};
        });

        //*change the destination field values
        formattedActionMaps.forEach((formattedActionMap :any) => {
          setValue(formattedActionMap.destinationFieldName, formattedActionMap.newValue)
        });
      
      });
    }

    const handleActionChange = (inputPropsValue: any, value: any, actionMap: any) => {
        inputProps = {...inputPropsValue, onChange : (e: any) => {
          if(typeof (window as any).notChangeInputs === 'undefined') return; // *return if the array does not exist
          if(e?.target?.value === value){ // * if the change action must be triggered , remove the dependant field name from notChangeInputs array
            const fieldIndexInNotChangeInputs = (window as any).notChangeInputs?.indexOf(actionMap?.field);
            if(fieldIndexInNotChangeInputs >=  0) (window as any).notChangeInputs?.splice(fieldIndexInNotChangeInputs, 1);
          }
      }};
    }

    const handleActionMap = (actionArray: any, map: any, action: any, inputPropsValue: any) => {
      actionArray.forEach((actionMap :any) => {
        if(!map?.conditions){
          if(action === 'function' && actionMap?.value === FIELD_DEPENDENCY_ACTIONS.fieldDep_GetFields){
            const referenceModule :string= field.type.refersTo[0];
              const actionMapParams :string[] = actionMap?.params;
              const relatedRecordFields :string =actionMapParams[0];
              const destinationFields :string[] =actionMapParams[1].split(',');
  
              inputProps = {...inputPropsValue, onChange : (val: string) => {
                let query = `Select ${relatedRecordFields} from ${referenceModule} where id = '${val}'`;
                getRelatedFields(query, relatedRecordFields, referenceModule, destinationFields);
              }};
        }
      } else {
          const conditions : any[] = map?.conditions;
          conditions.forEach((condition: any) => {
            const {value} = condition;
            if(action === FIELD_DEPENDENCY_ACTIONS.CHANGE){ // *change input action
              handleActionChange(inputPropsValue, value, actionMap);
            }
          })
      }
      });
    }

      const render = () => {
        let mapsToConsider = filterRelatedMaps(dependencyMaps, formattedDepenedncyMap.map((map :any) => map?.index)).map((valueMap: any) => {
            return { ...valueMap, conditions: valueMap?.conditions ? JSON.parse(valueMap?.conditions) :  valueMap?.conditions};
          });
          mapsToConsider.forEach((map :any) => {
              Object.keys(map?.actions).forEach((action: any) => {
                const actionArray = map?.actions[action]; // * get the array of actions which hold the dependent field names
                handleActionMap(actionArray, map, action, inputProps);
          }); //* loop through all filtered @dependencyMaps actions
        }); //* loop through all filtered @dependencyMaps
        let inputToRender :any = cloneElement(input, inputProps);
        return inputToRender;
      }

      return (
          <>{render()}</>
      )
  }